$color_1: #74788d;
$color_2: #28283C;
$color_3: #2A313D;
$color_4: #2229C1;
$color_5: #4c40ed;
$color_6: #fff;
$color_7: #F2535B;
$color_8: #F82424;
$color_9: #6DCC76;
$color_10: #34C85D;
$color_11: #FFC107;
$color_12: #1db9aa;
$color_13: #2196f3;
$color_14: #e63c3c;
$color_15: #f39c12;
$color_16: #26af48;
$color_17: #c580ff;
$color_18: #283447;
$color_19: #28A745;
$color_20: #DC3545;
$color_21: #8d8d8d;
$color_22: #FF0000;
$color_23: #888;
$color_24: #272b41;
$color_25: #858585;
$color_26: #68616D;
$color_27: #7E84A3;
$color_28: #ff008a;
$color_29: #383838;
$color_30: #3E4265;
$color_31: #959595;
$color_32: #C2C9D1;
$color_33: #FFBC35;
$color_34: #EDECF8;
$color_35: #E9EEF3;
$color_36: #292D32;
$color_37: #000000;
$color_38: #313131;
$color_39: #58C27D;
$color_40: #808080;
$color_41: #1A1A1A;
$color_42: #929292;
$color_43: white;
$color_44: #3C4758;
$color_45: #607D8B;
$color_46: #263238;
$color_47: #D1D1D1;
$color_48: #818181;
$color_49: #797979;
$color_50: #9E9E9E;
$color_51: #00CC45;
$color_52: #EBEBEB;
$color_53: #3A3A3A;
$color_54: #494949;
$color_55: #FFE30F;
$color_56: #326CFB;
$color_57: #a3a3a3;
$color_58: #F0142F;
$color_59: #9e9e9e;
$color_60: #ff9b44;
$color_61: #B3B3B3;
$color_62: #CED1DA;
$color_63: #9F9F9F;
$color_64: #6B6B6B;
$color_65: #949494;
$color_66: rgba(0, 0, 0, 0.22);
$color_67: #0E82FD;
$color_68: #999999;
$color_69: #252525;
$color_70: #526484;
$color_71: #8094AE;
$color_72: #9597A9;
$color_73: #12151C;
$color_74: #9D9D9D;
$color_75: #B0B0B0;
$color_76: #1BA345;
$color_77: #282828;
$color_78: #969696;
$color_79: #2563EB;
$color_80: #34444C;
$color_81: #828282;
$color_82: #B1B1B1;
$color_83: #575757;
$color_84: #2c3038;
$color_85: #48E100;
$color_86: #c23616;
$color_87: #ABABAB;
$color_88: #A8A8A8;
$color_89: #9f9f9f;
$color_90: rgba(50, 65, 72, 0.4);
$color_91: #777;
$color_92: #2c80ff;
$color_93: #00d285;
$color_94: #495463;
$color_95: #7A7F9A;
$color_96: #888888;
$color_97: #959494;
$color_98: #585858;
$color_99: #8B8B8B;
$color_100: #8C8C8C;
$color_101: #71717A;
$color_102: #203066;
$color_103: #151519;
$color_104: #2A396D;
$color_105: rgba(32, 48, 102, 1);
$color_106: #4C40ED;
$color_107: #101522;
$color_108: #58627B;
$color_109: #DD318E;
$color_110: #9397AD;
$color_111: #181059;
$color_112: #939AAD;
$color_113: #FFA621;
$color_114: #F9F9F9;
$color_115: #424242;
$color_116: #333;
$color_117: #ff0080;
$color_118: #898989;
$color_119: #FFB800;
$color_120: #6C6C6C;
$color_121: #0B2540;
$color_122: #626262;
$color_123: #FFBA08;
$color_124: #BBBBBB;
$color_125: #4C82F7;
$color_126: rgba(255, 255, 255, 1);
$color_127: rgba(194, 201, 209, 1);
$color_128: rgba(218, 218, 218, 1);
$color_129: rgba(32, 48, 102, 0.5);
$color_130: #67687A;
$color_131: rgba(32, 48, 102, 0.3);
$color_132: rgba(32, 48, 102, 0.7);
$color_133: #DEDEDE;
$color_134: #159C5B;
$color_135: #353535;
$color_136: rgba(107, 107, 107, 0.8);
$color_137: #F0F0F0;
$color_138: rgba(255, 255, 255, 0.7);
$color_139: #545454;
$color_140: rgba(84, 84, 84, 1);
$color_141: #9C9C9C;
$color_142: #6c6c6c;
$color_143: #1F2937;
$color_144: #D9D9D9;
$color_145: rgba(0, 0, 0, 0.5);
$color_146: #B4B7C9;
$color_147: transparent;
$color_148: #DADADA;
$color_149: #05A144;
$color_150: #FF0101;
$color_151: #333333;
$color_152: #3F4254;
$color_153: #ED3A3A;
$color_154: #000;
$color_155: #555a64;
$color_156: #fbc418;
$color_157: #dedfe0;
$color_158: #171717;
$color_159: #666666;
$color_160: rgba(27, 163, 69, 1);
$font-family_1: 'Roboto', sans-serif;
$font-family_2: "Font Awesome 5 Free";
$font-family_3: 'slick';
$font-family_4: 'Poppins', sans-serif;
$font-family_5: 'FontAwesome';
$font-family_6: "Fontawesome";
$font-family_7: "Font Awesome 6 Free";
$font-family_8: 'feather';
$font-family_9: "fontawesome";
$background-color_1: #fff;
$background-color_2: #4c40ed;
$background-color_3: #2229C1;
$background-color_4: #F4EFFD;
$background-color_5: #F7F7FF;
$background-color_6: rgba(2, 182, 179, 0.12);
$background-color_7: rgba(17, 148, 247, 0.12);
$background-color_8: rgba(242, 17, 54, 0.12);
$background-color_9: rgba(255, 152, 0, 0.12);
$background-color_10: #28a745;
$background-color_11: rgba(15, 183, 107, 0.12);
$background-color_12: rgba(197, 128, 255, 0.12);
$background-color_13: rgba(40, 52, 71, 0.12);
$background-color_14: #20c0f3;
$background-color_15: #0C9E6F;
$background-color_16: #ff0100;
$background-color_17: #ffbc34;
$background-color_18: #eee;
$background-color_19: transparent;
$background-color_20: #fafafa;
$background-color_21: #dcdcdc;
$background-color_22: rgba(0, 0, 0, 0.2);
$background-color_23: #f43f3f;
$background-color_24: #f9f9f9;
$background-color_25: #ff008a;
$background-color_26: rgba(255, 255, 255, 0.6);
$background-color_27: #f5f5f5;
$background-color_28: #e30303;
$background-color_29: #261f84;
$background-color_30: #e1e5ea;
$background-color_31: #0B2540;
$background-color_32: white;
$background-color_33: #EDECF8;
$background-color_34: #FF4949;
$background-color_35: #6DCC76;
$background-color_36: #CCCFE0;
$background-color_37: #00CC45;
$background-color_38: #EAEAEA;
$background-color_39: #F6F6F6;
$background-color_40: #923564;
$background-color_41: rgba(0, 0, 0, 0.6);
$background-color_42: #e60073;
$background-color_43: #000;
$background-color_44: #F3F3F5;
$background-color_45: rgba(0, 0, 0, 0.1);
$background-color_46: #c23616;
$background-color_47: #47BC00;
$background-color_48: #858585;
$background-color_49: #f5f5f6;
$background-color_50: #0de0fe;
$background-color_51: #3E4265;
$background-color_52: #ff0080;
$background-color_53: #F9F9F9;
$background-color_54: #424242;
$background-color_55: #0D153199;
$background-color_56: #2A396D;
$background-color_57: #2A396DE5;
$background-color_58: #ff008099;
$background-color_59: #0D153180;
$background-color_60: #F3F3F3;
$background-color_61: #4C40ED;
$background-color_62: #0C112399;
$background-color_63: #00000080;
$background-color_64: #FFB800;
$background-color_65: #203066;
$background-color_66: #F3F6FC;
$background-color_67: #22C55E;
$background-color_68: #4C82F7;
$background-color_69: #FFF9F9;
$background-color_70: #05A144;
$background-color_71: #FAFAFA;
$background-color_72: #E8E8E8;
$border-color_1: #4c40ed;
$border-color_2: #dcdcdc;
$border-color_3: #bbb;
$border-color_4: transparent;
$border-color_5: #68616D;
$border-color_6: #3C4758;
$border-color_7: #000;
$border-color_8: #C2C9D1;
$border-color_9: #fff;
$border-color_10: #F6F6F6;
$border-color_11: transparent transparent #fff #fff;
$border-color_12: #EDF3F9;
$border-color_13: #00CC45;
$border-color_14: #0de0fe;
$border-color_15: rgba(220, 220, 220, 0.3);
$border-color_16: #ccc;
$border-color_17: #FCEFF8;
$border-color_18: #2229C1;
$border-color_19: #ff0080;
$border-color_20: transparent #fff transparent transparent;
$border-color_21: #ff008a;
$border-color_22: #424242;
$border-color_23: #333;
$border-color_24: #2229c1;
$border-color_25: #E1E1E1;
$border-color_26: #E8E8E8;
$border-color_27: #000 #000;
$border-color_28: transparent #fff #fff transparent;
$border-top-color_1: #ddd;
$border-top-color_2: #4c40ed;
$border-top-color_3: #0B2540;
$border-bottom-color_1: #ddd;
$border-bottom-color_2: #4c40ed;
/*
Author       : Dreamguys
Template Name: Truelysell - Bootstrap Html Template
Version      : 1.0
*/
/*============================
 [Table of CSS]
1. General
2. Bootstrap Classes
3. Avatar
4. Nav Tabs
5. Table
6. Modal
7. Header
8. Mobile Menu
9. Slider
10. Home
11. About Us
12. Services
13. Booking
14. search
15. Error
16. Contact Us
17. Login
18. Customer Profile
19. Customer Dashboard
20. Customer Reviews
21. Booking List
22. FAQ
23. Pricing
24. Category
25. Maintenance
26. Coming Soon
27. Terms & Conditions
28. Provider Header
29. Sidebar
30. Provider Dashboard
31. Provider Signup
32. Provider Services
33. Provider Payout
34. Provider Availability
35. Coupon
36. Provider Settings
37. Provider Plan
38. Add Service Information
39. Orders
40. Calendar
41. Cursor
42. Booking Details
43. Chat
44. Blog List
45. Works
46. Invoice
47. Provider Details
48. Service list-item
49. Header Two
50. Home Two
51. Footer
52. Responsive
========================================*/
/*-----------------
    1. General
-----------------------*/
/*-----------------
    2. Bootstrap Classes
-----------------------*/
/*-----------------
	3. Avatar
-----------------------*/
/*-----------------
	4. Nav Tabs
-----------------------*/
/*-----------------
    5. Table
-----------------------*/
/*-----------------
    6. Modal
-----------------------*/
/*-----------------
    7. Header
-----------------------*/
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 64, 237, 0.7);
    border-radius: 50%;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(76, 64, 237, 0);
    border-radius: 50%;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 64, 237, 0);
    border-radius: 50%;
  }
}
/*-----------------
	8. Mobile Menu
-----------------------*/
/*-----------------
	9. Slider
-----------------------*/
/*-----------------
	9. Slick Slider
-----------------------*/
/*-----------------
    10. Home
-----------------------*/
@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes hero-thumb1-animation {
  0% {
    transform: translateY(-20px) translateX(50px);
  }
  to {
    transform: translateY(-20px) translateX(0);
  }
}
@keyframes hero-thumb2-animation {
  0% {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
/*-----------------
	11. About Us
-----------------------*/
/*-----------------
	12. Services
-----------------------*/
/*-----------------
	13. Booking
-----------------------*/
/*-----------------
    14. search
-----------------------*/
/*-----------------
	15. Error
-----------------------*/
/*-----------------
	16. Contact Us
-----------------------*/
/*-----------------
	17. Login
-----------------------*/
/*-----------------
	18. Customer Profile
-----------------------*/
/*-----------------
	19. Customer Dashboard
-----------------------*/
/*-----------------
	20. Customer Reviews
-----------------------*/
/*-----------------
	21. Booking List
-----------------------*/
/*-----------------
	22. FAQ
-----------------------*/
/*-----------------
	23. Pricing
-----------------------*/
/*-----------------
	24. Category
-----------------------*/
/*-----------------
	25. Maintenance
-----------------------*/
/*-----------------
	26. Coming Soon
-----------------------*/
/*-----------------
	27. Terms & Conditions
-----------------------*/
/*-----------------
	28. Provider Header
-----------------------*/
/*-----------------
	29. Sidebar
-----------------------*/
/*-----------------
	30. Provider Dashboard
-----------------------*/
/*-----------------
	31. Provider Signup
-----------------------*/
/*-----------------
	32. Provider Services
-----------------------*/
/*-----------------
	33. Provider Payout
-----------------------*/
/*-----------------
	34. Provider Availability
-----------------------*/
/*-----------------
	35. Coupon
-----------------------*/
/*-----------------
	36. Provider Settings
-----------------------*/
/*-----------------
	37. Provider Plan
-----------------------*/
/*-----------------
	38. Add Service Information
-----------------------*/
/*-----------------
	39. Orders
-----------------------*/
/*-----------------
	40. Calendar
-----------------------*/
/*-----------------
	41. Cursor
-----------------------*/
/*-----------------
	42. Booking Details
-----------------------*/
/*-----------------
    43. Chat
-----------------------*/
@keyframes blink {
  50% {
    opacity: 1;
  }
}
/*-----------------
	44. Blog List
-----------------------*/
/*-----------------
	45. Works
-----------------------*/
/*-----------------
	46. Invoice
-----------------------*/
/*-----------------
	46. Notification
-----------------------*/
/*-----------------
	47. Provider Details
-----------------------*/
/*-----------------
	48. Service List
-----------------------*/
/*-----------------
    49. Header Two
-----------------------*/
/*-----------------
    50. Header seven
-----------------------*/
/*-----------------
    51. Header Eight
-----------------------*/
/*-----------------
    52. Header Nine
-----------------------*/
/*-----------------
    53. Header Six
-----------------------*/
/*-----------------
    54. Header Three
-----------------------*/
/*-----------------
    55. Header Four
-----------------------*/
/*-----------------
    56. Home Two
-----------------------*/
/*-----------------
    57. Home Three
-----------------------*/
/*-----------------
    58. Home Four
-----------------------*/
/*-----------------
    59. Home Five
-----------------------*/
/*-----------------
    60. Home Six
-----------------------*/
/*-----------------
    61. Home seven
-----------------------*/
/*-----------------
    56. Home Eight
-----------------------*/
/*-----------------
    57. Home Nine
-----------------------*/
/*-----------------
	58. Provider Subscription
-----------------------*/
/*-----------------
	59. Footer
-----------------------*/
/*-----------------
	60. Add Services
-----------------------*/
/*-----------------
	61. Chat Profile
-----------------------*/
/*-----------------
	62. Installation
-----------------------*/
/*-----------------
	63. Verification
-----------------------*/
/* Appointment-settings */
/* Notification */
/* Booking-Details */
/* Service-Select */
/*-----------------
	52. Responsive
-----------------------*/
body {
  background-color: $background-color_1;
  color: $color_1;
  font-family: $font-family_1;
  font-size: 16px;
  height: 100%;
  overflow-x: hidden;
  line-height: 1.6;
}
h1 {
  color: $color_2;
  font-family: $font-family_1;
  font-weight: 600;
  font-size: 36px;
}
h2 {
  color: $color_2;
  font-family: $font-family_1;
  font-weight: 600;
  font-size: 32px;
}
h3 {
  color: $color_2;
  font-family: $font-family_1;
  font-weight: 600;
  font-size: 28px;
}
h4 {
  color: $color_2;
  font-family: $font-family_1;
  font-weight: 600;
  font-size: 24px;
}
h5 {
  color: $color_2;
  font-family: $font-family_1;
  font-weight: 600;
  font-size: 18px;
}
h6 {
  color: $color_2;
  font-family: $font-family_1;
  font-weight: 600;
  font-size: 16px;
}
a {
  color: $color_3;
  text-decoration: none;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  &:hover {
    color: $color_4;
    text-decoration: none;
    outline: none;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
  }
  &:focus {
    color: $color_4;
    text-decoration: none;
    outline: none;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
  }
}
button {
  &:focus {
    outline: 0;
  }
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.btn.focus {
  box-shadow: none;
}
.btn {
  &:focus {
    box-shadow: none;
  }
}
.btn.btn-white {
  background: #fff;
  border: none !important;
  color: $color_5 !important;
}
.btn-primary {
  background-color: $background-color_2;
  border: 1px solid #4c40ed;
  box-shadow: inset 0 0 0 #2229C1;
  border-radius: 5px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    img {
      filter: brightness(0) invert(1);
    }
  }
  &:active {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_3;
      border: 1px solid #2229C1;
      box-shadow: inset 0 70px 0 0 #2229C1;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
      &:not(:disabled) {
        &:not(.disabled) {
          box-shadow: unset;
        }
      }
    }
    &:hover {
      background-color: $background-color_3;
      border: 1px solid #2229C1;
      box-shadow: inset 0 70px 0 0 #2229C1;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:not(:disabled) {
      &:not(.disabled) {
        background-color: $background-color_4;
        border: 1px solid #F4EFFD;
        box-shadow: inset 70px 0 0 0 #F4EFFD;
        color: $color_1;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        transition: all 0.7s;
      }
    }
  }
  &:focus {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:disabled {
    background-color: $background-color_4;
    border: 1px solid #F4EFFD;
    box-shadow: inset 70px 0 0 0 #F4EFFD;
    color: $color_1;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-primary.active {
  background-color: $background-color_3;
  border: 1px solid #2229C1;
  box-shadow: inset 0 70px 0 0 #2229C1;
  color: $color_6;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:focus {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:not(:disabled) {
      &:not(.disabled) {
        box-shadow: unset;
      }
    }
  }
  &:hover {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:not(:disabled) {
    &:not(.disabled) {
      background-color: $background-color_4;
      border: 1px solid #F4EFFD;
      box-shadow: inset 70px 0 0 0 #F4EFFD;
      color: $color_1;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
}
.open {
  > .dropdown-toggle.btn-primary {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_3;
      border: 1px solid #2229C1;
      box-shadow: inset 0 70px 0 0 #2229C1;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:hover {
      background-color: $background-color_3;
      border: 1px solid #2229C1;
      box-shadow: inset 0 70px 0 0 #2229C1;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
  > .dropdown-toggle.btn-primary.focus {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  > .dropdown-toggle.btn-outline-primary.focus {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  > .dropdown-toggle.btn-outline-primary {
    &:focus {
      background-color: $background-color_2;
      border: 1px solid #4c40ed;
      box-shadow: inset 0 70px 0 0 #4c40ed;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:hover {
      background-color: $background-color_2;
      border: 1px solid #4c40ed;
      box-shadow: inset 0 70px 0 0 #4c40ed;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
  > .dropdown-toggle.btn-secondary {
    background-color: $background-color_5;
    border: 1px solid #F7F7FF;
    box-shadow: inset 0 70px 0 0 #F7F7FF;
    color: $color_4;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_1;
      border: 1px solid #4c40ed;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_5;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:hover {
      background-color: $background-color_1;
      border: 1px solid #4c40ed;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_5;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
  > .dropdown-toggle.btn-secondary.focus {
    background-color: $background-color_1;
    border: 1px solid #4c40ed;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_5;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  > .dropdown-toggle.btn-danger {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_1;
      border: 1px solid #F2535B;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_7;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:hover {
      background-color: $background-color_1;
      border: 1px solid #F2535B;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_7;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
  > .dropdown-toggle.btn-danger.focus {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-primary.active.focus {
  background-color: $background-color_3;
  border: 1px solid #2229C1;
  box-shadow: inset 0 70px 0 0 #2229C1;
  color: $color_6;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.focus {
  &:active {
    background-color: $background-color_3;
    border: 1px solid #2229C1;
    box-shadow: inset 0 70px 0 0 #2229C1;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.show {
  > .btn-primary.dropdown-toggle {
    background-color: $background-color_4;
    border: 1px solid #F4EFFD;
    box-shadow: inset 70px 0 0 0 #F4EFFD;
    color: $color_1;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      box-shadow: unset;
    }
  }
  > .btn-secondary.dropdown-toggle {
    background-color: $background-color_2;
    border-color: $border-color_1;
    color: $color_6;
    background-color: $background-color_2;
    border-color: $border-color_1;
    color: $color_6;
    &:focus {
      box-shadow: unset;
      box-shadow: unset;
    }
  }
}
.btn-primary.disabled {
  background-color: $background-color_4;
  border: 1px solid #F4EFFD;
  box-shadow: inset 70px 0 0 0 #F4EFFD;
  color: $color_1;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary {
  background-color: $background-color_1;
  border: 1px solid #4c40ed;
  color: $color_5;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 5px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:focus {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:active {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_2;
      border: 1px solid #4c40ed;
      box-shadow: inset 0 70px 0 0 #4c40ed;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:hover {
      background-color: $background-color_2;
      border: 1px solid #4c40ed;
      box-shadow: inset 0 70px 0 0 #4c40ed;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
}
.btn-outline-primary.active {
  background-color: $background-color_2;
  border: 1px solid #4c40ed;
  box-shadow: inset 0 70px 0 0 #4c40ed;
  color: $color_6;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:focus {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:hover {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-outline-primary.active.focus {
  background-color: $background-color_2;
  border: 1px solid #4c40ed;
  box-shadow: inset 0 70px 0 0 #4c40ed;
  color: $color_6;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary.focus {
  &:active {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-secondary {
  background: #F7F7FF;
  border: 1px solid #F7F7FF;
  box-shadow: inset 0 0 0 0 #fff;
  color: $color_5;
  border-radius: 4px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_5;
    border: 1px solid #F7F7FF;
    box-shadow: inset 0 70px 0 0 #F7F7FF;
    color: $color_4;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:focus {
    background-color: $background-color_5;
    border: 1px solid #F7F7FF;
    box-shadow: inset 0 70px 0 0 #F7F7FF;
    color: $color_4;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:active {
    background-color: $background-color_5;
    border: 1px solid #F7F7FF;
    box-shadow: inset 0 70px 0 0 #F7F7FF;
    color: $color_4;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_1;
      border: 1px solid #4c40ed;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_5;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
      &:not(:disabled) {
        &:not(.disabled) {
          box-shadow: unset;
          box-shadow: unset;
        }
      }
    }
    &:hover {
      background-color: $background-color_1;
      border: 1px solid #4c40ed;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_5;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:not(:disabled) {
      &:not(.disabled) {
        background-color: $background-color_2;
        border-color: $border-color_1;
        color: $color_6;
        background-color: $background-color_2;
        border-color: $border-color_1;
        color: $color_6;
      }
    }
  }
  &:disabled {
    background-color: $background-color_2;
    border-color: $border-color_1;
    color: $color_6;
    background-color: $background-color_2;
    border-color: $border-color_1;
    color: $color_6;
  }
}
.btn-secondary.active {
  background-color: $background-color_5;
  border: 1px solid #F7F7FF;
  box-shadow: inset 0 70px 0 0 #F7F7FF;
  color: $color_4;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:focus {
    background-color: $background-color_1;
    border: 1px solid #4c40ed;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_5;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:not(:disabled) {
      &:not(.disabled) {
        box-shadow: unset;
        box-shadow: unset;
      }
    }
  }
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #4c40ed;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_5;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:not(:disabled) {
    &:not(.disabled) {
      background-color: $background-color_2;
      border-color: $border-color_1;
      color: $color_6;
      background-color: $background-color_2;
      border-color: $border-color_1;
      color: $color_6;
    }
  }
}
.btn-secondary.active.focus {
  background-color: $background-color_1;
  border: 1px solid #4c40ed;
  box-shadow: inset 70px 0 0 0 #fff;
  color: $color_5;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.focus {
  &:active {
    background-color: $background-color_1;
    border: 1px solid #4c40ed;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_5;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-secondary.disabled {
  background-color: $background-color_2;
  border-color: $border-color_1;
  color: $color_6;
  background-color: $background-color_2;
  border-color: $border-color_1;
  color: $color_6;
}
.btn-danger {
  background: #F2535B;
  border: 1px solid #F2535B;
  box-shadow: inset 0 0 0 0 #fff;
  color: $color_6;
  border-radius: 10px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:focus {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:active {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:focus {
      background-color: $background-color_1;
      border: 1px solid #F2535B;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_7;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
    &:hover {
      background-color: $background-color_1;
      border: 1px solid #F2535B;
      box-shadow: inset 70px 0 0 0 #fff;
      color: $color_7;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
}
.btn-danger.active {
  background-color: $background-color_1;
  border: 1px solid #F2535B;
  box-shadow: inset 0 70px 0 0 #fff;
  color: $color_7;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:focus {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-danger.active.focus {
  background-color: $background-color_1;
  border: 1px solid #F2535B;
  box-shadow: inset 70px 0 0 0 #fff;
  color: $color_7;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger.focus {
  &:active {
    background-color: $background-color_1;
    border: 1px solid #F2535B;
    box-shadow: inset 70px 0 0 0 #fff;
    color: $color_7;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-danger-light {
  background: #FFF5F5;
  border: 1px solid #FFF5F5;
  color: $color_8;
  border-radius: 4px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #FF4949;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_8;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-light-success {
  background: #F3FFF6;
  border: 1px solid #F3FFF6;
  color: $color_9;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #34C85D;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_10;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-light-warning {
  background: #FFF9E8;
  border: 1px solid #FFF9E8;
  color: $color_11;
  border-radius: 6px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #FFC107;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_11;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.btn-secondary-outline {
  background: #F7F7FF;
  border: 1px solid #4c40ed;
  color: $color_5;
  border-radius: 4px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  &:hover {
    background-color: $background-color_2;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #4c40ed;
    color: $color_6;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
}
.bg-info-light {
  background-color: $background-color_6 !important;
  color: $color_12 !important;
}
.bg-primary-light {
  background-color: $background-color_7 !important;
  color: $color_13 !important;
}
.bg-danger-light {
  background-color: $background-color_8 !important;
  color: $color_14 !important;
}
.bg-warning-light {
  background-color: $background-color_9 !important;
  color: $color_15 !important;
}
.bg-success {
  background-color: $background-color_10 !important;
}
.bg-success-light {
  background-color: $background-color_11 !important;
  color: $color_16 !important;
}
.bg-purple-light {
  background-color: $background-color_12 !important;
  color: $color_17 !important;
}
.bg-default-light {
  background-color: $background-color_13 !important;
  color: $color_18 !important;
}
.text-light-success {
  color: $color_19 !important;
}
.text-light-danger {
  color: $color_20 !important;
}
.form-group {
  margin-bottom: 1rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control {
  border-color: $border-color_2;
  color: $color_2;
  min-height: 42px;
  padding: 6px 15px;
  border-radius: 5px;
  border-color: $border-color_2;
  color: $color_116;
  font-size: 15px;
  min-height: 42px;
  padding: 6px 15px;
  &:focus {
    border-color: $border-color_3;
    box-shadow: none;
    outline: 0 none;
  }
  &::placeholder {
    color: $color_1;
  }
}
.breadcrumb-title {
  h2 {
    font-weight: 500;
    font-size: 25px;
    margin: 0;
  }
  span {
    color: $color_2;
  }
  font-size: 42px;
  margin-bottom: 15px;
}
.text-body {
  color: $color_1 !important;
}
.pagination {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  > ul {
    float: left;
    width: 100%;
    text-align: center;
    margin: 0;
    li {
      float: none;
      display: inline-block;
      margin: 0;
      a {
        float: left;
        width: 35px;
        height: 35px;
        background: #fff;
        border: 1px solid #e8ecec;
        line-height: 34px;
        font-size: 13px;
        color: $color_21;
      }
      span {
        float: left;
        width: 35px;
        height: 35px;
        background: #fff;
        border: 1px solid #e8ecec;
        line-height: 34px;
        font-size: 13px;
        color: $color_21;
      }
    }
    li.arrow {
      a {
        background: #f1f1f1;
        font-size: 17px;
        margin: 0 9px;
      }
    }
    li.active {
      a {
        background: #4c40ed;
        border-color: $border-color_1;
        color: $color_6;
      }
    }
  }
}
.card {
  background: #fff;
  border: 2px solid #EBEDF0;
  border-radius: 6px;
  margin-bottom: 24px;
  .card-body {
    padding: 15px;
  }
}
.text-primary {
  color: $color_5 !important;
}
.text-danger {
  color: $color_22 !important;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .border {
    border-width: 3px !important;
  }
  .rounded {
    border-radius: 6px !important;
  }
  .avatar-title {
    font-size: 18px;
  }
}
.avatar-title {
  width: 100%;
  height: 100%;
  background-color: $background-color_14;
  color: $color_6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.avatar-away {
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: '';
    border: 2px solid #fff;
    background-color: $background-color_17;
  }
}
.avatar-offline {
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: '';
    border: 2px solid #fff;
    background-color: $background-color_16;
  }
}
.avatar-online {
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: '';
    border: 2px solid #fff;
    background-color: $background-color_15;
  }
}
.avatar-xs {
  width: 29px;
  height: 29px;
  .border {
    border-width: 2px !important;
  }
  .rounded {
    border-radius: 4px !important;
  }
  .avatar-title {
    font-size: 12px;
  }
}
.avatar-xs.avatar-away {
  &::before {
    border-width: 1px;
  }
}
.avatar-xs.avatar-offline {
  &::before {
    border-width: 1px;
  }
}
.avatar-xs.avatar-online {
  &::before {
    border-width: 1px;
  }
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  .border {
    border-width: 3px !important;
  }
  .rounded {
    border-radius: 4px !important;
  }
  .avatar-title {
    font-size: 15px;
  }
}
.avatar-sm.avatar-away {
  &::before {
    border-width: 2px;
  }
}
.avatar-sm.avatar-offline {
  &::before {
    border-width: 2px;
  }
}
.avatar-sm.avatar-online {
  &::before {
    border-width: 2px;
  }
}
.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
  .border {
    border-width: 3px !important;
  }
  .rounded {
    border-radius: 8px !important;
  }
  .avatar-title {
    font-size: 24px;
  }
}
.avatar-lg.avatar-away {
  &::before {
    border-width: 3px;
  }
}
.avatar-lg.avatar-offline {
  &::before {
    border-width: 3px;
  }
}
.avatar-lg.avatar-online {
  &::before {
    border-width: 3px;
  }
}
textarea {
  resize: none;
}
.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
  > li {
    > a {
      margin-right: 0;
      color: $color_23;
      border-radius: 0;
      &:hover {
        border-color: $border-color_4;
        color: $color_24;
      }
      &:focus {
        border-color: $border-color_4;
        color: $color_24;
      }
    }
  }
  .nav-link {
    border-radius: 0;
    &:focus {
      background-color: $background-color_18;
      border-color: $border-color_4;
      color: $color_24;
    }
    &:hover {
      background-color: $background-color_18;
      border-color: $border-color_4;
      color: $color_24;
    }
  }
}
.nav-tabs.nav-tabs-solid {
  > li {
    > a {
      color: $color_24;
      color: $color_24;
      border-color: $border-color_4;
      &:hover {
        background-color: $background-color_21;
      }
      &:focus {
        background-color: $background-color_21;
      }
    }
    > a.active {
      background-color: $background-color_2;
      border-color: $border-color_1;
      color: $color_6;
      &:hover {
        background-color: $background-color_2;
        border-color: $border-color_1;
        color: $color_6;
      }
      &:focus {
        background-color: $background-color_2;
        border-color: $border-color_1;
        color: $color_6;
      }
    }
    margin-bottom: 0;
  }
  > .active {
    > a {
      background-color: $background-color_2;
      border-color: $border-color_1;
      color: $color_6;
      &:hover {
        background-color: $background-color_2;
        border-color: $border-color_1;
        color: $color_6;
      }
      &:focus {
        background-color: $background-color_2;
        border-color: $border-color_1;
        color: $color_6;
      }
    }
  }
  background-color: $background-color_20;
  border: 0;
  > .open {
    &:not(.active) {
      > a {
        background-color: $background-color_21;
        border-color: $border-color_4;
      }
    }
  }
}
.tab-content {
  padding-top: 20px;
}
.nav-link {
  &:focus {
    color: $color_25;
  }
  &:hover {
    color: $color_25;
  }
}
.nav-tabs.nav-justified {
  > li {
    > a {
      border-radius: 0;
      margin-bottom: 0;
      &:hover {
        border-bottom-color: $border-bottom-color_1;
      }
      &:focus {
        border-bottom-color: $border-bottom-color_1;
      }
    }
  }
}
.nav-tabs.nav-justified.nav-tabs-solid {
  > li {
    > a {
      border-color: $border-color_4;
    }
  }
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
  > li {
    > a {
      border-radius: 50px;
    }
    > a.active {
      border-radius: 50px;
      &:hover {
        border-radius: 50px;
      }
      &:focus {
        border-radius: 50px;
      }
    }
  }
}
.nav-tabs-justified {
  > li {
    > a {
      border-radius: 0;
      margin-bottom: 0;
      &:hover {
        border-bottom-color: $border-bottom-color_1;
      }
      &:focus {
        border-bottom-color: $border-bottom-color_1;
      }
    }
  }
}
.nav-tabs-justified.nav-tabs-solid {
  > li {
    > a {
      border-color: $border-color_4;
    }
  }
}
.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
  > li {
    > a {
      border-width: 2px 0 0 0;
      &:hover {
        border-width: 2px 0 0 0;
      }
      &:focus {
        border-width: 2px 0 0 0;
      }
    }
  }
}
.nav-tabs.nav-tabs-top {
  > li {
    margin-bottom: 0;
    > a {
      border-width: 2px 0 0 0;
      &:hover {
        border-width: 2px 0 0 0;
        border-top-color: $border-top-color_1;
      }
      &:focus {
        border-width: 2px 0 0 0;
        border-top-color: $border-top-color_1;
      }
    }
    > a.active {
      border-top-color: $border-top-color_2;
      &:hover {
        border-top-color: $border-top-color_2;
      }
      &:focus {
        border-top-color: $border-top-color_2;
      }
    }
  }
  > li.open {
    > a {
      border-top-color: $border-top-color_1;
    }
  }
  > li + li {
    > a {
      margin-left: 1px;
    }
  }
}
.nav-tabs.nav-tabs-bottom {
  > li {
    > a.active {
      border-bottom-width: 2px;
      border-color: $border-color_4;
      border-bottom-color: $border-bottom-color_2;
      background-color: $background-color_19;
      transition: none 0s ease 0s;
      -moz-transition: none 0s ease 0s;
      -o-transition: none 0s ease 0s;
      -ms-transition: none 0s ease 0s;
      -webkit-transition: none 0s ease 0s;
      &:hover {
        border-bottom-width: 2px;
        border-color: $border-color_4;
        border-bottom-color: $border-bottom-color_2;
        background-color: $background-color_19;
        transition: none 0s ease 0s;
        -moz-transition: none 0s ease 0s;
        -o-transition: none 0s ease 0s;
        -ms-transition: none 0s ease 0s;
        -webkit-transition: none 0s ease 0s;
      }
      &:focus {
        border-bottom-width: 2px;
        border-color: $border-color_4;
        border-bottom-color: $border-bottom-color_2;
        background-color: $background-color_19;
        transition: none 0s ease 0s;
        -moz-transition: none 0s ease 0s;
        -o-transition: none 0s ease 0s;
        -ms-transition: none 0s ease 0s;
        -webkit-transition: none 0s ease 0s;
      }
    }
  }
}
.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
  > li {
    > a {
      border-width: 2px 0 0 0;
      &:hover {
        border-width: 2px 0 0 0;
      }
      &:focus {
        border-width: 2px 0 0 0;
      }
    }
  }
}
.table {
  tbody {
    td {
      padding: 12px;
      font-size: 15px;
      border-color: $border-color_2;
      color: $color_1;
    }
  }
  th {
    padding: 15px;
    font-size: 15px;
    border-color: $border-color_2;
    color: $color_1;
    vertical-align: middle;
    white-space: nowrap;
  }
  tr {
    padding: 15px;
    font-size: 15px;
    border-color: $border-color_2;
    color: $color_1;
  }
  td {
    vertical-align: middle;
  }
}
.table > :not(:first-child) {
  border-top: 0;
}
table.table {
  td {
    h2 {
      display: inline-block;
      font-size: inherit;
      font-weight: 400;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      a {
        font-weight: 500;
        font-size: 15px;
        color: $color_2;
        &:hover {
          color: $color_5;
        }
      }
      span {
        color: $color_1;
        display: block;
        font-size: 13px;
        margin-top: 7px;
        font-weight: normal;
      }
    }
    h2.table-avatar {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-size: inherit;
      font-weight: 400;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      white-space: nowrap;
    }
    h2.table-user {
      span {
        font-size: 13px;
        margin-top: 5px;
        font-weight: normal;
      }
    }
    .table-actions {
      white-space: nowrap;
    }
  }
}
table.dataTable {
  thead {
    .sorting {
      &:before {
        right: 1em;
        content: "\f0de";
        font-family: $font-family_2;
      }
      &:after {
        right: 1em;
        content: "\f0dd";
        font-family: $font-family_2;
      }
    }
    .sorting_asc {
      &:before {
        right: 1em;
        content: "\f0de";
        font-family: $font-family_2;
      }
      &:after {
        right: 1em;
        content: "\f0dd";
        font-family: $font-family_2;
      }
    }
    .sorting_desc {
      &:before {
        right: 1em;
        content: "\f0de";
        font-family: $font-family_2;
      }
      &:after {
        right: 1em;
        content: "\f0dd";
        font-family: $font-family_2;
      }
    }
    .sorting_asc_disabled {
      &:before {
        right: 1em;
        content: "\f0de";
        font-family: $font-family_2;
      }
      &:after {
        right: 1em;
        content: "\f0dd";
        font-family: $font-family_2;
      }
    }
    .sorting_desc_disabled {
      &:before {
        right: 1em;
        content: "\f0de";
        font-family: $font-family_2;
      }
      &:after {
        right: 1em;
        content: "\f0dd";
        font-family: $font-family_2;
      }
    }
  }
}
.dataTables_paginate {
  .pagination {
    .previous {
      display: none;
    }
    .next {
      display: none;
    }
  }
}
#tablelength {
  label {
    font-weight: 500;
    font-size: 14px;
    color: $color_26;
    select {
      width: auto;
      display: inline-block;
      border: 0.5px solid #dcdcdc;
      border-radius: 5px;
      padding: 4px 10px;
      min-height: auto;
      color: $color_26;
      margin: 0 8px;
    }
  }
}
#tablepagination {
  ul {
    margin: 0;
  }
}
.table-ingopage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .dataTables_info {
    font-weight: 500;
    font-size: 14px;
    color: $color_26;
    margin-right: 10px;
  }
}
.custom-modal {
  .modal-header {
    padding: 30px;
  }
  .modal-body {
    padding: 30px;
  }
  .modal-content {
    border-radius: 4px;
    border: 0;
    padding: 0;
  }
  .close-btn {
    background: #F82424;
    border: 0;
    border-radius: 50%;
    color: $color_6;
    font-size: 15px;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 99;
    &:hover {
      background: #4c40ed;
      color: $color_6;
    }
  }
}
.modal-title {
  font-weight: 500;
  font-size: 24px;
}
.header {
  background: #fff;
  position: sticky;
  box-shadow: 0px 10px 40px rgba(202, 202, 202, 0.2);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  .has-arrow {
    .dropdown-toggle {
      &:after {
        border-top: 0;
        border-left: 0;
        border-bottom: 2px solid #7E84A3;
        border-right: 2px solid #7E84A3;
        content: '';
        height: 8px;
        display: inline-block;
        pointer-events: none;
        -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        width: 8px;
        vertical-align: 2px;
        margin-right: 10px;
        border-top: 0;
        border-left: 0;
        border-bottom: 2px solid #68616D;
        border-right: 2px solid #68616D;
        content: '';
        height: 8px;
        display: inline-block;
        pointer-events: none;
        -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        width: 8px;
        vertical-align: 2px;
      }
    }
    .dropdown-toggle[aria-expanded="true"] {
      &:after {
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-left: 0;
      border-bottom: 2px solid #757575;
      border-right: 2px solid #757575;
      content: '';
      height: 8px;
      display: inline-block;
      pointer-events: none;
      -webkit-transform-origin: 66% 66%;
      -ms-transform-origin: 66% 66%;
      transform-origin: 66% 66%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      width: 8px;
      vertical-align: 2px;
      margin-left: 10px;
      display: none;
    }
  }
  .header-left {
    float: left;
    height: 70px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 270px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    .logo {
      display: inline-block;
      line-height: 60px;
      img {
        max-height: 24px;
        width: auto;
      }
    }
  }
  .dropdown-menu {
    > li {
      > a {
        position: relative;
        padding: 10px 18px;
        &:focus {
          background-color: $background-color_2;
          color: $color_6;
          i {
            color: $color_6;
          }
        }
        &:hover {
          background-color: $background-color_2;
          color: $color_6;
          i {
            color: $color_6;
          }
        }
        i {
          color: $color_5;
          margin-right: 10px;
          text-align: center;
          width: 18px;
        }
      }
    }
  }
  .user-menu {
    .dropdown-menu {
      > li {
        > a {
          i {
            color: $color_5;
            font-size: 16px;
            margin-right: 10px;
            min-width: 18px;
            text-align: center;
          }
          &:focus {
            i {
              color: $color_6;
            }
          }
          &:hover {
            i {
              color: $color_6;
            }
          }
        }
      }
    }
  }
  .home {
    &:hover, &:focus {
      background-color: #fff !important;
    }
  }
  .home-icon {
    color: #004aad !important;
    //&:hover {
    //  color: #004aad;
    //}
  }
}
.header.fixed {
  position: fixed;
  background-color: $background-color_1;
  box-shadow: 0px 3px 53px rgb(197 197 197 / 27%);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.logo {
  img {
    height: auto;
    max-width: 100%;
    max-height: 35px;
  }
  a {
    float: left;
    img {
      float: left;
      max-height: 32px;
    }
  }
}
.mobile-toolbar {
  display:none;
}
.header-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  height: 85px;
  padding: 0;
  margin-bottom: 0;
}
.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    > a {
      color: $color_2;
    }
    .submenu {
      li {
        a {
          display: block;
          padding: 8px 15px 8px 15px;
          clear: both;
          white-space: nowrap;
          font-size: 14px;
          color: $color_26;
          -webkit-transition: all .35s ease;
          transition: all .35s ease;
          width: 100%;
          position: relative;
        }
        position: relative;
      }
      > li.has-submenu {
        > a {
          &::after {
            content: "\f054";
            font-family: $font-family_2;
            font-weight: 900;
            position: absolute;
            right: 15px;
            top: 12px;
            font-size: 13px;
          }
        }
      }
    }
  }
  > li.active {
    > a {
      color: $color_5;
    }
  }
  li {
    a {
      display: block;
      font-weight: 500;
      &:hover {
        color: $color_5;
      }
    }
  }
  li.login-link {
    display: none;
  }
}
.header-navbar-rht {
  margin: 0;
  padding: 0;
  > li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-right: 20px;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:last-child {
      padding-right: 0px;
    }
    .dropdown-menu {
      border: 0;
      box-shadow: 0 0 3px rgb(0 0 0 / 10%);
      .dropdown-item {
        border-top: 1px solid #f0f0f0;
        padding: 10px 15px;
        font-size: 0.9375rem;
        &:first-child {
          border-top: 0;
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }
    .fa-bell {
      font-size: 24px;
    }
    .fa-comments {
      font-size: 24px;
    }
    a.header-login {
      &:hover {
        background-color: $background-color_1;
        border: 1px solid #4c40ed;
        box-shadow: inset 210px 0 0 0 #fff;
        color: $color_5;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
      &:focus {
        background-color: $background-color_1;
        border: 1px solid #4c40ed;
        box-shadow: inset 210px 0 0 0 #fff;
        color: $color_5;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  li {
    > .dropdown-menu {
      a {
        &:hover {
          color: $color_5;
          background-color: $background-color_1;
        }
      }
    }
    > a.header-login {
      background-color: $background-color_2;
      border: 1px solid #4c40ed;
      color: $color_6;
      box-shadow: inset 0 0 0 #2229C1;
      border-radius: 5px;
      padding: 9px 20px;
      font-weight: 500;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
      min-height: 46px;
      &:hover {
        background-color: $background-color_3;
        border: 1px solid #2229C1;
        box-shadow: inset 0 70px 0 0 #2229C1;
        color: $color_6;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        transition: all 0.7s;
      }
      img {
        margin-right: 7px;
      }
    }
    > a.header-reg {
      font-weight: 500;
      color: $color_2;
      text-decoration: none;
      padding: 0;
      &:hover {
        color: $color_4;
      }
    }
  }
  .dropdown-menu {
    min-width: 200px;
    padding: 0;
    right: 0;
    left: unset;
    .dropdown-item {
      padding: 7px 15px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border-top: 1px solid #e3e3e3;
      padding: 10px 15px;
      &:hover {
        color: $color_5;
      }
    }
  }
}
.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  align-items: center;
  .dropdown-menu {
    min-width: 170px;
    padding: 0;
    margin-top: 0.3rem;
    left: -5rem;
    .dropdown-item {
      padding: 7px 15px;
      display: flex;
      align-items: center;
      border-top: 1px solid #e3e3e3;
      padding: 10px 15px;
      font-size: 14px;
      &:first-child {
        border-top: 0;
      }
      &:hover {
        color: $color_5;
      }
    }
  }
}
.user-menu.nav {
  > li {
    > a {
      color: $color_6;
      font-size: 14px;
      line-height: 58px;
      padding: 0 15px;
      height: 60px;
      &:hover {
        background-color: $background-color_22;
        background-color: $background-color_2;
        i {
          color: $color_6;
        }
      }
      &:focus {
        background-color: $background-color_22;
        background-color: $background-color_2;
        i {
          color: $color_6;
        }
      }
      .badge {
        background-color: $background-color_23;
        display: block;
        font-size: 10px;
        font-weight: bold;
        min-height: 15px;
        min-width: 15px;
        position: absolute;
        right: 3px;
        top: 6px;
      }
      > i {
        font-size: 1.5rem;
        line-height: 60px;
      }
    }
    > a.mobile_btn {
      border: 0;
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;
      border: 0;
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
    > a.viewsite {
      &:focus {
        background-color: $background-color_19;
        i {
          color: $color_1;
        }
      }
    }
  }
  > li.view-btn {
    > a {
      &:hover {
        i {
          color: $color_6;
        }
        background-color: $background-color_42;
        color: $color_6;
      }
      &:focus {
        i {
          color: $color_6;
        }
        background-color: $background-color_40 !important;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color_6;
      padding: 9px 15px;
      border-radius: 6px;
      background-color: $background-color_2;
      i {
        font-size: 17px;
        position: relative;
      }
    }
  }
}
.user-img {
  display: inline-block;
  position: relative;
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 2s infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  > img {
    height: 40px;
    object-fit: cover;
    width: 40px;
    border: 3px solid #fff;
    outline: 3px solid #D9D9D9;
  }
}
.user-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  background-color: $background-color_24;
  display: flex;
  padding: 10px 15px;
  background-color: $background-color_24;
  display: flex;
  padding: 10px 15px;
  .user-text {
    margin-left: 10px;
    margin-left: 10px;
    h6 {
      font-size: 15px;
      margin-bottom: 2px;
      margin-bottom: 2px;
    }
  }
}
.flag-nav {
  > a {
    color: $color_2;
    border: 1px solid #E4E4E8;
    border-radius: 8px;
    font-size: 14px;
    padding: 11px 13px;
    img {
      margin-right: 5px;
    }
  }
  .dropdown-item {
    img {
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}
.noti-dropdown {
  .dropdown-toggle {
    color: $color_6;
    font-size: 14px;
    line-height: 23px;
    padding: 0 15px;
    &::after {
      display: none;
    }
    span {
      background-color: $background-color_23;
      display: block;
      font-size: 10px;
      font-weight: bold;
      min-height: 15px;
      min-width: 15px;
      position: absolute;
      right: 3px;
      top: 5px;
    }
    i {
      float: right;
      line-height: 50px;
      margin-left: 15px;
      font-size: 21px;
      float: left;
      line-height: 50px;
      color: $color_6;
      margin: 0px;
      margin-left: 0px;
      cursor: pointer;
    }
  }
  .dropdown-menu {
    top: 63px !important;
    font-size: 14px;
  }
}
.notification-message {
  margin-bottom: 0;
  h6 {
    font-size: 14px;
    font-weight: 400;
    color: $color_2;
    position: relative;
    padding-left: 10px;
    margin-bottom: 8px;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: #DDDEE1;
      border-radius: 2px;
      width: 4px;
      height: 100%;
    }
  }
  .avatar-online {
    &::before {
      top: 0;
      background: #6DCC76;
    }
  }
}
.notifications {
  padding: 0;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
  .notification-time {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.35;
    color: $color_27;
  }
  .notify-btns {
    .btn {
      margin: 0 8px 8px 0;
    }
  }
  .media {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
    &:last-child {
      border-bottom: none;
    }
    a {
      display: block;
      padding: 0;
      border-radius: 2px;
      &:hover {
        background-color: $background-color_20;
      }
    }
    .avatar {
      margin-right: 15px;
      width: 38px;
      height: 38px;
    }
  }
  .media-list {
    .media-left {
      padding-right: 8px;
    }
  }
  .noti-content {
    &::-webkit-scrollbar {
      width: 6px;
      background: #EEF1FE;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(231, 231, 231);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #8A8A95;
      border-radius: 4px;
      &:hover {
        background: #1B2559;
      }
    }
  }
  ul.notification-list {
    > li {
      margin-top: 0;
      padding: 10px 15px;
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        border-radius: 2px;
      }
      > a {
        &:hover {
          background-color: $background-color_20;
        }
      }
      .list-item {
        border: 0;
        padding: 0;
        position: relative;
      }
    }
  }
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
  padding-right: 15px;
  select {
    border: 0;
    font-size: 12px;
    color: $color_1;
    &:focus-visible {
      outline: 0;
    }
  }
  .notification-title {
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    span {
      padding: 3px 6px;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
      color: $color_6;
      background: #4c40ed;
    }
  }
  .clear-noti {
    color: $color_2;
    float: right;
    font-size: 14px;
    &:hover {
      color: $color_5;
    }
  }
}
.topnav-dropdown-footer {
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #eee;
  a {
    display: block;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: $color_5;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $color_5;
      i {
        color: $color_5;
        margin-left: 8px;
        transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
      }
    }
    img {
      margin-left: 5px;
      filter: brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2990%) hue-rotate(239deg) brightness(91%) contrast(105%);
    }
  }
}
.noti-details {
  font-size: 14px;
  color: $color_2;
  margin-bottom: 8px;
  font-weight: 500;
  span {
    color: $color_1;
    font-weight: 400;
  }
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li.notification-message {
    p.noti-details {
      position: relative;
    }
    &:first-child {
      p.noti-details {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 20px;
          background: red;
          position: absolute;
          bottom: 5px;
          margin-left: 5px;
        }
      }
    }
    &:nth-child(2) {
      p.noti-details {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 20px;
          background: red;
          position: absolute;
          bottom: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
.noti-time {
  font-size: 14px;
  margin: 0;
}
.flag-dropdown {
  .nav-link {
    color: $color_2;
    padding: .5rem 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
  }
}
.account-item {
  .nav-link {
    padding: .5rem 0;
  }
}
.user-info {
  margin-left: 10px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: $color_2;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.user-infos {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-one {
}
.header-six {
  .bar-icon {
    span {
      background-color: $background-color_25;
    }
  }
  .menu-close {
    color: $color_28;
  }
}
.header-nine {
  .bar-icon {
    span {
      background-color: $background-color_25;
    }
  }
  .menu-close {
    color: $color_28;
  }
  .main-menu-wrapper {
    margin-left: 0;
  }
}
.sidebar-overlay {
  background-color: $background-color_26;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1039;
  background-color: $background-color_41;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.menu-opened {
  .main-menu-wrapper {
    transform: translateX(0);
  }
}
.menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  display: none;
}
.menu-logo {
  img {
    height: auto;
    max-width: 180px;
  }
}
.menu-close {
  font-size: 18px;
  color: $color_5;
}
.bar-icon {
  display: inline-block;
  width: 31px;
  span {
    background-color: $background-color_2;
    display: block;
    float: left;
    height: 2px;
    margin-bottom: 6px;
    width: 30px;
    border-radius: 2px;
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}
#mobile_btn {
  display: none;
  margin-right: 30px;
}
html.menu-opened {
  body {
    overflow: hidden;
    overflow: hidden;
  }
  overflow: hidden;
}
.main-menu-wrapper {
  margin: 0 15px 0;
}
.owl-nav {
  .owl-next {
    width: 32px;
    height: 32px;
    background: #fff !important;
    box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
    text-shadow: none;
    top: 4px;
    cursor: pointer;
    border: 0;
    border-radius: 50% !important;
    color: $color_2;
    margin-bottom: 30px;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    margin-left: 10px;
    &:hover {
      background-color: $background-color_2 !important;
      color: $color_6;
    }
    span {
      font-size: 34px;
      line-height: 25px;
    }
  }
  .owl-prev {
    width: 32px;
    height: 32px;
    background: #fff !important;
    box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
    text-shadow: none;
    top: 4px;
    cursor: pointer;
    border: 0;
    border-radius: 50% !important;
    color: $color_2;
    margin-bottom: 30px;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    &:hover {
      background-color: $background-color_2 !important;
      color: $color_6;
    }
    span {
      font-size: 34px;
      line-height: 25px;
    }
  }
}
.testimonial-slider {
  .owl-nav {
    .owl-next {
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      margin-bottom: 0;
      right: -60px;
    }
    .owl-prev {
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      margin-bottom: 0;
      left: -60px;
    }
  }
}
.gallery-widget {
  img {
    border-radius: 5px;
  }
}
[dir='rtl'] {
}
.hero-section {
  position: relative;
  min-height: 660px;
  background: url(../img/bg/bg-banner.png), url(../img/bg/bg-banner.png);
  background-repeat: no-repeat;
  background-position: left top, left bottom;
  &:before {
    content: "";
    background-image: url("../img/banner-right.png");
    background-repeat: no-repeat;
    background-position: 100% 0;
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.home-banner {
  min-height: 660px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.banner-imgs {
  .banner-1 {
    position: absolute;
    z-index: 2;
    top: 5.7rem;
    left: -6rem;
    max-width: 203px;
    max-height: 203px;
    &:before {
      position: absolute;
      content: "";
      background: url(../img/icons/banner-arrow.svg);
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      left: -9rem;
      z-index: -1;
      top: 6.5rem;
      transform: rotate(-15deg);
    }
  }
  .banner-2 {
    position: absolute;
    z-index: 2;
    max-width: 350px;
    top: 0;
    right: -45px;
  }
  .banner-3 {
    position: absolute;
    z-index: 2;
    max-width: 300px;
    bottom: -4rem;
    left: -3rem;
  }
  .banner-4 {
    position: absolute;
    z-index: 2;
    max-width: 145px;
    bottom: 1rem;
    right: 2.2rem;
  }
  padding: 65px 0 0;
  position: relative;
  height: 100%;
  min-height: 540px;
  img {
    border-radius: 50%;
  }
}
.shape-1 {
  animation: hero-thumb-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2.5s linear infinite alternate;
  -o-animation: hero-thumb-animation 2.5s linear infinite alternate;
}
.shape-2 {
  animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -o-animation: hero-thumb1-animation 2.5s linear infinite alternate;
}
.shape-3 {
  animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -o-animation: hero-thumb2-animation 2.5s linear infinite alternate;
}
.breadcrumb {
  margin-bottom: 0;
}
.section-search {
  position: relative;
  z-index: 9;
  max-width: 800px;
  h1 {
    font-size: 50px;
    color: $color_3;
    font-family: $font-family_4;
    margin-bottom: 20px;
    width: 40rem;
  }
  p {
    font-size: 18px;
    color: $color_1;
    font-weight: 500;
    margin: 0;
  }
}
.search-box {
  width: 100%;
  background: #fff;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 3px 53px rgba(197, 197, 197, 0.27);
  border-radius: 10px;
  padding: 15px;
  margin-top: 48px;
  display: inline-block;
  form {
    float: left;
    width: 100%;
  }
  &::-webkit-input-placeholder {
    font-size: 14px;
    color: $color_31;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    color: $color_31;
    opacity: 1;
  }
  &::placeholder {
    font-size: 14px;
    color: $color_31;
    opacity: 1;
  }
}
.search-input {
  float: left;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  label {
    font-weight: 500;
    color: $color_2;
    font-size: 16px;
    margin-bottom: 0;
  }
  input {
    height: 20px;
    min-height: 20px;
    border: 0;
    padding: 0;
    padding-right: 12px;
  }
}
.search-input.line {
  margin-right: 25px;
  width: 42%;
  input {
    height: 20px;
    min-height: 20px;
    border: 0;
    padding: 0;
    padding-right: 12px;
  }
  &::before {
    position: absolute;
    right: 0;
    top: 50%;
    width: 2px;
    height: 43px;
    background: #F2F2F2;
    content: "";
    z-index: 1;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
}
.search-group-icon {
  width: 43px;
  height: 43px;
  background: #F2F2FF;
  border-radius: 50%;
  font-size: 20px;
  color: $color_5;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}
.providerset {
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 18px;
  position: relative;
  top: 0;
  transition: all 0.5s;
  background: #fff;
  margin-bottom: 1.5rem;
  &:hover {
    box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  }
}
.providerset-img {
  img {
    width: 100%;
  }
  margin-bottom: 18px;
}
.providerset-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.offer-paths {
  background-color: $background-color_2;
  border-radius: 50px;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-top: -180px;
  padding: 0 104px;
  z-index: 1;
  &::after {
    position: absolute;
    content: "";
    background: url(../img/offer-bg.png) no-repeat 0 0 / 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.providers-section {
  background: url(../img/bg/provider-bg.png), url(../img/bg/pricing2.png);
  background-repeat: no-repeat;
  background-position: right center, left top;
}
.pricing-sections {
  background: url(../img/bg/pricing.png), url(../img/bg/pricing2.png);
  background-repeat: no-repeat;
  background-position: right center, left center;
}
.pricing-plans {
  border: 2px solid #F5F5F5;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #fff;
  &:hover {
    box-shadow: 0px 10px 20px -5px #4c40ed14;
  }
}
.pricing-plans.active {
  box-shadow: 0px 10px 20px -5px #4c40ed14;
}
.pricing-btn {
  text-align: center;
}
.offer-pathimg {
  margin-top: -120px;
}
.offer-path-content {
  h3 {
    font-size: 38px;
    font-weight: 600;
    color: $color_6;
    margin-bottom: 8px;
  }
  p {
    color: $color_6;
    margin-bottom: 20px;
    font-size: 18px;
  }
  padding-left: 48px;
}
.btn-views {
  background: #fff;
  color: $color_2;
  font-weight: 500;
  display: inline-block;
  border: 1px solid #fff;
  padding: 12px 20px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  display: inline-flex;
  align-items: center;
  &:hover {
    background-color: $background-color_1;
    border: 1px solid #4c40ed;
    box-shadow: inset 0 70px 0 0 #fff;
    color: $color_5;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  i {
    margin-left: 8px;
  }
}
.providerset-name {
  h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    a {
      margin-right: 6px;
    }
    i {
      color: $color_9;
    }
  }
  span {
    font-weight: 400;
  }
}
.providerset-prices {
  h6 {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    span {
      font-size: 14px;
      color: $color_1;
      margin-left: 3px;
      font-weight: 400;
    }
  }
}
.provider-rating {
  .rating {
    margin: 0;
    span {
      color: $color_1;
      margin-left: 5px;
    }
  }
}
.search-btn {
  float: right;
  .btn {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    padding: 11px 20px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
  }
}
.theme-text {
  color: $color_5;
}
.section-heading {
  margin-bottom: 50px;
  h2 {
    font-size: 40px;
    font-weight: 600;
    font-family: $font-family_4;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    color: $color_1;
    margin-bottom: 0;
  }
  .owl-nav {
    .owl-next {
      margin-bottom: 0;
      line-height: 0;
    }
    .owl-prev {
      margin-bottom: 0;
      line-height: 0;
    }
  }
}
.feature-section {
  background: url(../img/bg/feature-bg-01.png), url(../img/bg/feature-bg-02.png), #F7F7FF;
  background-repeat: no-repeat;
  background-position: left center, right center;
  padding: 90px 0 66px;
}
.feature-box {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  padding: 25px;
  text-align: center;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  position: relative;
  display: block;
  z-index: 1;
  overflow: hidden;
  h5 {
    margin-bottom: 0;
  }
  .feature-overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    width: 100%;
    img {
      border-radius: 10px;
    }
  }
  &:hover {
    .feature-overlay {
      opacity: 1;
      left: 0;
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 50%);
        border-radius: 10px;
      }
    }
    h5 {
      color: $color_6;
    }
  }
}
.feature-icon {
  span {
    z-index: 1;
    width: 94px;
    height: 94px;
    background: #F7F7FF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-radius: 50%;
    margin: 0 auto 20px;
  }
}
.btn-view {
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  img {
    margin-left: 8px;
  }
  i {
    margin-left: 8px;
  }
  &:hover {
    img {
      filter: invert(91%) sepia(125%) saturate(7510%) hue-rotate(258deg) brightness(112%) contrast(100%);
    }
  }
}
.service-section {
  padding: 90px 0;
  &.set-background {
    background: url(../img/bg/about-bg-01.webp);
    background-size: 71%;
  }
}
.service-widget {
  background-color: $background-color_1;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  margin-bottom: 24px;
  position: relative;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  &:hover {
    box-shadow: 0px 10px 20px -5px rgb(76 64 237 / 8%);
  }
  .serv-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    h6 {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 0;
      .old-price {
        font-size: 16px;
        color: $color_1;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }
  }
  .service-content {
    .title {
      a {
        &:hover {
          color: $color_5;
          color: $color_5;
        }
      }
    }
    p {
      i {
        margin-right: 7px;
        color: $color_32;
      }
    }
  }
  .btn-book {
    background: #F7F7FF;
    border-radius: 5px;
    color: $color_5;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 20px;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:hover {
      background: #fff;
      border: 1px solid #4c40ed;
      box-shadow: inset 0 70px 0 0 #fff;
      color: $color_5;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
  .fav-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px 20px 10px;
    z-index: 1;
  }
  .service-img {
    .item-info {
      .service-user {
        font-size: 20px;
        line-height: 28px;
        color: $color_6;
        font-weight: bold;
        float: left;
      }
    }
  }
  .service-user {
    img {
      width: 30px !important;
      height: 30px;
      border-radius: 50%;
      margin-right: 8px;
      display: inline-block !important;
    }
  }
}
.service-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px 10px 0 0;
  .serv-img {
    border-radius: 10px 10px 0 0;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    width: 100%;
  }
  .fixed-size {
    max-height: 16rem;
  }
  &:hover {
    .serv-img {
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
}
.service-content {
  padding: 20px;
  .title {
    font-size: 18px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    color: $color_1;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .rate {
      color: $color_1;
      float: right;
      i {
        font-size: 15px;
      }
      i.filled {
        color: $color_33 !important;
      }
    }
  }
  .item-info {
    position: relative;
    padding-left: 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 5px 10px;
      background-color: $background-color_53;
      margin: 0 13px 13px 0;
      font-size: 14px;
    }
  }
  .user-info {
    h6 {
      color: $color_1;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      overflow: hidden;
      align-items: center;
    }
    i {
      font-size: 12px;
      line-height: 26px;
      color: $color_158;
      text-align: center;
      width: 27px;
      height: 27px;
      border: 1px dashed #171717;
      border-radius: 50%;
      &:hover {
        background: #4c40ed;
        color: $color_6;
      }
    }
  }
}
.price {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1rem;
  .old-price {
    color: $color_1;
    text-decoration: line-through;
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
  }
}
.servicecontent {
  .btn-book {
    padding: 10px 20px;
  }
  .fav-icon {
    i {
      transition: all 0.3s;
    }
  }
  &:hover {
    .fav-icon {
      a {
        background: #F7F7FF;
      }
      i {
        transform: rotateY(360deg);
      }
    }
    .fav-item {
      .serv-rating {
        i {
          color: $color_5;
        }
      }
    }
  }
  .service-content {
    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      i {
        margin-right: 7px;
        color: $color_32;
      }
    }
  }
  .fav-item {
    .fav-icon {
      width: 32px;
      height: 32px;
    }
  }
  .service-widget {
    .btn-book {
      padding: 5px 15px;
    }
  }
}
.provide-social.social-icon {
  li {
    display: inline-block;
    margin-right: 7px;
    a {
      width: 36px;
      height: 36px;
      background: #F7F7FF;
      border-radius: 40px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      color: $color_1;
      &:hover {
        background-color: $background-color_2;
        color: $color_6;
      }
    }
  }
}
.item-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px 20px;
  z-index: 1;
}
.item-cat {
  background: #F7F7FF;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
  display: inline-block;
  color: $color_5;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  line-height: normal;
  &:hover {
    background: #4c40ed;
    color: $color_6;
  }
}
.item-img {
  display: inline-block;
  float: right;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #fff;
  }
}
.fav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 14px;
  .fav-icon {
    width: 43px;
    height: 43px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-radius: 50%;
    width: 31px;
    height: 31px;
    &:hover {
      background-color: $background-color_25;
      color: $color_6;
    }
  }
  .serv-rating {
    background: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    color: $color_2;
    display: inline-flex;
    align-items: center;
    i {
      color: $color_33;
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .item-info {
    position: unset;
    padding: 0;
    width: auto;
  }
}
.fav-icon.selected {
  background-color: $background-color_25;
  color: $color_6;
  background: #4c40ed;
}
.btn-sec {
  text-align: center;
  .btn {
    display: inline-flex;
    margin-top: 26px;
  }
}
.work-section {
  background: url(../img/bg/work-bg-01.png), url(../img/bg/work-bg-02.png), #F7F7FF;
  background-repeat: no-repeat;
  background-position: left center, right 0 bottom 25%;
  padding: 80px 0 66px;
  &.offer-container {
    margin-top: 366px;
  }
  .no-offer-path {
    padding-top: 80px;
  }
}
.work-box {
  background: #fff;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  margin-bottom: 24px;
  position: relative;
  min-height: 19.5rem;
  h5 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  p {
    color: $color_1;
    margin-bottom: 0;
  }
  h4 {
    font-weight: 600;
    font-size: 60px;
    color: $color_34;
    margin-bottom: 0;
    position: absolute;
    top: -30px;
    left: 0;
  }
}
.work-icon {
  span {
    width: 83px;
    height: 72px;
    background: #F7F7FF;
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin: 0 auto 36px;
  }
}
.rating {
  margin-bottom: 17px;
  i {
    color: $color_35;
    color: $color_157;
  }
  i.active {
    color: $color_33;
  }
  i.filled {
    color: $color_156;
  }
}
.client-section {
  padding: 90px 0 66px;
  background: url(../img/bg/testimonial-bg-01.png), url(../img/bg/testimonial-bg-02.png), #F9F9F9;
  background-repeat: no-repeat;
  background-position: left center, right bottom;
}
.client-widget {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  padding: 1.5rem;
  text-align: center;
  margin: 60px 0px 24px;
}
.client-img {
  margin: -83px 0 20px;
  img {
    width: 110px !important;
    height: 110px;
    border-radius: 50%;
    margin: 0 auto;
  }
}
.client-content {
  p {
    margin-bottom: 24px;
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  h6 {
    color: $color_1;
    font-weight: 400;
    margin-bottom: 0;
  }
}
.blog-section {
  padding: 90px 0 66px;
}
.blog {
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 20px;
  &:hover {
    box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
    .blog-image {
      img {
        -webkit-transform: scale(1.15);
        -moz-transform: scale(1.15);
        transform: scale(1.15);
      }
    }
  }
}
.blog-image {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  img {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  }
}
.blog-content {
  padding-top: 20px;
  .blog-item {
    display: inline-flex;
    display: -webkit-inline-flex;
    li {
      display: inline-flex;
      display: -webkit-inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: $color_1;
      margin: 0 25px 15px 0;
      margin-right: 25px;
      a {
        color: $color_26;
        &:hover {
          color: $color_5;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      i {
        margin-right: .5rem;
        color: $color_5;
      }
    }
  }
  .blog-title {
    font-size: 20px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
    a {
      color: $color_2;
      &:hover {
        color: $color_5;
      }
    }
  }
}
.app-section {
  padding: 70px 0 90px;
}
.partner-img {
  border: 2px solid #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: $background-color_1;
  img {
    width: auto !important;
    margin: 0 auto;
    height: 35px;
  }
  &:hover {
    box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  }
}
.app-sec {
  background: #4c40ed;
  border-radius: 50px;
  padding: 60px;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    border-radius: 10px;
    background-position: right top 10px;
    background-repeat: no-repeat;
    top: 10px;
    right: 20px;
    width: 100%;
    height: 100%;
  }
  h2 {
    font-size: 40px;
    font-family: $font-family_4;
    color: $color_6;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 15px;
    color: $color_6;
  }
  h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    color: $color_6;
  }
}
.downlaod-btn {
  display: flex;
  a {
    margin-right: 15px;
    display: inline-flex;
    &:last-child {
      margin-right: 0;
    }
  }
}
.appimg-set {
  margin-top: -128px;
  position: relative;
}
.scan-img {
  position: relative;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  display: inline-block;
}
.swal2-actions {
  .btn {
    margin: 0 5px;
    border-radius: 5px;
  }
}
.swal2-title {
  color: $color_2 !important;
  font-size: 22px !important;
}
#swal2-content {
  font-size: 14px;
  color: $color_26;
}
.about-banner {
  background: url(../img/bg/about-bg.png);
  margin-bottom: 47px;
}
.quality-img {
  width: 58px;
  height: 58px;
  background: #fff;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  img {
    width: 28px;
  }
}
.quality-info {
  h6 {
    font-weight: 500;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.about-sec {
  padding: 90px 0;
}
.about-img {
  position: relative;
  img {
    border-radius: 10px;
  }
  .about-exp {
    span {
      font-weight: 600;
      font-size: 25px;
      color: $color_6;
      display: inline-block;
      margin: 240px -112px;
      transform: rotate(-90deg);
    }
    position: absolute;
    background: #4c40ed;
    border-radius: 10px;
    width: 370px;
    height: 100%;
  }
}
.abt-img {
  padding: 30px 0 30px 60px;
  position: relative;
  z-index: 1;
}
.about-content {
  margin-left: 50px;
  h6 {
    font-size: 16px;
    color: $color_5;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  h2 {
    font-size: 38px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 15px;
      &:before {
        position: absolute;
        top: 4px;
        left: 0;
        content: '\f058';
        font-family: $font-family_5;
        color: $color_5;
      }
    }
  }
}
.bgimg4 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.work-bg.work-section {
  background: url(../img/bg/about-bg-02.png), #F7F7FF;
  background-repeat: no-repeat;
  background-position: right 0 bottom 25%;
}
.chooseus-sec {
  padding: 90px 0 70px;
}
.choose-content {
  h2 {
    font-size: 38px;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 40px;
  }
  margin-bottom: 50px;
  margin-right: 50px;
}
.choose-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: $background-color_27;
  border-radius: 10px;
  padding: 20px;
  img {
    margin-right: 20px;
  }
}
.choose-info {
  h5 {
    font-size: 32px;
    margin-bottom: 2px;
  }
  p {
    margin-bottom: 0;
  }
}
.chooseus-img {
  margin-bottom: 50px;
}
.support-card {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  p {
    margin: 15px 0 0;
  }
}
.support-title {
  background: #F4F7FF;
  border-radius: 5px;
  padding: 20px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  a {
    color: $color_2;
    display: block;
    position: relative;
    padding-right: 60px;
  }
  > a {
    &:after {
      font-family: $font-family_6;
      font-weight: 900;
      font-size: 15px;
      content: "\f078";
      position: absolute;
      top: 0;
      right: 0;
      color: $color_36;
    }
  }
}
.about-offer {
  padding: 0 67px;
  border-radius: 20px;
  margin: 0;
  align-items: flex-end;
  .offer-path-content {
    padding: 67px 42px 67px 0;
    h3 {
      margin-bottom: 25px;
    }
  }
  .offer-pathimg {
    margin-top: 0;
  }
  .btn-views {
    min-width: 138px;
    color: $color_5;
  }
}
.about-offer.offer-paths {
  &::after {
    content: none;
  }
}
.service-offer {
  position: relative;
  padding-bottom: 90px;
  &:before {
    position: absolute;
    content: "";
    background: #F9F9F9;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
  }
}
.service-wrap {
  margin-bottom: 30px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  h5 {
    font-weight: 600;
    font-size: 24px;
    font-family: $font-family_4;
    margin-bottom: 20px;
  }
  .owl-nav {
    .owl-next {
      margin-bottom: 20px;
    }
    .owl-prev {
      margin-bottom: 20px;
    }
  }
}
.provide-service.service-wrap {
  margin-bottom: 15px;
}
.serv-profile {
  margin: 0 0 22px;
  span.badge {
    font-weight: 400;
    font-size: 16px;
    background: #F7F7FF;
    color: $color_5;
    border-radius: 4px;
    margin-bottom: 15px;
    padding: 8px 10px;
    display: inline-block;
  }
  h2 {
    font-size: 36px;
    margin-bottom: 8px;
  }
  ul {
    li {
      font-size: 16px;
      display: inline-block;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .serv-pro {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .serv-review {
    margin-bottom: 0;
    span {
      font-size: 14px;
    }
  }
}
.serv-pro-info {
  h6 {
    font-weight: 500;
    margin-bottom: 6px;
  }
}
.service-map {
  i {
    color: $color_32;
  }
}
.serv-action {
  text-align: right;
  margin: 35px 0 22px;
  ul {
    li {
      margin-right: 12px;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      a {
        width: 47px;
        height: 47px;
        background: #fff;
        border: 2px solid #E6E8EC;
        color: $color_1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        border-radius: 50%;
        &:hover {
          background: #4c40ed;
          border-color: $border-color_1;
          color: $color_6;
          img {
            filter: brightness(0) invert(1);
          }
        }
        img {
          width: 15px;
        }
      }
    }
  }
}
.service-images {
  position: relative;
  margin-bottom: 10px;
  img {
    width: 100%;
    border-radius: 10px;
  }
}
.small-gallery {
  .circle-icon {
    display: none;
  }
  &:hover {
    .circle-icon {
      display: flex;
    }
  }
}
.circle-icon {
  width: 58px;
  height: 58px;
  color: $color_37;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.provide-box {
  margin-bottom: 1.5rem;
  display: flex;
  > span {
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7FF;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
  }
  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }
}
.provide-info {
  h6 {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 5px;
  }
  p {
    font-size: 15px;
    color: $color_1;
    word-break: break-word;
    margin-bottom: 0;
  }
  .serv-review {
    font-size: 14px;
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
    color: $color_5;
    margin-bottom: 0;
  }
}
.review-box {
  background: #fff;
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    color: $color_1;
    margin-bottom: 15px;
  }
  .card-body {
    span {
      color: $color_155;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.review-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  .rating {
    margin-bottom: 10px;
  }
}
.review-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.review-name {
  h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    color: $color_1;
    margin-bottom: 0;
  }
}
.btn-review {
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 27px;
}
.reply-area {
  margin: 15px 0 0;
  textarea {
    margin-bottom: 10px;
  }
  .btn {
    font-weight: 500;
    font-size: 14px;
    color: $color_2;
  }
}
.available-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.package-widget {
  background: #F7F7FF;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  ul {
    li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 24px;
      color: $color_1;
      display: flex;
      display: -webkit-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "\f058";
        font-weight: 900;
        font-family: $font-family_7;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        color: $color_5;
      }
    }
  }
}
.pack-service.package-widget {
  ul {
    li {
      padding-left: 0;
      &:before {
        content: none;
      }
    }
  }
}
.pack-service {
  .custom_check {
    .checkmark {
      border: 1px solid #C2C9D1;
      background: transparent;
    }
  }
}
.service-gal {
  margin-bottom: 40px;
}
.big-gallery {
  a.btn-show {
    background: #fff;
    border-radius: 8px;
    font-weight: 500;
    padding: 10px 15px;
    position: absolute;
    left: 20px;
    bottom: 20px;
    &:hover {
      background: #4c40ed;
      color: $color_6;
    }
  }
}
.card-provide {
  border: 2px solid #F5F5F5;
  .card-body {
    padding: 20px;
  }
  .btn {
    font-weight: 500;
    font-size: 16px;
    padding: 11px 9px;
    width: 100%;
  }
  .map-grid {
    margin-bottom: 20px;
  }
  .contact-map {
    height: 250px;
    border-radius: 10px;
  }
}
.provide-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}
.service-amount {
  h5 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 7px;
    span {
      font-size: 29px;
      color: $color_1;
      font-size: 20px;
      margin-left: 5px;
      text-decoration: line-through;
    }
  }
  .serv-review {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    span {
      font-size: 16px;
      font-weight: 600;
      color: $color_2;
    }
  }
  p {
    font-weight: 400;
    font-size: 17px;
    color: $color_1;
    span {
      font-weight: 600;
      font-size: 18px;
      color: $color_38;
    }
    i {
      color: $color_33;
    }
  }
}
.serv-review {
  font-weight: 400;
  font-size: 17px;
  color: $color_1;
  font-size: 12px;
  color: $color_1;
  span {
    color: $color_2;
    font-weight: 600;
    font-size: 14px;
  }
  i {
    color: $color_33;
  }
}
.serv-proimg {
  position: relative;
  img {
    width: 61px;
    height: 61px;
    border-radius: 50%;
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 15px;
    color: $color_39;
    background: #fff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
  }
}
.card-available {
  background: #F7F7FF;
  border-radius: 8px;
  border: 0;
  .card-body {
    padding: 15px;
  }
}
.available-info {
  flex: 1;
  h5 {
    font-size: 24px;
    font-family: $font-family_4;
    margin-bottom: 20px;
  }
  ul {
    li {
      color: $color_2;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        float: right;
        color: $color_1;
      }
      span.text-danger {
        color: $color_8 !important;
      }
    }
  }
  p {
    span {
      color: $color_40;
      font-size: 15px;
      font-weight: 400;
      margin-left: 40px;
    }
  }
}
.add-serving {
  width: calc(100% - 65px);
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-serv-item {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.add-serv-img {
  margin-right: 8px;
  img {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    flex-shrink: 0;
  }
}
.add-serv-info {
  overflow: hidden;
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 14px;
    color: $color_1;
    margin-bottom: 0;
    i {
      color: $color_32;
    }
  }
}
.add-serv-amt {
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
  }
}
#background-video {
  width: 100%;
  height: 300px;
  position: relative;
  background: url("../img/video-bg.jpg") top center no-repeat;
  border-radius: 5px;
  background-size: cover;
  iframe {
    width: 100%;
    height: 100%;
    display: none;
  }
}
.progress-wrap {
  position: fixed;
  right: 25px;
  bottom: 25px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(112, 201, 134, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  &::after {
    position: absolute;
    content: '\f062';
    font-family: $font-family_5;
    text-align: center;
    line-height: 35px;
    font-size: 17px;
    color: $color_5;
    left: 0;
    top: 0;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  svg {
    path {
      fill: none;
    }
  }
  svg.progress-circle {
    path {
      stroke: #4c40ed;
      stroke-width: 4;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.service-fav {
  .item-info {
    position: unset;
    padding: 0;
    width: auto;
  }
  .service-content {
    padding: 15px;
  }
  .btn {
    font-weight: 500;
    font-size: 13px;
    padding: 6px 15px;
    border-radius: 4px;
  }
}
.serv-user {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.serv-user-info {
  h5 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
#datetimepickershow {
  background: #fff;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 20px;
  .bootstrap-datetimepicker-widget {
    table {
      th.next {
        float: right;
        margin-right: 6px;
        margin-bottom: 15px;
      }
      th.prev {
        float: left;
        margin-left: 6px;
        margin-bottom: 15px;
      }
      td {
        width: 30px;
        height: 30px;
        margin: 0 auto;
      }
      td.active {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 50%;
      }
      td.day {
        font-size: 14px;
        line-height: 40px;
      }
    }
  }
}
.bootstrap-datetimepicker-widget {
  table {
    th {
      font-size: 14px;
    }
    td.day {
      font-size: 14px;
    }
    td.active {
      background-color: $background-color_2;
      text-shadow: unset;
      &:hover {
        background-color: $background-color_2;
        text-shadow: unset;
      }
    }
  }
}
.step-register {
  margin: 0 0 40px;
  li {
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-bottom: 3px solid rgba(20, 85, 141, 0.1);
    position: relative;
    margin-bottom: 15px;
    padding: 0 0 24px;
    opacity: 0.5;
    &:first-child {
      justify-content: start;
      -webkit-justify-content: start;
      -ms-flex-pack: start;
    }
    .multi-step-icon {
      width: 50px;
      height: 50px;
      background: rgba(76, 64, 237, 0.19);
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .multi-step-info {
      h6 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 7px;
      }
      p {
        color: $color_1;
        margin-bottom: 0;
        font-size: 15px;
      }
    }
  }
  li.active {
    &::before {
      content: "";
      border-bottom: 3px solid #4c40ed;
      border-radius: 5px;
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: -3px;
      left: 0;
    }
    opacity: 1;
    .multi-step-icon {
      background: #4c40ed;
    }
  }
  li.activate {
    opacity: 1;
    .multi-step-icon {
      background: #4c40ed;
    }
  }
}
.booking-service {
  margin-bottom: 25px;
}
.service-book {
  display: inline-flex;
  display: -webkit-inline-flex;
  width: 100%;
  .serv-profile {
    margin: 0 0 25px;
    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  a {
    width: 100%;
  }
}
.service-book-img {
  margin: 0 20px 25px 0;
  img {
    width: 175px;
    border-radius: 10px;
  }
}
.visits {
  width: 14.28%;
  padding: 0 8px;
  margin-right: -4px !important;
  input {
    &:checked {
      ~ {
        .visit-rsn {
          background: #4c40ed;
          border: 2px solid rgba(220, 220, 220, 0.3);
          color: $color_6;
          border-radius: 4px;
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &:disabled {
      ~ {
        .visit-rsn {
          background-color: $background-color_28;
          color: $color_6;
          border-radius: 5px;
        }
      }
    }
  }
  .visit-rsn {
    &:hover {
      background: #4c40ed;
      border: 2px solid rgba(220, 220, 220, 0.3);
      color: $color_6;
      border-radius: 4px;
    }
  }
  .form-check-input {
    opacity: 0;
  }
  input.form-check-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    margin-left: 0;
  }
  span.visit-rsn {
    text-align: center;
    background: #F7F7FF;
    border: 2px solid #F5F5F5;
    border-radius: 5px;
    color: $color_1;
    padding: 7px 5px;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    position: relative;
  }
}
.visit-btns {
  color: $color_24;
  background-color: $background-color_1;
  width: 100%;
  margin-bottom: 15px;
  display: block;
  outline: unset;
  cursor: pointer;
}
.book-submit {
  margin-top: 80px;
  .btn {
    font-weight: 500;
    padding: 10px 20px;
    + {
      .btn {
        margin-left: 24px;
      }
    }
  }
}
.book-title {
  h5 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 24px;
  }
}
.book-option {
  text-align: right;
  ul {
    li {
      display: inline-block;
      font-size: 14px;
      color: $color_26;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      .custom_check {
        padding-left: 30px;
        .checkmark {
          margin-right: 10px;
        }
      }
    }
  }
}
.book-select {
  .custom_check {
    .checkmark {
      border: 1px solid #DCDCDC;
      background: #4c40ed;
    }
  }
}
.book-form {
  margin-bottom: 30px;
  .select2-container--default {
    .select2-selection--single {
      border: 1px solid #C2C9D1;
      border-radius: 5px;
      height: 42px;
      .select2-selection__rendered {
        font-size: 14px;
        color: $color_1;
        line-height: 42px;
      }
      .select2-selection__arrow {
        height: 42px;
        b {
          border-color: $border-color_5;
        }
      }
    }
  }
  .col-form-label {
    color: $color_2;
    padding: 0 0 8px;
  }
}
.booking-done {
  h6 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  p {
    color: $color_1;
    margin-bottom: 30px;
  }
  .book-submit {
    margin-top: 0;
  }
}
.summary-box {
  background: #F7F7FF;
  border-radius: 8px;
  margin-bottom: 24px;
}
.booking-info {
  padding: 20px 20px 10px;
  border-bottom: 1px solid #F5F5F5;
  .service-book-img {
    img {
      width: 114px;
      height: 101px;
      object-fit: cover;
    }
    margin: 0 10px 10px 0;
  }
  .serv-profile {
    span.badge {
      margin-bottom: 5px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 15px;
    }
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      li {
        font-size: 14px;
        margin: 0 10px 10px 0;
      }
    }
    .serv-pro {
      img {
        width: 26px;
        height: 26px;
        margin-right: 0;
      }
    }
  }
  .service-book {
    .serv-profile {
      margin: 0;
    }
  }
}
.booking-date {
  border-bottom: 1px solid #F5F5F5;
  padding: 15px;
  li {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: $color_2;
    text-transform: capitalize;
    margin-bottom: 12px;
    span {
      float: right;
      text-align: right;
      font-size: 14px;
      // color: $color_1;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.booking-total {
  padding: 15px;
  ul {
    li {
      span {
        font-size: 16px;
        font-weight: 600;
        // color: $color_2;
      }
    }
  }
}
.payment-card {
  background: #fff;
  border: 1px solid #E5E7EB;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 24px;
}
.booking-info-tab {
  .payment-card {
    &:last-child {
      margin-bottom: 0;
    }
    .custom_radio {
      padding: 0;
    }
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E1E1E1;
    h6 {
      color: $color_25;
      font-size: 14px;
    }
  }
  border: 0;
  padding: 24px;
  margin-bottom: 0;
  .tab-content {
    .sub-title {
      h5 {
        margin-bottom: 20px;
      }
    }
    .form-group {
      margin-bottom: 10px;
    }
  }
  .payment-card.payment-bg {
    border-color: $border-color_1;
    background: #F7F7FF;
    h6 {
      color: $color_5;
    }
  }
  .sub-title {
    h5 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .add-more-card {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .total-price {
    h5 {
      padding: 10px;
      border-radius: 10px;
      background: rgba(109, 204, 118, 0.10);
      display: flex;
      justify-content: center;
      color: $color_149;
      font-weight: 400;
      font-size: 14px;
      margin: 20px 0 0;
    }
  }
}
.payment-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  label {
    display: inline-block;
  }
  span {
    font-size: 15px;
    text-align: right;
    margin-left: 10px;
  }
}
.payment-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .custom_radio {
    .checkmark {
      margin-top: -2px;
    }
  }
}
.payment-list {
  .form-control {
    line-height: 42px;
    min-height: 42px;
    padding: 0 15px;
    &::placeholder {
      color: $color_1;
    }
  }
  .col-form-label {
    font-weight: 500;
    color: $color_2;
  }
}
.pay-title {
  font-size: 20px;
  font-family: $font-family_4;
  margin-bottom: 24px;
}
.booking-pay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.booking-coupon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.coupon-icon {
  position: relative;
  margin-right: 24px;
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  }
  .form-control {
    padding: 5px 10px 5px 27px;
    min-height: 42px;
  }
}
.custom_check {
  display: inline-block;
  position: relative;
  font-size: 14px !important;
  margin-bottom: 15px;
  padding-left: 25px;
  cursor: pointer;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ {
        .checkmark {
          background-color: $background-color_2;
          border-color: $border-color_1;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #E4E4E8;
    background-color: $background-color_1;
    border-radius: 5px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &::after {
      content: "\e92b";
      font-family: $font-family_8 !important;
      font-weight: 900;
      position: absolute;
      display: none;
      left: 2px;
      top: 52%;
      transform: translateY(-50%);
      color: $color_6;
      font-size: 10px;
    }
  }
}
.custom_radio {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          &::after {
            background-color: $background-color_2;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            visibility: visible;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    margin: 1px 0 0 0;
    border: 1px solid #E0E0E0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    &::after {
      position: absolute;
      left: 4px;
      top: 4px;
      content: '';
      width: 9px;
      height: 9px;
      background-color: $background-color_1;
      border: 1px solid #E0E0E0;
      -webkit-transform: scale(0.1);
      -moz-transform: scale(0.1);
      -ms-transform: scale(0.1);
      -o-transform: scale(0.1);
      transform: scale(0.1);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    }
  }
}
.form-focus {
  position: relative;
  .col-form-label {
    position: absolute;
    top: -7px;
    left: 10px;
    background: #fff;
    font-weight: 500;
    font-size: 12px;
    color: $color_26;
    padding: 0 5px;
    z-index: 1;
  }
}
.content {
  padding: 50px 0;
}
.page-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.btn-back {
  border: 2px solid #E6E8EC;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 500;
  color: $color_41;
  &:hover {
    background: #E6E8EC;
    color: $color_41;
  }
}
.page-breadcrumb {
  ol {
    background-color: $background-color_19;
    font-size: 17px;
    margin-bottom: 0;
    padding: 0;
    li {
      a {
        &:hover {
          color: $color_5;
        }
      }
    }
    li.active {
      color: $color_42;
      font-weight: 600;
    }
  }
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
  &::before {
    content: "\f111";
    font-weight: 900;
    font-family: $font-family_7;
    padding: 9px 15px 0 15px;
    color: $color_5;
    font-size: 6px;
  }
}
.breadcrumb-bar {
  background-image: url('../img/bg/b.png');
  text-align: center;
  position: relative;
  padding: 53px 0;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    background: #F7F7FF;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .page-breadcrumb {
    ol {
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      li {
        a {
          font-size: 17px;
          font-weight: 400;
        }
        font-size: 17px;
        font-weight: 400;
      }
    }
  }
}
.filter-content {
  .rating {
    i {
      color: $color_33;
    }
    i.filled {
      color: $color_33;
      font-weight: 600;
    }
  }
  .select2-container--default {
    .select2-selection--single {
      height: 40px;
      .select2-selection__rendered {
        line-height: 40px;
        font-size: 14px;
        color: $color_1;
      }
      .select2-selection__arrow {
        height: 40px;
        color: $color_1;
      }
    }
  }
  padding: 20px;
  background: #F7F7FF;
  border: 2px solid #F5F5F5;
  border-radius: 5px;
  margin-bottom: 18px;
  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    span {
      float: right;
    }
  }
  .form-control {
    min-height: 46px;
    border: 1px solid #C2C9D1;
    color: $color_1;
  }
}
.page-title {
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: $color_2;
    margin: 0;
  }
  margin-bottom: 40px;
}
.filter-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  h5 {
    font-size: 24px;
    color: $color_2;
    font-weight: 500;
    margin: 0;
  }
}
a.reset-link {
  color: $color_5;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.rating-set {
  li {
    margin-bottom: 15px;
    &:last-child {
      .rating {
        margin: 0;
      }
      margin: 0;
    }
    .checkboxs {
      span {
        top: 2px;
        background-color: $background-color_1;
      }
    }
  }
}
.filter-checkbox {
  li {
    .checkboxs {
      span {
        background-color: $background-color_1;
      }
    }
    margin-bottom: 15px;
  }
  margin-top: 20px;
}
.filter-div {
  .filter-content {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    font-weight: 500;
    width: 100%;
    padding: 10px 20px;
  }
}
#fill-more {
  height: 180px;
  overflow: hidden;
}
.more-view {
  font-size: 13px;
  font-weight: 500;
  color: $color_5;
  display: inline-flex;
  align-items: center;
}
.group-img {
  position: relative;
  .form-control {
    padding: 6px 30px 6px 15px;
  }
  img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $color_2;
  }
  i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $color_2;
  }
}
.average-rating {
  font-size: 12px;
  color: $color_1;
}
.filter-range-amount {
  margin-top: 15px;
  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: $color_1;
  }
}
.select2-container--default {
  .select2-selection--single {
    .select2-selection__rendered {
      color: $color_44;
      line-height: 40px;
      padding-left: 15px;
      padding-right: 25px;
      font-size: 15px;
    }
    background-color: $background-color_1;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    height: 42px;
    .select2-selection__arrow {
      height: 42px;
      right: 6px;
      b {
        position: absolute;
        top: 50%;
        left: 50%;
        border-color: $border-color_6;
        border-style: solid;
        border-width: 0 2px 2px 0;
        padding: 3px;
        height: 0;
        margin-left: -10px;
        margin-top: -3px;
        width: 0;
        transform: rotate(45deg) translateY(-50%);
        -webkit-transform: rotate(45deg) translateY(-50%);
        -ms-transform: rotate(45deg) translateY(-50%);
      }
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $background-color_2;
    color: $color_6;
  }
}
.select2-container--default.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        border-color: $border-color_7;
        border-width: 2px 0 0 2px;
        margin-top: 3px;
        padding: 3px;
      }
    }
  }
}
.servicecontent.service-widget {
  .serv-info {
    h6 {
      font-size: 22px;
      font-weight: 600;
      margin: 0;
      b {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
.sorting-div {
  margin-bottom: 0;
  .grid-listview {
    a {
      width: 40px;
      height: 40px;
      &:hover {
        background: #4c40ed;
        border-color: $border-color_1;
        color: $color_6;
        > img {
          filter: brightness(0) invert(1);
        }
      }
    }
    a.active {
      background: #4c40ed;
      border-color: $border-color_1;
      color: $color_6;
    }
  }
}
.bg-img {
  position: relative;
}
img.bgimg1 {
  position: absolute;
  top: 0;
}
img.bgimg2 {
  position: absolute;
  top: 350px;
}
.bgimg3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.checkboxs {
  cursor: pointer;
  display: flex;
  min-height: 16px;
  align-items: center;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $color_45;
  font-weight: normal;
  margin-bottom: 0;
  input {
    display: none;
    &:checked {
      ~ {
        span {
          border-color: $border-color_1;
          background-color: $background-color_2;
          i {
            &:before {
              height: 50%;
              transition: height 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
            }
            &:after {
              width: 100%;
              transition: width 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
            }
          }
        }
        b {
          color: $color_46;
        }
      }
    }
  }
  span {
    position: relative;
    display: flex;
    width: 16px;
    height: 16px;
    border: 1px solid #C2C9D1;
    border-radius: 2px;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: 8px;
    background: #F7F7FF;
    i {
      position: relative;
      width: 15px;
      height: 15px;
      margin-top: 0px;
      margin-left: 2px;
      transform: rotate(-45deg) translateX(0%) translateY(0%) scale(0.7);
      transform-origin: center left;
      &:before {
        content: "";
        position: absolute;
        background-color: $background-color_32;
        border-radius: 20px;
        height: 0%;
        top: 50%;
        left: 0;
        width: 3.5px;
      }
      &:after {
        content: "";
        position: absolute;
        background-color: $background-color_32;
        border-radius: 20px;
        width: 0%;
        bottom: 0;
        left: 0;
        height: 3px;
      }
    }
  }
  .check-content {
    color: $color_2;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    padding-top: 0;
  }
}
.count-search {
  h6 {
    font-size: 14px;
    font-weight: 500;
    color: $color_2;
    margin: 4px 0 0;
  }
}
.sortbyset {
  display: flex;
  align-items: center;
  margin: 0 10px 20px 0;
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin-right: 6px;
    margin-bottom: 0;
  }
}
.sorting-select {
  min-width: 178px;
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        color: $color_1;
        line-height: 38px;
        font-weight: 500;
      }
      height: 40px;
      font-size: 14px;
      .select2-selection__arrow {
        height: 38px;
      }
    }
  }
}
.grid-listview {
  margin-bottom: 20px;
  ul {
    display: flex;
    align-items: center;
  }
  a {
    width: 40px;
    height: 40px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EDECF8;
    color: $color_1;
    border-radius: 7px;
    &:hover {
      > img {
        filter: brightness(0) saturate(100%) invert(34%) sepia(84%) saturate(5841%) hue-rotate(240deg) brightness(92%) contrast(102%);
      }
      background: #F7F7FF;
      color: $color_5;
    }
  }
  li {
    + {
      li {
        margin-left: 8px;
      }
    }
  }
  a.active {
    background: #F7F7FF;
    color: $color_5;
  }
}
.fav-icon.active {
  background: #4c40ed;
  color: $color_6;
}
.modal {
  z-index: 1052;
}
.error-page {
  .bgimg3 {
    width: 40%;
  }
  .content {
    padding: 30px 0;
  }
  .error-wrap {
    text-align: center;
  }
}
.error-wrap {
  .error-logo {
    margin-bottom: 30px;
    img {
      max-height: 35px;
    }
  }
  h2 {
    font-size: 38px;
    margin-bottom: 10px;
  }
  .error-img {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 30px;
  }
  .btn {
    font-weight: 500;
    padding: 10px 25px;
    display: inline-flex;
    display: -webkit-inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.contact-details {
  margin-bottom: 66px;
}
.contact-img {
  position: relative;
  margin-right: 25px;
  img {
    border-radius: 10px;
  }
  &:before {
    position: absolute;
    content: "";
    background: url("../img/bg/contact-bg.png") no-repeat;
    left: -15px;
    top: -15px;
    width: 113px;
    height: 113px;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: "";
    background: url("../img/bg/contact-bg.png") no-repeat;
    right: -6px;
    bottom: 120px;
    width: 113px;
    height: 113px;
    z-index: -1;
  }
}
.contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 24px;
  &:hover {
    background: #fff;
    box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  }
  span {
    font-size: 30px;
    color: $color_5;
    width: 80px;
    height: 80px;
    background: #F7F7FF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    flex-shrink: 0;
    border-radius: 50%;
  }
}
.contact-data {
  margin-left: 15px;
  h4 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
  }
  p {
    color: $color_1;
    margin-bottom: 0;
    a {
      color: $color_26;
    }
  }
}
.contact-map {
  border: 0;
  display: block;
  width: 100%;
  height: 450px;
  margin: 0;
}
.col-form-label {
  color: $color_2;
}
.form-icon {
  position: relative;
  .cus-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    height: auto;
  }
  .form-control {
    padding-right: 30px;
  }
}
.form-icon.form-msg {
  span {
    top: 20px;
  }
}
.contact-queries {
  h2 {
    font-size: 38px;
    margin-bottom: 40px;
    font-family: $font-family_4;
  }
  .btn {
    font-weight: 500;
    padding: 12px 22px;
    margin-top: 22px;
    display: inline-flex;
    display: -webkit-inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-group {
    margin-bottom: 18px;
  }
  .form-control {
    min-height: 46px;
    padding: 9px 30px 8px 15px;
  }
}
.login-back {
  margin-bottom: 40px;
  a {
    font-size: 14px;
    color: $color_1;
    &:hover {
      color: $color_5;
    }
  }
}
.login-back.manage-log {
  margin-bottom: 36px;
}
.login-header {
  margin-bottom: 30px;
  h3 {
    font-family: $font-family_4;
    font-size: 32px;
    margin-bottom: 13px;
  }
  p {
    font-size: 14px;
    margin-bottom: 8px;
    span {
      color: $color_2;
      font-weight: 500;
    }
  }
  h6 {
    font-size: 14px;
    font-weight: 400;
    color: $color_2;
    margin-bottom: 10px;
    a {
      color: $color_5;
      &:hover {
        color: $color_4;
      }
    }
  }
}
.login-wrap {
  .col-form-label {
    font-size: 16px;
    color: $color_2;
    padding: 0;
    margin-bottom: 8px;
  }
  .form-control {
    border-radius: 5px;
    border-color: $border-color_8;
    &::placeholder {
      color: $color_47;
      font-size: 14px;
    }
  }
  .custom_check {
    font-size: 14px;
    .checkmark {
      border-radius: 2px;
    }
  }
  .login-btn {
    font-weight: 500;
    border-radius: 5px;
    padding: 12px 10px;
    margin-bottom: 20px;
  }
  max-width: 420px;
  margin: 0 auto;
}
.char-length {
  p {
    font-size: 13px;
    margin-bottom: 20px;
  }
}
.forgot-link {
  font-size: 13px;
  font-weight: 500;
  color: $color_5;
  text-decoration: underline;
  display: inline-block;
}
.login-or {
  color: $color_48;
  margin-bottom: 30px;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
.or-line {
  background-color: $background-color_33;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}
.span-or {
  font-size: 13px;
  background-color: $background-color_1;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
  top: 1px;
  padding: 0 10px;
}
.btn-google {
  background: #F7F7FF;
  border-radius: 5px;
  color: $color_2;
  font-weight: 500;
  font-size: 14px;
  padding: 9px;
  &:hover {
    border-color: $border-color_1;
    color: $color_5;
    background: #fff;
  }
}
.social-login {
  .btn {
    font-weight: 500;
    padding: 10px 20px;
    + {
      .btn {
        margin-top: 10px;
      }
    }
  }
}
.no-acc {
  font-size: 14px;
  color: $color_1;
  text-align: center;
  margin: 30px 0 0 0;
  a {
    font-size: 14px;
    font-weight: 500;
    color: $color_5;
    &:hover {
      color: $color_4;
    }
  }
}
.passcode-wrap {
  input {
    background: #F6F6F6;
    box-shadow: inset 0px 0px 9px rgba(76, 64, 237, 0.12);
    width: 74px;
    height: 74px;
    border: 0;
    border-radius: 50%;
    text-align: center;
    color: $color_2;
    font-size: 32px;
    font-weight: 500;
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.time-expiry {
  font-size: 13px;
  background: #F7F7FF;
  border-radius: 5px;
  padding: 3px 8px;
  color: $color_5;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 50px;
}
.log-form {
  padding-bottom: 26px;
}
.otp-img {
  margin-bottom: 18px;
}
.otp-wrap {
  .no-acc {
    margin: 0 0 20px;
  }
}
.pass-group {
  position: relative;
}
.toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: $color_49;
  cursor: pointer;
}
.feather-eye-off.feather-eye {
  &::before {
    content: "\e95c" !important;
  }
}
.password-strength {
  display: flex;
  margin-top: 18px;
  span {
    width: 100%;
    height: 5px;
    background: #E4E6EF;
    margin-right: 10px;
    border-radius: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}
#passwordInfo {
  font-size: 13px;
  margin-top: 10px;
  color: $color_50;
}
.choose-signup {
  background: #fff;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  h6 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    margin-bottom: 17px;
  }
  .btn {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #4c40ed;
      color: $color_6;
      box-shadow: inset 0 0 0 0 #fff;
    }
  }
  &:hover {
    border: 2px solid #4c40ed;
    .btn {
      border-color: $border-color_1;
      background: #4c40ed;
      color: $color_6;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.choose-img {
  margin-bottom: 16px;
  img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
  }
}
.poor-active {
  span.active#poor {
    background: #FF0000;
  }
  span.active#poors {
    background: #FF0000;
  }
}
.avg-active {
  span.active#poor {
    background: #FFB54A;
  }
  span.active#weak {
    background: #FFB54A;
  }
  span.active#poors {
    background: #FFB54A;
  }
  span.active#weaks {
    background: #FFB54A;
  }
}
.strong-active {
  span.active#poor {
    background: #1D9CFD;
  }
  span.active#weak {
    background: #1D9CFD;
  }
  span.active#strong {
    background: #1D9CFD;
  }
  span.active#poors {
    background: #1D9CFD;
  }
  span.active#weaks {
    background: #1D9CFD;
  }
  span.active#strongs {
    background: #1D9CFD;
  }
}
.heavy-active {
  span.active#poor {
    background: #159F46;
  }
  span.active#weak {
    background: #159F46;
  }
  span.active#strong {
    background: #159F46;
  }
  span.active#heavy {
    background: #159F46;
  }
  span.active#poors {
    background: #159F46;
  }
  span.active#weaks {
    background: #159F46;
  }
  span.active#strongs {
    background: #159F46;
  }
  span.active#heavys {
    background: #159F46;
  }
}
input[type=password] {
  &::-ms-reveal {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
}
.domain-grp {
  border: 1px solid #C2C9D1;
  border-radius: 5px;
  .form-control {
    border: 0;
  }
  .input-group-text {
    background: #fff;
    border-color: $border-color_9;
    color: $color_5;
    font-size: 14px;
    font-weight: 500;
  }
}
.settings-header {
  padding: 24px;
  text-align: center;
  border-bottom: 1px solid #F5F5F5;
  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 2px solid #fff;
    outline: 4px solid rgba(0, 0, 0, 0.07);
  }
  h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 3px;
  }
  p {
    font-size: 14px;
    color: $color_26;
    margin-bottom: 0;
  }
}
.settings-img {
  margin-bottom: 12px;
}
.settings-widget {
  background: #F7F7FF;
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  border: 1px solid #edecf8;
  .settings-menu {
    ul {
      li {
        a {
          display: flex;
          align-items: center;
          color: $color_25;
          font-weight: 400;
          font-size: 16px;
          &:hover {
            color: $color_5;
          }
        }
        a.active {
          color: $color_5;
        }
      }
    }
  }
}
.settings-menu {
  padding: 24px;
  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 20px;
        margin-bottom: 24px;
      }
      a {
        color: $color_1;
        font-weight: 500;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &:hover {
          color: $color_5;
          img {
            filter: invert(29%) sepia(76%) saturate(7498%) hue-rotate(246deg) brightness(99%) contrast(88%);
          }
        }
        img {
          margin-right: 10px;
          font-size: 20px;
          min-width: 25px;
          margin-right: 8px;
          min-width: 16px;
        }
      }
      a.active {
        color: $color_5;
        img {
          filter: invert(29%) sepia(76%) saturate(7498%) hue-rotate(246deg) brightness(99%) contrast(88%);
        }
      }
      i {
        margin-right: 10px;
        font-size: 20px;
        min-width: 25px;
      }
    }
    li.active {
      a {
        color: $color_5;
      }
    }
  }
}
.pro-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}
.pro-img {
  margin-right: 13px;
  img {
    width: 61px;
    height: 61px;
    border-radius: 50%;
  }
}
.widget-title {
  h4 {
    font-weight: 600;
    font-size: 24px;
    font-family: $font-family_4;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
    color: $color_151;
  }
}
.user-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.img-upload {
  background: #4c40ed;
  border-radius: 4px;
  color: $color_6;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  display: inline-flex;
  display: -webkit-inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &:hover {
    background: #2229C1;
    color: $color_6;
  }
  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.pro-info {
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  .img-upload {
    margin: 0 15px 10px 0;
  }
  .btn-remove {
    background: #FFF5F5;
    border: 1px solid #F82424;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 13px;
    font-weight: 500;
    color: $color_8;
    margin-bottom: 10px;
    &:hover {
      background-color: $background-color_34;
      color: $color_6;
    }
  }
}
.form-info {
  .col-form-label {
    font-weight: 500;
  }
  .form-control {
    background: #F6F6F6;
    border-color: $border-color_10;
    min-height: 46px;
    &::placeholder {
      color: $color_47;
    }
  }
  .select2-container--default {
    .select2-selection--single {
      background: #F6F6F6;
      border-color: $border-color_10;
    }
  }
}
.brief-bio {
  font-size: 14px;
  font-weight: 400;
  color: $color_1;
}
.linked-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.linked-acc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  .link-icon {
    width: 46px;
    height: 46px;
    background: #F9F9FB;
    border: 1px solid #F3F3F3;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    flex-shrink: 0;
  }
}
.linked-info {
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    span {
      color: $color_2;
    }
  }
}
.linked-action {
  margin-left: auto;
}
.btn-acc {
  background: #FFF5F5;
  border-radius: 4px;
  color: $color_8;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 24px;
  &:hover {
    background: #FF4949;
    color: $color_6;
  }
}
.acc-submit {
  text-align: right;
  .btn {
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 5px;
    + {
      .btn {
        margin-left: 24px;
      }
    }
  }
}
.setting-submenu {
  padding: 5px 0 0 35px;
  li {
    font-size: 13px;
    margin-bottom: 12px !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
.btn-set {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 19px;
  min-width: 99px;
  text-align: center;
  border-radius: 4px;
}
.link-check {
  color: $color_9;
  margin-right: 30px !important;
}
.link-close {
  color: $color_8;
  margin-right: 30px !important;
  .fa-circle-check {
    color: $color_160;
  }
}
.linked-item {
  a {
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    .linked-acc {
      margin-bottom: 0;
    }
    .btn-set {
      margin-bottom: 0;
    }
  }
  a.btn-gconnect {
    background: #F3FFF6;
    border-radius: 4px;
    padding: 7px 17px;
    font-weight: 500;
    font-size: 13px;
    color: $color_9;
    margin-right: 20px;
  }
  .linked-acc {
    margin-bottom: 0px;
  }
  a.link-close {
    margin-right: 32px !important;
  }
  background: #FFF;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 24px;
  margin-bottom: 20px;
}
.custom-table {
  .badge-success {
    min-width: 79px;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 8px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
  }
  .badge-danger {
    min-width: 79px;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 8px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
  }
  .badge-warning {
    min-width: 79px;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 8px;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
  }
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  thead {
    th {
      &:first-child {
        border-radius: 10px 0 0 0;
      }
      color: $color_2;
      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
}
.badge-success {
  background: #F3FFF6;
  border-radius: 6px;
  color: $color_9;
  padding: 6px 15px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.sml-status {
  .checktoggle {
    width: 42px;
    height: 24px;
    &:after {
      width: 20px;
      height: 20px;
      transform: translate(2px, -50%);
    }
  }
  .check {
    &:checked {
      + {
        .checktoggle {
          background-color: $background-color_35;
          border: 1px solid #6DCC76;
          &:after {
            transform: translate(calc(-100% - 2px), -50%);
          }
        }
      }
    }
  }
}
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  &:checked {
    + {
      .checktoggle {
        background-color: $background-color_37;
        border: 1px solid #00CC45;
        &:after {
          background-color: $background-color_1;
          left: 100%;
          transform: translate(calc(-100% - 3px), -50%);
        }
      }
    }
  }
}
.checktoggle {
  background-color: $background-color_36;
  border: 1px solid #CCCFE0;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 22px;
  margin-bottom: 0;
  position: relative;
  width: 41px;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(3px, -50%);
    width: 16px;
    height: 16px;
    background-color: $background-color_1;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
  }
}
.blue-tog {
  .check {
    &:checked {
      + {
        .checktoggle {
          background: #2229C1;
          border: 1px solid #2229C1;
        }
      }
    }
  }
}
.status-tog {
  .checktoggle {
    height: 24px;
    width: 42px;
    border-radius: 999px;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      background-image: url(../img/icons/check.svg);
      background-repeat: no-repeat;
      background-size: 11px 20px;
      width: 20px;
      height: 20px;
      transform: translate(3px, -50%);
    }
    &:after {
      width: 20px;
      height: 20px;
      background-image: url(../img/icons/x-icon.svg);
      background-repeat: no-repeat;
      background-size: 12px 21px;
      transform: translate(1px, -50%);
      background-position: center;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06);
    }
  }
  .check {
    &:checked {
      + {
        .checktoggle {
          &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            background-image: url(../img/icons/x-icon.svg);
            background-repeat: no-repeat;
            background-size: 13px 21px;
            width: 20px;
            height: 20px;
            filter: brightness(0) invert(1);
          }
          &:after {
            background-image: url(../img/icons/check.svg);
            background-repeat: no-repeat;
            background-size: 12px 21px;
            transform: translate(calc(-100% - 2px), -50%);
            background-position: center;
          }
        }
      }
    }
  }
}
.notification-table {
  .table-thead {
    background: #EDF3F9;
  }
  .table {
    border: 1px solid #F1F1F1;
    margin-bottom: 40px;
    thead {
      th {
        &:first-child {
          border-radius: 5px 0 0px 0px;
        }
        &:last-child {
          border-radius: 0 5px 0px 0px;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
.notify-item {
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    color: $color_1;
    margin-bottom: 0;
  }
}
.provide-table.manage-table {
  margin-bottom: 30px;
}
.write-review {
  p {
    color: $color_1;
    margin-bottom: 20px;
  }
}
.dash-widget {
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.dash-icon {
  width: 35px;
  height: 35px;
  background: #FFF2E5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 8px;
}
.dash-icon.bg-blue {
  background: #F4F7FF;
}
.dash-info {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  h6 {
    font-size: 14px;
    font-weight: 400;
    color: $color_26;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 24px;
    margin-bottom: 0;
    text-align: right;
  }
}
.bg-light-primary {
  background: #D8E6FF;
}
.bg-light-success {
  background: #F3FFF6;
}
.bg-light-danger {
  background: #FFF5F5;
}
.recent-booking {
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  padding: 0 15px;
  tr {
    td {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    &:last-child {
      border: 0;
      td {
        border: 0;
      }
    }
  }
}
.btn-light-danger {
  background: #FFF5F5;
  border-radius: 4px;
  color: $color_8;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 12px;
  &:hover {
    background: #fcd9d9;
    color: $color_8;
  }
}
.table-user {
  .avatar {
    width: 40px;
    height: 40px;
    > img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
.book-img {
  width: 42px;
  height: 42px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  background: #F7F7FF;
  border-radius: 50%;
  margin-right: 10px;
}
.table-book {
  h6 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }
  p {
    font-size: 13px;
    margin-bottom: 0;
    i {
      color: $color_32;
      margin-right: 5px;
    }
    span {
      margin-left: 10px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}
.trans-amt {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
.thead-light {
  background: #EDF3F9;
}
.dash-wallet {
  .dash-icon {
    width: 45px;
    height: 45px;
    background: #F6F6F6;
    margin: 0 12px 0 0;
    img {
      width: 20px;
    }
  }
  .dash-info {
    h6 {
      color: $color_1;
      font-size: 14px;
      margin-bottom: 5px;
    }
    h5 {
      font-weight: 600;
      font-size: 18px;
      text-align: left;
    }
  }
}
.btn-wallet {
  padding: 6px 15px;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 30px;
}
.bank-selection {
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
    ~ {
      label {
        .role-check {
          display: none;
        }
      }
    }
    &:checked {
      ~ {
        label {
          .role-check {
            display: block;
          }
        }
      }
    }
  }
  label {
    background: #F4F7FF;
    border: 2px solid #F5F5F5;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    width: 100%;
    position: relative;
    height: 42px;
    margin-bottom: 30px;
  }
  position: relative;
  .role-check {
    position: absolute;
    top: -8px;
    right: -8px;
    color: $color_51;
  }
}
#add-payout {
  .bank-selection {
    label {
      margin-bottom: 20px;
    }
  }
}
.day-cont {
  .form-group {
    margin-bottom: 8px;
  }
}
.review-list {
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  //&:last-child {
  //	border-bottom: 0;
  //}
  p {
    color: $color_1;
    margin: 0;
  }
}
.review-info {
  margin-bottom: 12px;
  h5 {
    margin-bottom: 10px;
    span {
      font-weight: 500;
      color: $color_2;
    }
  }
  a {
    &:hover {
      color: $color_5;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
}
.review-count {
  flex: 0 0 auto;
  margin-left: auto;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  .rating {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.review-user {
  color: $color_2;
  .avatar {
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }
}
.review-date {
  font-size: 14px;
  color: $color_1;
}
.review-sort {
  margin-bottom: 20px;
  p {
    font-weight: 500;
    color: $color_2;
    padding-right: 6px;
    display: inline-block;
    margin-bottom: 0;
  }
  .select2-container {
    width: auto !important;
    min-width: 125px;
    color: $color_1;
    font-size: 15px;
    text-align: left;
  }
  .select2-container--default {
    .select2-selection--single {
      border: 1px solid #EDECF8;
      border-radius: 5px;
      height: 40px;
      .select2-selection__rendered {
        line-height: 39px;
        color: $color_1;
      }
      .select2-selection__arrow {
        height: 40px;
      }
    }
  }
}
.review-entries {
  span {
    font-weight: 500;
    font-size: 14px;
  }
  select {
    margin: 0 10px 0;
    border: 2px solid #F5F5F5;
    border-radius: 5px;
    padding: 5px 30px 5px 10px;
    color: $color_2;
    font-weight: 500;
    font-size: 14px;
  }
}
.review-pagination {
  text-align: right;
  p {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    float: none;
    li {
      margin-left: 10px;
      a {
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        background: #F4F7FF;
        border: 0;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        color: $color_1;
      }
    }
    li.active {
      a {
        background-color: $background-color_2 !important;
        border-color: $border-color_1 !important;
        color: $color_6;
      }
    }
  }
}
.page-item {
  .page-link {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    background: #F4F7FF;
    border: 0;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: $color_1;
    &:hover {
      background-color: $background-color_2 !important;
      border-color: $border-color_1 !important;
      color: $color_6;
    }
  }
}
.page-item.active {
  .page-link {
    background-color: $background-color_2 !important;
    border-color: $border-color_1 !important;
    color: $color_6;
  }
}
.badge-danger {
  background: #FFF5F5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  color: $color_8;
  padding: 6px 20px;
  display: inline-block;
}
.customer-chat {
  .chat-cont-left {
    .chat-scroll {
      max-height: calc(100vh - 330px);
    }
  }
  .chat-scroll {
    max-height: calc(100vh - 330px);
  }
  .chat-cont-profile {
    .card-body {
      max-height: calc(100vh - 330px);
    }
  }
}
.booking-list {
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  .booking-widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    text-align: left;
    // flex: 0 0 calc(100% - 235px);
    overflow: hidden;
    .booking-img {
      img {
        border-radius: 8px;
        height: 150px;
        object-fit: cover;
        width: 150px;
      }
    }
  }
  .booking-img {
    position: relative;
    margin-right: 15px;
    flex: 0 0 160px;
    max-width: 160px;
    .fav-item {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
.booking-widget {
  .fav-item {
    .fav-icon {
      &:hover {
        background: #4c40ed;
      }
    }
  }
}
.booking-det-info {
  h3 {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    margin-bottom: 15px;
    a {
      margin-right: 5px;
    }
    .badge {
      padding: 9px 16px;
      font-weight: 500;
      font-size: 13px;
      display: inline-block;
      border-radius: 4px;
    }
  }
}
.booking-details {
  li {
    font-size: 14px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    span.book-item {
      font-weight: 500;
      font-size: 14px;
      color: $color_2;
      // width: 100px;
      // width: 5.7rem;
      display: inline-block;
      > i {
        display: inline-block;
        width: 18px;
      }
    }
    span.badge-grey {
      background: #F7F7FF;
      border-radius: 5px;
      color: $color_5;
      padding: 2px 8px;
      display: inline-block;
    }
    span.badge {
      color: $color_6;
      width: auto;
      flex: 0 0 auto;
      margin-left: 5px;
      font-weight: normal;
      padding: 4px 9px;
      height: 20px;
    }
    p {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      margin: 0 10px 0 0;
      &:before {
        position: absolute;
        top: 4px;
        left: 0;
        content: '\f111';
        font-family: $font-family_5;
        font-size: 8px;
        color: $color_52;
      }
    }
    span.badge-grey.badge-new {
      background: #E8E8E8;
      color: $color_116;
      margin-left: 7px;
    }
  }
}
.booking-action {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex: 0 0 235px;
  justify-content: flex-end;
  flex-wrap: wrap;
  a {
    font-size: 14px;
    font-weight: 500;
    padding: 6px 15px;
    margin: 3px 0;
    border-radius: 5px;
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
    + {
      a {
        margin-left: 10px;
      }
    }
    i {
      margin-right: 5px;
    }
  }
  .rating {
    margin-bottom: 10px;
  }
  .view-btn {
    color: $color_5;
    font-weight: 500;
    margin: 0;
    padding: 0 15px;
    &:hover {
      color: $color_5;
    }
  }
}
.user-book {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
  .avatar {
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }
}
.badge-warning {
  background: #FFFDEF;
  border-radius: 5px;
  color: $color_55 !important;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.badge-info {
  background: #F4F7FF;
  color: $color_56;
}
.view-action {
  margin: 25px auto 0;
}
.review-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.rev-img {
  margin-right: 10px;
  img {
    width: 55px;
    height: 55px;
    border-radius: 8px;
  }
}
.rev-info {
  h6 {
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.modal-submit {
  .btn {
    padding: 10px 20px;
    font-weight: 500;
    border-radius: 5px;
  }
}
.faq-card {
  background: #F7F7FF;
  border: 1px solid #EDECF8;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .card-collapse {
    p {
      margin: 10px 0 0;
    }
  }
}
.faq-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  a {
    color: $color_2;
    display: block;
    position: relative;
    padding-right: 60px;
  }
  > a {
    &:after {
      font-family: $font-family_6;
      font-weight: 900;
      content: "\f055";
      position: absolute;
      top: 0;
      right: 0;
      color: $color_36;
    }
    &:not(.collapsed) {
      color: $color_5;
      &:after {
        content: "\f056";
        color: $color_5;
      }
    }
  }
}
.faq-sec {
  text-align: center;
  h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.price-card {
  background: #fff;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 20px;
  &:hover {
    background: #4c40ed;
    .price-head {
      h6 {
        color: $color_6;
      }
      h1 {
        color: $color_6;
        span {
          color: $color_6;
        }
      }
    }
    .price-body {
      ul {
        li {
          color: $color_6;
        }
      }
    }
  }
}
.price-head {
  border-bottom: 1px solid #EDECF8;
  padding-bottom: 15px;
  h6 {
    font-weight: 400;
    color: $color_1;
    margin-bottom: 5px;
  }
  h1 {
    font-size: 38px;
    margin-bottom: 0;
    span {
      font-size: 16px;
      color: $color_1;
      font-weight: normal;
    }
  }
}
.price-level {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  .badge-success {
    background: #6DCC76;
    font-weight: 400;
    font-size: 13px;
    color: $color_6;
    padding: 3px 8px;
    i {
      font-size: 8px;
    }
  }
}
.price-body {
  padding-top: 15px;
  ul {
    margin-bottom: 30px;
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        position: absolute;
        content: "";
        top: 5px;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
      }
    }
    li.active {
      &:before {
        background-image: url("../img/icons/check-icon.svg");
      }
    }
    li.inactive {
      &:before {
        background-image: url("../img/icons/close-icon.svg");
      }
    }
  }
  .btn-choose {
    img {
      filter: brightness(0) saturate(100%) invert(24%) sepia(85%) saturate(5324%) hue-rotate(243deg) brightness(96%) contrast(93%);
    }
    background: #EDF3F9;
    border-radius: 8px;
    font-weight: 500;
    color: $color_5;
    padding: 10px 20px;
    margin: 0 auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    i {
      margin-left: 5px;
    }
  }
}
.price-toggle {
  font-weight: 500;
  font-size: 14px;
  color: $color_2;
  text-align: center;
  margin-bottom: 40px;
  .checktoggle {
    margin: 0 8px;
    background: #4c40ed;
    border-color: $border-color_1;
  }
  .check {
    &:checked {
      + {
        .checktoggle {
          background-color: $background-color_2;
          border: 1px solid #4c40ed;
        }
      }
    }
  }
}
.category-card {
  background: #fff;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  margin-bottom: 24px;
}
.category-img {
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  }
  &:hover {
    img {
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
}
.category-info {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  p {
    font-size: 16px;
    color: $color_1;
    margin-bottom: 0;
  }
}
.category-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h6 {
    font-size: 20px;
    margin-bottom: 0;
    a {
      color: $color_2;
      &:hover {
        color: $color_5;
      }
    }
  }
}
.category-icon {
  width: 45px;
  height: 45px;
  background: #F7F7FF;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
  img {
    max-height: 48px;
  }
}
.maintenance-wrap {
  h2 {
    font-size: 38px;
    margin-bottom: 8px;
  }
  p {
    color: $color_1;
    margin-bottom: 30px;
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin: 0 10px 15px 0;
  }
  .social-icon {
    ul {
      margin: 0 0 15px;
    }
  }
  .btn {
    font-weight: 700;
    padding: 10px 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.social-icon.media-icon {
  ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
  }
}
.media-icon.social-icon {
  ul {
    li {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $color_1;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        font-size: 18px;
        transition: all 0.4s ease 0s;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: $background-color_38;
        &:hover {
          background-color: $background-color_2;
          color: $color_6;
        }
      }
    }
  }
}
.maintenance-sec {
  padding: 80px 0;
}
.maintenance-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.countdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .countdown-el {
    margin-right: 20px;
    text-align: center;
    position: relative;
    background-color: $background-color_39;
    padding: 10px;
    border-radius: 10px;
    min-width: 100px;
    min-height: 100px;
    &:last-child {
      margin-right: 0;
      &:before {
        content: none;
      }
    }
    p {
      font-weight: bold;
      font-size: 30px;
      color: $color_2;
      margin-bottom: 0;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
.email-notify {
  margin-bottom: 30px;
  .form-info {
    margin-bottom: 15px;
  }
  .btn {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
    padding: 11px 36px;
    min-width: auto;
    border-radius: 8px;
  }
}
.coming-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  p {
    margin: 0 23px 0 0;
  }
}
.terms-content {
  p {
    margin-bottom: 24px;
    color: $color_1;
  }
  ul {
    margin-bottom: 24px;
    li {
      position: relative;
      color: $color_1;
      margin-bottom: 10px;
      padding-left: 30px;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        font-family: $font-family_5;
        font-weight: 900;
        content: "\f058";
        color: $color_5;
      }
    }
  }
}
.terms-btn {
  .btn {
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.privacy-cont {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.customer-body {
  .user-menu.nav {
    > li {
      > a {
        color: $color_1;
        font-size: 13px;
        padding: 0 15px;
        margin: 15px 0px;
        line-height: unset;
        height: auto;
        > i {
          font-size: 18px;
        }
      }
      > a.viewsite {
        display: inline-flex;
        align-items: center;
        margin: 0;
        &:hover {
          color: $color_5;
          background: transparent;
          i {
            color: $color_5;
            background: transparent;
          }
        }
      }
    }
    > li.account-item {
      > a {
        &:hover {
          background-color: $background-color_19;
        }
        &:focus {
          background-color: $background-color_19;
        }
      }
    }
  }
  .header {
    border-bottom: 2px solid rgba(236, 238, 246, .85);
  }
}
.provider-body {
  .header {
    background: #fff;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    height: 76px;
    width: auto;
    transition: all 0.45s ease 0s;
    border-bottom: 2px solid rgba(236, 238, 246, 0.85);
    .has-arrow.account-item {
      .dropdown-toggle {
        &:after {
          content: none;
        }
      }
    }
  }
  .user-menu.nav {
    > li {
      > a {
        color: $color_1;
        font-size: 13px;
        padding: 0 15px;
        margin: 15px 0px;
        line-height: unset;
        height: auto;
        > i {
          font-size: 18px;
        }
      }
      > a.viewsite {
        display: inline-flex;
        align-items: center;
        margin: 0;
        &:hover {
          color: $color_5;
          background: transparent;
          i {
            color: $color_5;
            background: transparent;
          }
        }
      }
    }
    > li.account-item {
      > a {
        &:hover {
          background-color: $background-color_19;
        }
        &:focus {
          background-color: $background-color_19;
        }
      }
    }
  }
  .flag-nav {
    > a {
      img {
        margin-right: 0;
      }
    }
  }
  margin-top: 0;
  .page-wrapper {
    background-color: $background-color_1;
    padding-top: 76px;
    min-height: calc(100vh - 40px);
    margin-left: 270px;
    position: relative;
    > .content {
      padding: 24px;
    }
  }
  .sidebar-notification.opened {
    z-index: 1038;
  }
}
.page-headers {
  h6 {
    margin: 0;
    font-size: 22px;
    color: $color_2;
    font-weight: 500;
  }
  .search-bar {
    .form-control {
      border: 1px solid #C2C9D1;
      border-radius: 8px;
      padding: 9px 9px 9px 36px;
    }
    position: relative;
    width: 250px;
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }
  }
}
.header-left {
  .logo.logo-small {
    display: none;
  }
}
.header-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
}
.dropdown-heads {
  > a {
    background: #F7F7FA;
    width: 40px;
    height: 40px !important;
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
  }
  margin: 0 5px;
}
.user-link {
  display: flex;
  align-items: center;
}
span.user-content {
  padding-left: 8px;
}
span.user-name {
  display: block;
  color: $color_2;
  font-size: 14px;
  font-weight: 600;
}
span.user-details {
  color: $color_50;
  font-size: 12px;
  font-weight: 400;
}
.mobile_btn {
  display: none;
  float: left;
}
.slide-nav {
  .sidebar {
    margin-left: 0;
  }
}
.menu-title {
  color: $color_57;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
  color: $color_59;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 10px 5px 20px;
  white-space: nowrap;
  margin-top: 30px;
  h6 {
    margin: 0;
  }
  > i {
    float: right;
    line-height: 40px;
  }
}
.sidebar-overlay.opened {
  display: block;
}
.view-btn {
  margin-right: 15px;
}
.select-by {
  min-width: 134px;
  margin-right: 25px;
}
.delete-wrap {
  margin-bottom: 30px;
  h2 {
    font-size: 24px;
    margin-bottom: 25px;
  }
  p {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.modal-content.doctor-profile {
  border-radius: 4px;
}
.del-icon {
  margin-bottom: 23px;
  margin-bottom: 23px;
  i {
    font-size: 60px;
    color: $color_58;
    margin-bottom: 23px;
  }
}
.submit-section {
  text-align: center;
  margin-top: 25px;
}
.modal.contentmodal {
  .modal-content {
    .submit-section {
      .btn {
        padding: 7px 12px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        color: $color_6;
        min-width: 150px;
        margin-top: 5px;
      }
    }
  }
}
.main-wrapper {
  width: 100%;
}
.mobile_btns {
  display: none;
  float: left;
}
.mobile-only {
  display: none;
}
.sidebar {
  background-color: $background-color_1;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 76px;
  transition: all 0.2s ease-in-out 0s;
  width: 270px;
  z-index: 1038;
  .sidebar-menu {
    > ul {
      > li {
        > a {
          span {
            transition: all 0.2s ease-in-out 0s;
            display: inline-block;
            margin-left: 10px;
            white-space: nowrap;
          }
          span.chat-user {
            margin-left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span.badge {
            margin-left: auto;
          }
        }
      }
    }
  }
  > div {
    position: static !important;
  }
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
  padding: 10px;
  height: calc(100vh - 145px);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    ul {
      a {
        .menu-arrow {
          top: 10px;
          right: 0;
          top: 10px;
        }
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: $color_61;
        padding: 7px 10px 7px 68px;
        position: relative;
        &:before {
          position: absolute;
          content: '\f101';
          font-family: $font-family_5;
          left: 50px;
          top: 8px;
        }
      }
      a.active {
        color: $color_5;
      }
      li {
        &:last-child {
          a {
            padding-bottom: 0;
          }
        }
      }
      ul {
        a {
          padding-left: 65px;
        }
        ul {
          a {
            padding-left: 85px;
          }
        }
      }
    }
    li {
      a {
        i {
          display: inline-block;
          font-size: 20px;
          line-height: 24px;
          text-align: left;
          vertical-align: middle;
          width: 20px;
          transition: all 0.2s ease-in-out 0s;
        }
      }
    }
    li.menu-title {
      a {
        i {
          font-size: 16px !important;
          margin-right: 0;
          text-align: right;
          width: auto;
        }
      }
    }
  }
  li {
    a {
      color: $color_1;
      display: block;
      font-size: 15px;
      font-weight: 500;
      height: auto;
      padding: 0 20px;
      &:hover {
        color: $color_5;
      }
      > .badge {
        color: $color_6;
      }
    }
    a.subdrop {
      .menu-arrow {
        -transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        i {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
    > a {
      &:hover {
        img {
          filter: invert(29%) sepia(76%) saturate(7498%) hue-rotate(246deg) brightness(99%) contrast(88%);
        }
      }
    }
  }
  > ul {
    > li {
      > a {
        &:hover {
          color: $color_5;
        }
        align-items: center;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        position: relative;
        transition: all 0.2s ease-in-out 0s;
      }
      margin-bottom: 20px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
    }
    > li.active {
      > a {
        &:hover {
          color: $color_5;
        }
      }
    }
  }
  li.active {
    > a {
      color: $color_5;
      color: $color_5;
      img {
        filter: invert(29%) sepia(76%) saturate(7498%) hue-rotate(246deg) brightness(99%) contrast(88%);
      }
    }
  }
  .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: $font-family_2;
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 2px;
    &:before {
      content: "\f105";
    }
    i {
      -webkit-transition: -webkit-transform 0.15s;
      -o-transition: -o-transform 0.15s;
      transition: transform .15s;
      position: absolute;
      right: 20px;
      display: inline-block;
      text-rendering: auto;
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      top: 10px;
      width: auto;
    }
  }
  li.menu-title {
    a {
      color: $color_60;
      display: inline-block;
      margin-left: auto;
      padding: 0;
    }
    a.btn {
      color: $color_6;
      display: block;
      float: none;
      font-size: 15px;
      margin-bottom: 15px;
      padding: 10px 15px;
    }
  }
  .set-menu {
    padding: 15px 0;
  }
  .help-menu {
    padding: 15px 0 0;
  }
}
.siderbar-toggle {
  .switch {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    padding: 0 20px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  position: relative;
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #CCCFE0;
    -webkit-transition: .4s;
    transition: .4s;
    width: 41px;
    height: 21px;
    &:before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      left: 4px;
      bottom: 2px;
      background-color: $background-color_32;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input {
    &:checked {
      + {
        .slider {
          background-color: $background-color_2;
          &:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
            left: -5px;
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #4c40ed;
        }
      }
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
.sidebar-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 27px;
  justify-content: space-between;
}
.sidebar-logo {
  .logo {
    height: 27px;
  }
}
.card-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.mini-sidebar {
  .logo-small {
    display: block;
    min-width: 20px;
  }
  .sidebar-header {
    padding: 15px 0;
    justify-content: center;
  }
  .menu-user-info {
    display: none;
  }
  .menu-user {
    span {
      display: none;
    }
  }
  .logo {
    display: none;
  }
  #toggle_btn {
    .slider {
      background-color: $background-color_2;
      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        left: -5px;
      }
    }
  }
}
.mini-sidebar.expand-menu {
  .sidebar-header {
    padding: 27px;
    justify-content: space-between;
  }
  .logo {
    display: block;
  }
  .siderbar-toggle {
    opacity: 1;
    width: auto;
  }
}
.logo-small {
  display: none;
}
.menu-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  align-self: end;
  justify-content: space-between;
  margin: 50px 0 0;
  padding: 0 15px 15px;
  width: 100%;
}
.menu-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menu-user-img {
  position: relative;
  margin-right: 10px;
  img {
    width: 41px;
    height: 41px;
    border-radius: 50%;
  }
}
.menu-user-info {
  h6 {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 3px;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.select-set {
  color: $color_62;
}
.user-drop {
  border: 0;
  box-shadow: 0px 0px 30px rgba(69, 69, 69, 0.15);
  padding: 20px;
  display: none;
  position: absolute;
  bottom: 15px;
  left: 100%;
  width: 300px;
  ul {
    border-top: 2px solid #F4F4F4;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        padding: 0;
        font-size: 14px;
        color: $color_64;
      }
    }
  }
  .menu-user {
    margin-bottom: 15px;
  }
  .menu-user-info {
    p {
      color: $color_65;
    }
  }
}
#dropboxes {
  &::before {
    border: 8px solid #fff;
    border-color: $border-color_11;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
    content: "";
    left: 0;
    position: absolute;
    bottom: 16px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.set-user {
  border-top: 2px solid #F4F4F4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  p {
    font-weight: 500;
    font-size: 14px;
    color: $color_63;
    margin-bottom: 0;
  }
}
.help-menu {
  li {
    a {
      font-weight: 400;
      text-decoration: underline;
    }
  }
}
.main-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.dash-card {
  background: #fff;
  border: 2px solid rgba(236, 238, 246, 0.85);
  border-radius: 6px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.dash-header {
  padding: 20px;
  p {
    font-size: 15px;
    span {
      color: $color_2;
      font-weight: 500;
    }
  }
  select {
    background: #F7F7FF;
    border: 1px solid #4c40ed;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    color: $color_5;
    padding: 6px;
  }
}
.dash-widget-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
}
.dash-widget-icon {
  width: 44px;
  height: 44px;
  background: #F4F7FF;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 12px;
  flex-shrink: 0;
}
.dash-widget-info {
  margin-right: 20px;
  h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  h5 {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.dash-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  p {
    margin: 0 10px 0 0;
  }
  .badge-success {
    font-size: 13px;
    padding: 1px 7px;
    border-radius: 25px;
  }
  .btn {
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 22px;
  }
}
.dash-widget-img {
  margin-top: auto;
  img {
    width: 100%;
  }
}
.subhead-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.plan-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.plan-price {
  h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2px;
    text-align: right;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  margin-bottom: 15px;
  .btn {
    min-width: 70px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 15px;
  }
}
.plan-term {
  h6 {
    font-weight: 500;
    color: $color_1;
    margin-bottom: 0;
    span {
      color: $color_2;
    }
  }
  h5 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 7px;
  }
  p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.pay-next {
  .plan-term {
    h6 {
      font-size: 15px;
      font-weight: 400;
    }
  }
  .plan-info {
    &:last-child {
      .plan-term {
        margin-bottom: 0;
      }
      margin: auto 0 0;
      .plan-price {
        margin-bottom: 0;
      }
    }
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.plan-btns {
  .btn {
    font-weight: 500;
    font-size: 14px;
    margin: 5px 14px 0 0;
  }
}
.view-history {
  text-align: center;
  .btn {
    font-weight: 500;
    font-size: 14px;
    padding: 9px 35px;
  }
}
.price-tab {
  ul {
    li {
      margin: 0 14px 16px 0;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        background: #fff;
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        padding: 10px 24px;
        color: $color_2;
        &:hover {
          background: #EDF3F9;
          border-color: $border-color_12;
          color: $color_2;
        }
      }
      .active {
        background: #EDF3F9;
        border-color: $border-color_12;
        color: $color_2;
      }
    }
  }
}
.price-subscribe {
  margin-bottom: 20px;
  .price-card {
    margin-bottom: 30px;
    &:hover {
      background: #F4F7FF;
      .price-head {
        h1 {
          color: $color_2;
          span {
            color: $color_1;
          }
        }
        h6 {
          color: $color_1;
        }
      }
      .price-body {
        ul {
          li {
            color: $color_2;
          }
        }
      }
      .btn-choose {
        background: #4c40ed;
        color: $color_6;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  .btn-choose {
    background: #F7F7FF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $color_5;
    padding: 9px 24px;
    margin: 0 auto;
    &:hover {
      background: #4c40ed;
      color: $color_6;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.price-btn {
  text-align: center;
  .btn {
    font-weight: 500;
    padding: 12px 20px;
  }
}
.subscribe-box {
  border: 2px solid #F4F4F4;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 24px;
  ul {
    li {
      font-weight: 500;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        float: right;
        margin-right: 40px;
        a {
          color: $color_67;
          text-decoration: underline;
        }
      }
    }
  }
}
.payment-subscribe {
  .btn {
    font-weight: 700;
    padding: 12px 20px;
    margin-bottom: 30px;
    width: 100%;
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1.5rem;
  }
}
.payment-methods {
  .custom_radio {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 25px;
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1.5rem;
    span {
      color: $color_68;
      font-size: 15px;
    }
  }
}
.serv-info {
  .serv-edit {
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
    color: $color_1;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    i {
      margin-right: 5px;
      color: $color_32;
    }
  }
  span {
    font-weight: 500;
    font-size: 14px;
    color: $color_1;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    i {
      margin-right: 5px;
      color: $color_32;
    }
  }
  a {
    &:hover {
      color: $color_5;
      i {
        color: $color_5;
      }
    }
  }
  &:hover {
    span {
      color: $color_5;
    }
  }
  .rating {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    span {
      color: $color_110;
    }
  }
}
.pro-service {
  .service-content {
    padding: 15px;
    .title {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  .service-widget {
    .btn-book {
      font-size: 14px;
    }
  }
}
.tab-list {
  ul {
    margin-bottom: 10px;
  }
  li {
    margin: 0 10px 20px 0;
    a {
      background: #F7F7FF;
      border: 1px solid #4c40ed;
      border-radius: 5px;
      font-weight: 500;
      color: $color_5;
      padding: 6px 15px;
      display: inline-block;
      &:hover {
        background: #4c40ed;
        border-color: $border-color_1;
        color: $color_6;
      }
    }
    a.active {
      background: #4c40ed;
      border-color: $border-color_1;
      color: $color_6;
    }
  }
}
.edit-service {
  .login-back {
    margin-bottom: 20px;
  }
  h5 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 30px;
  }
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 25px;
  }
}
.category-rating {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .item-cat {
    margin-right: 10px;
  }
  .serv-rating {
    display: inline-block;
    background: #F9F9F9;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    color: $color_2;
    padding: 3px 9px;
    margin-bottom: 12px;
    i {
      color: $color_33;
      margin-right: 5px;
    }
  }
}
.service-action {
  ul {
    li {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      margin-right: 20px;
      a {
        color: $color_1;
        &:hover {
          color: $color_5;
          i {
            color: $color_5;
          }
        }
      }
      i {
        color: $color_32;
        margin-right: 6px;
      }
    }
  }
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex: 0 0 315px;
  justify-content: flex-end;
  flex-wrap: wrap;
  h6 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 0;
    .old-price {
      font-weight: 500;
      font-size: 16px;
      color: $color_1;
      text-decoration: line-through;
      margin-left: 5px;
    }
  }
  .btn {
    font-size: 14px;
    font-weight: 500;
    margin-left: 30px;
    padding: 10px 23px;
  }
}
.add-set {
  font-weight: 500;
  font-size: 13px;
  padding: 9px 13px;
}
.page-header {
  .add-set {
    margin-bottom: 30px;
  }
  .review-sort {
    margin-bottom: 30px;
  }
  .grid-listview {
    margin-bottom: 30px;
  }
}
.balance-crad {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  h3 {
    font-size: 32px;
    margin: 0;
  }
  .view-transaction {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 15px;
    border-radius: 4px;
  }
  .btn-withdraw {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 15px;
    border-radius: 4px;
    margin: 6px auto 0;
    display: block;
  }
}
.balance-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .balance-icon {
    width: 50px;
    height: 50px;
    background: #F7F7FF;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
  }
  h6 {
    font-size: 15px;
    color: $color_1;
    font-weight: 400;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
.balance-amt {
  .badge-success {
    font-size: 12px;
    margin-bottom: 10px;
    padding: 4px 10px;
  }
}
.provider-subtitle {
  h6 {
    font-size: 24px;
    font-family: $font-family_4;
    margin-bottom: 30px;
  }
}
.provide-table {
  margin-bottom: 30px;
}
.availability-sec {
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .status-toggle {
    margin-bottom: 15px;
  }
  .input-group-text {
    background: #F6F6F6;
    border-radius: 10px 0px 0px 10px;
    min-width: 74px;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
  }
}
.text-dark {
  color: $color_69 !important;
}
.fw-500 {
  font-weight: 500;
}
.action-set {
  background: #F7F7FF;
  color: $color_1;
  width: 36px;
  display: inline-flex;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: #4c40ed;
    color: $color_6;
  }
}
.table-action {
  .action-set {
    &:last-child {
      margin-right: 0;
    }
  }
}
.coupon-submit {
  text-align: center;
  .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px;
    min-width: 187px;
  }
}
.custom_radio.radio-success {
  input {
    &:checked {
      ~ {
        .checkmark {
          border-color: $border-color_13;
          &::after {
            background-color: $background-color_37;
          }
        }
      }
    }
  }
}
.coupon-title {
  h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 24px;
  }
}
.page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.content-side {
  min-width: 260px;
}
.content-sidebar {
  padding: 24px;
}
.main-content {
  width: calc(100% - 260px);
  padding: 24px;
}
.bootstrap-tagsinput {
  display: block;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #dcdcdc;
  height: 46px;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  box-shadow: none;
  overflow-y: auto;
  .tag {
    margin-right: 5px;
    margin-bottom: 2px;
    color: $color_1;
    background: #F4F7FF !important;
    font-size: 14px;
    font-weight: 500;
    color: $color_1;
    border-radius: 5px;
    margin-top: 5px;
    &:hover {
      color: $color_1;
    }
    [data-role="remove"] {
      position: relative;
      left: 7px;
      cursor: pointer;
      &:after {
        content: "x";
        padding: 0px 2px;
      }
    }
  }
  input {
    line-height: 36px;
    border: 1px solid transparent;
    background: transparent;
    &:focus-visible {
      border: 1px solid transparent;
    }
  }
}
.label-text {
  font-size: 11px;
  color: $color_74;
}
.form-checkbox {
  .custom_check {
    margin-right: 22px;
    margin-bottom: 2px;
  }
}
.slot-flex {
  border: 1px solid #C2C9D1;
  border-radius: 5px;
  .form-control {
    border: 0;
  }
  .select2-container--default {
    .select2-selection--single {
      border: 0;
      .select2-selection__rendered {
        color: $color_5;
      }
    }
  }
  .select2-container {
    width: auto !important;
    min-width: 100px;
  }
}
.remaind-select {
  .bank-selection {
    display: inline-block;
    margin-right: 13px;
    label {
      background: #F7F7FF;
      width: auto;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;
      height: auto;
      padding: 5px 14px;
    }
    input {
      &:checked {
        ~ {
          label {
            background: #4c40ed;
            color: $color_6;
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
    .role-check {
      background: #fff;
      height: 20px;
    }
  }
}
.appointment-sec {
  .widget-title {
    h4 {
      font-size: 22px;
      font-weight: 500;
    }
  }
}
.visa-img {
  background: #fff;
  border: 1px solid #DFE0E3;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 8px;
}
.card-payment-add {
  background: #fff;
  border: 2px dashed #D9D9D9;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  span {
    width: 32px;
    height: 32px;
    background: #98A2B3;
    color: $color_6;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}
.form-duration {
  position: relative;
  .mins {
    position: absolute;
    background: #fff;
    border-radius: 6px;
    color: $color_5;
    font-weight: 500;
    font-size: 14px;
    padding: 9px 18px;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
  }
  .form-control {
    padding: 10px 70px 10px 15px;
  }
}
.ck-editor {
  .ck.ck-editor__main {
    > .ck-editor__editable {
      &:not(.ck-focused) {
        border: 1px solid #E5E7EB;
        border-radius: 0 0 6px 6px;
      }
    }
  }
  .ck.ck-toolbar {
    background: #fff;
    border: 1px solid #E5E7EB;
    border-radius: 6px 6px 0 0 !important;
  }
}
.sml-info {
  font-size: 12px;
  color: $color_42;
}
.area-info {
  font-size: 11px;
  color: $color_75;
}
.add-text {
  font-weight: 500;
  font-size: 14px;
  color: $color_5;
}
#progressbar {
  margin-bottom: 20px;
  display: flex;
  li {
    border-radius: 10px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
    margin: 0 24px 20px 0;
    box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
    padding: 10px;
    position: relative;
    text-align: left;
    width: 100%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
  }
  li.active {
    opacity: 1;
    &::after {
      #progressbar {
        li.activated {
          &::after {
            border-left: 15px solid #F7F7FF;
          }
        }
      }
    }
    span {
      background: #4c40ed;
      color: $color_6;
    }
  }
  li.activated {
    opacity: 1;
    cursor: pointer;
    span {
      background-color: $background-color_70;
      color: $color_6;
    }
  }
  .multi-step-info {
    h6 {
      font-weight: 500;
      color: $color_2;
      margin-bottom: 2px;
    }
    p {
      font-size: 14px;
      color: $color_1;
      margin-bottom: 0;
    }
  }
  .multi-step-icon {
    span {
      background: #FAFAFA;
      padding: 24px;
      border-radius: 10px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      margin-right: 15px;
    }
  }
  li.active.activated {
    span {
      background: #333;
    }
  }
}
.sub-title {
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
  }
}
.schedule-nav {
  margin-bottom: 10px;
  ul {
    li {
      margin: 5px 15px 10px 0;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      a.active {
        background: #4c40ed;
        border: 1px solid #4c40ed;
        color: $color_6;
      }
      a {
        &:hover {
          background: #4c40ed;
          border: 1px solid #4c40ed;
          color: $color_6;
        }
      }
    }
    > li {
      > a {
        background: #F7F7FF;
        border: 1px solid #F7F7FF;
        border-radius: 5px;
        padding: 7px 15px;
        color: $color_5;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
.input-icon {
}
.file-upload {
  background: #fff;
  border: 1px solid #C2C9D1;
  border-radius: 5px;
  padding: 45px 20px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  img {
    margin-bottom: 20px;
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 6px;
    span {
      color: $color_5;
      text-decoration: underline;
    }
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.file-preview {
  ul {
    margin-bottom: 5px;
    li {
      display: inline-block;
      margin: 0 15px 15px 0;
      position: relative;
      h6 {
        font-weight: 400;
        font-size: 14px;
      }
      a {
        position: absolute;
        top: 3px;
        right: 3px;
        color: $color_8;
      }
      img {
        width: 83px;
        height: 72px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}
.img-preview {
  position: relative;
  display: inline-block;
  a {
    position: absolute;
    right: 0;
    top: 5px;
  }
  a.remove-item {
    display: none;
  }
}
.field-btns {
  margin-top: 20px;
  text-align: right;
  .btn {
    font-weight: 500;
    font-size: 14px;
    padding: 9px 24px;
  }
}
.timepicker-sec {
  display: none;
}
fieldset#first-field {
  display: block;
}
.service-inform-fieldset {
  fieldset {
    display: none;
  }
}
.page-topbar {
  .back-link {
    a {
      font-size: 15px;
      color: $color_26;
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  .page-breadcrumb {
    ol {
      font-size: 16px;
      margin-bottom: 30px;
      li.active {
        color: $color_2;
      }
    }
  }
}
.book-id {
  margin-bottom: 15px;
  h4 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 10px;
  }
}
.book-id-action {
  text-align: right;
  margin-bottom: 30px;
  .btn {
    padding: 9px 15px;
    font-weight: 500;
    font-size: 14px;
    margin: 0 14px 10px 0;
    border-radius: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.chat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .btn {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 14px;
    margin-bottom: 10px;
  }
}
.slot-box {
  background: #F8F8F8;
  padding: 20px 20px 10px;
  margin-bottom: 30px;
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.slot-booked {
  ul {
    li {
      i {
        color: $color_32;
      }
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
.slot-user-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.slot-chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.slot-user-info {
  margin-right: 25px;
  p {
    font-size: 14px;
    margin-bottom: 6px;
  }
}
.slot-action {
  .btn {
    font-weight: 500;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 5px;
    margin: 0 15px 10px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  float: right;
  .btn-light-success {
    color: $color_76;
  }
}
.order-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.slot-address {
  h6 {
    font-size: 14px;
    margin-bottom: 13px;
  }
  ul {
    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 15px;
      span {
        width: 40px;
        height: 40px;
        background: #F7F7FF;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        margin-right: 10px;
      }
      h6 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 4px;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}
.order-amt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  h5 {
    margin-bottom: 10px;
  }
}
.order-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.order-img {
  margin-right: 8px;
  img {
    width: 55px;
    height: 47px;
    border-radius: 8px;
  }
}
.order-profile {
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.ord-summary {
  ul {
    li {
      font-weight: 500;
      color: $color_2;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .ord-amt {
        float: right;
        font-weight: 400;
        color: $color_1;
      }
    }
    li.ord-total {
      span {
        font-weight: 500;
        color: $color_77;
      }
    }
  }
}
.ord-code {
  background: #F7F7FF;
  border-radius: 5px;
  padding: 4px 9px;
  font-size: 13px;
  color: $color_5;
  margin-left: 15px;
}
.book-history {
  ul {
    li {
      position: relative;
      padding: 0 0 24px 20px;
      margin-left: 4px;
      border-left: 2px solid #E4E7EC;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: -4px;
        width: 7px;
        height: 7px;
        background: #fff;
        outline: 4px solid #3D5EE1;
        border-radius: 50%;
      }
      &:last-child {
        border: 0;
        padding-bottom: 0;
      }
      h6 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
        i {
          color: $color_5;
        }
      }
    }
  }
}
.payment-summary {
  border-bottom: 1px solid #D8E2E8;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.slot-pay {
  p {
    font-size: 13px;
    .slot-img {
      background: #D8E3F3;
      border-radius: 4px;
      padding: 2px 1px;
      margin-left: 10px;
    }
  }
}
.slot-title {
  font-weight: 500;
  font-size: 18px;
  color: $color_2;
  margin-right: 40px;
}
.order-reviews {
  ul {
    li {
      .order-comment {
        margin-bottom: 20px;
      }
      &:last-child {
        .order-comment {
          margin-bottom: 0;
        }
      }
    }
  }
  .btn {
    font-weight: 500;
    font-size: 14px;
    padding: 9px 20px;
    margin-bottom: 20px;
  }
}
.order-comment {
  .rating {
    margin-bottom: 15px;
  }
  h6 {
    font-size: 16px;
    font-weight: 500;
    color: $color_26;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    i {
      color: $color_5;
    }
  }
}
.fc {
}
.leave-action {
  .btn {
    font-weight: 500;
    font-size: 13px;
    padding: 5px 14px;
    border-radius: 4px;
    margin: 0 10px 30px 0;
    &:last-child {
      margin-right: 0;
    }
  }
}
.msg-alert {
  margin-bottom: 29px;
  p {
    font-size: 14px;
    color: $color_72;
    margin-bottom: 10px;
    span {
      color: $color_2;
      font-weight: 500;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -4px;
  margin-top: -4px;
  width: 10px;
  height: 10px;
  z-index: 10000001;
  background: #4c40ed !important;
  -webkit-transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
  transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background: #4c40ed !important;
  opacity: .1;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: .5;
  border: 2px solid #4c40ed;
  -webkit-transition: all .08s ease-out;
  transition: all .08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}
.toggle-sidebar {
  width: 400px;
  position: fixed;
  top: 0;
  right: -400px;
  height: 100vh;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  z-index: 1040;
  .sidebar-header {
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    border-bottom: 1px solid #F0F0F0;
    h5 {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
  .sidebar-close {
    background: #F82424;
    border: 0;
    border-radius: 50%;
    color: $color_6;
    font-size: 15px;
    width: 29px;
    height: 29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin: 0;
    &:hover {
      background: #4c40ed;
      color: $color_6;
    }
  }
}
.sidebar-layout {
  background-color: $background-color_1;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 33px 74px 14px rgba(76, 64, 237, 0.08);
  border-radius: 20px 0px 0px 20px;
  max-width: 400px;
  width: 100%;
  position: absolute;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.bk-wrap {
  border-bottom: 1px solid #F0F0F0;
  padding: 25px 25px 20px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .bk-price {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.book-customer {
  h5 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.book-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 17px 5px 0;
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 10px;
  }
  p {
    font-size: 12px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.book-email-info {
  p {
    font-size: 12px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.btn-mail {
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 14px;
  margin-left: 10px;
}
.edit-book {
  &:hover {
    background: #4c40ed;
    color: $color_6;
  }
  width: 32px;
  height: 32px;
  background: #F7F7FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  color: $color_1;
  margin-bottom: 20px;
}
.bk-service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.book-confirm {
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    span {
      font-size: 13px;
      padding: 8px 16px;
      margin-left: 10px;
    }
  }
  ul {
    margin-bottom: 25px;
    li {
      font-size: 12px;
      margin-bottom: 10px;
      .bk-date {
        font-weight: 500;
        font-size: 14px;
        color: $color_2;
        min-width: 100px;
        display: inline-block;
        i {
          color: $color_32;
          width: 18px;
          display: inline-block;
        }
      }
      span {
        color: $color_2;
      }
    }
  }
  .btn {
    font-weight: 500;
    font-size: 13px;
  }
}
.bk-history {
  h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  ul {
    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      padding-bottom: 40px;
      &:last-child {
        padding-bottom: 0;
        &:before {
          content: none;
        }
      }
      &:before {
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        background: #EEEEEE;
        left: 19px;
        top: 0;
      }
    }
  }
  span {
    width: 38px;
    height: 38px;
    background: #F5F4FE;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-radius: 50%;
    color: $color_5;
    margin-right: 15px;
    z-index: 1;
  }
  h6 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.wizard-book {
  ul {
    li {
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      position: relative;
      padding-right: 40px;
      &:last-child {
        padding-right: 0;
        &:before {
          content: none;
        }
      }
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        top: 17px;
        left: 15px;
        background: #DBE3EB;
      }
      > div {
        text-align: center;
        z-index: 1;
      }
      span {
        width: 37px;
        height: 37px;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        background: #DBE3EB;
        color: $color_6;
        border-radius: 50%;
        margin-bottom: 8px;
        z-index: 1;
      }
      h6 {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    li.activate {
      &:before {
        background: #4c40ed;
      }
      span {
        background: #4c40ed;
      }
    }
    li.active {
      span {
        background: #4c40ed;
      }
    }
  }
}
.bk-action {
  text-align: center;
  .btn {
    margin-right: 19px;
    border-radius: 4px;
    padding: 5px 14px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.chat-scroll {
  max-height: calc(100vh - 264px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background: #fff;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D4D4D4;
    border-radius: 10px;
    &:hover {
      background: #D4D4D4;
    }
  }
}
.chat-cont-left {
  .chat-scroll {
    max-height: calc(100vh - 286px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      background: #fff;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #D4D4D4;
      border-radius: 10px;
      &:hover {
        background: #D4D4D4;
      }
    }
  }
  .chat-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: $background-color_1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 15px;
    h6 {
      font-size: 20px;
      margin-bottom: 3px;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  .chat-header.inner-chat-header {
    padding: 15px 0;
    h6 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  .card {
    border: 2px solid rgba(220, 220, 220, 0.3);
    border-radius: 5px;
  }
  .chat-users-list {
    a.media {
      border: 1px solid #fff;
      padding: 8px;
      transition: all 0.2s ease 0s;
      .media-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        > div {
          &:first-child {
            .user-name {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: $color_2;
              font-weight: 500;
              font-size: 15px;
              text-transform: capitalize;
              margin-bottom: 3px;
            }
            .user-last-chat {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: $color_95;
              font-size: 13px;
            }
          }
          &:last-child {
            .badge {
              width: 15px;
              height: 15px;
              background: #4c40ed;
              border-radius: 50%;
              color: $color_6;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              justify-content: center;
              -webkit-justify-content: center;
              -ms-flex-pack: center;
              font-weight: 500;
              font-size: 9px;
              padding: 0;
            }
            text-align: right;
            .last-chat-time {
              color: $color_95;
              font-size: 13px;
            }
          }
        }
      }
      .media-img-wrap {
        margin-right: 10px;
        position: relative;
        .avatar {
          width: 35px;
          height: 35px;
        }
      }
    }
    a.media.active {
      background-color: $background-color_44;
      border-radius: 5px;
    }
  }
}
.contacts_body {
  padding: 0.75rem 0;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
  .media {
    .media-body {
      .msg-box {
        .chat-file-icon {
          margin-right: 9px;
          i {
            font-size: 25px;
          }
        }
        .chat-file-info {
          h6 {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 5px;
          }
          p {
            font-size: 12px;
            color: $color_81;
            margin-bottom: 0;
          }
        }
        > div {
          padding: 12px 17px;
          border-radius: 10px;
          display: inline-block;
          position: relative;
          p {
            font-size: 14px;
            margin-bottom: 0;
          }
        }
        + {
          .msg-box {
            margin-top: 5px;
          }
        }
      }
      margin-left: 6px;
    }
    .avatar {
      height: 25px;
      width: 25px;
      width: 40px;
      height: 40px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .chat-msg-info {
      li {
        .dropdown-item {
          padding: 4px 6px;
          padding: 4px 15px;
        }
      }
    }
  }
  ul.list-unstyled {
    margin: 0 auto;
    padding: 1rem 0 0;
    width: 100%;
    border-top: 1px solid #E8EBED;
    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .media.received {
    margin-bottom: 20px;
    .chat-msg-info {
      li {
        a {
          color: $color_87;
        }
      }
    }
    .media-body {
      .msg-box {
        > div {
          background-color: $background-color_48;
          color: $color_6;
          .chat-time {
            color: $color_87;
          }
        }
        position: relative;
        display: inline-block;
        &:first-child {
          > div {
            border-radius: 15px 15px 15px 0;
            margin-top: 0;
          }
        }
        div.msg-img {
          border-radius: 15px 0px 0px 0px;
          padding: 8px;
        }
      }
    }
  }
  .media.sent {
    margin-bottom: 20px;
    align-items: flex-end;
    .media-body {
      -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
      align-items: flex-end;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: flex-end;
      margin-left: 0;
      margin-right: 6px;
      .msg-box {
        > div {
          background: #F7F7F8;
          .chat-time {
            color: $color_90;
          }
          .chat-msg-info {
            flex-direction: row-reverse;
            li {
              padding-left: 16px;
              padding-right: 0;
              position: relative;
              &:last-child {
                padding-left: 0;
              }
            }
          }
          .chat-msg-attachments {
            flex-direction: row-reverse;
          }
          &:hover {
            .chat-msg-actions {
              opacity: 1;
            }
          }
        }
        margin-left: 70px;
        position: relative;
        &:first-child {
          > div {
            border-radius: 15px 15px 0 15px;
            margin-top: 0;
            border: 1px solid #FAFAFA;
            background: #FAFAFA;
          }
        }
      }
    }
    .avatar {
      -ms-flex-order: 3;
      order: 3;
      margin-bottom: 30px;
    }
  }
  .chat-date {
    font-size: 13px;
    margin: 22px 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    span {
      color: $color_88;
      background: #fff;
      border: 1px solid #F1F1F1;
      border-radius: 5px;
      padding: 2px 10px;
      display: inline-block;
      background-color: $background-color_72;
      color: $color_116;
    }
  }
}
.chat-list {
  margin: 0 auto;
  padding: 0 14px;
  text-align: center;
  li {
    display: inline-block;
    margin-bottom: 5px;
    a {
      padding: 5px 11px;
      font-weight: 500;
      font-size: 12px;
      color: $color_80;
      border-radius: 50px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      &:hover {
        background-color: $background-color_44;
      }
      i {
        margin-right: 5px;
      }
    }
    a.active {
      background-color: $background-color_44;
    }
  }
}
.chat-compose {
  width: 37px;
  height: 37px;
  color: $color_80;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #F9F9F9;
  border-radius: 5px;
  font-size: 18px;
}
.chat-file-attachments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-file-attach {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-right: 20px;
}
.chat-file-download {
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.card-footer {
  background-color: $background-color_1;
  border-radius: 0 0 15px 15px;
  border-top: 0;
}
.search {
  border-radius: 15px 0 0 15px;
  background-color: $background-color_45;
  border: 0;
  color: $color_6;
  &:focus {
    box-shadow: none;
    outline: 0px;
  }
}
.btn-file {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
  background: #EDEEF7;
  border: 1px solid #EDEEF7;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  position: absolute !important;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
.type_msg {
  background: #fff;
  border: 1px solid #4c40ed;
  box-shadow: 0px 4px 8px rgba(52, 68, 76, 0.04);
  border-radius: 50px;
  overflow-y: auto;
  min-height: 50px;
  padding: 10px 107px 10px 45px;
  border-radius: 50px !important;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    outline: 0px;
    z-index: unset !important;
  }
  &::placeholder {
    color: $color_82;
  }
}
.send-action {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  a {
    width: 30px;
    height: 30px;
    display: inline-flex;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    background: #EDEEF7;
    border: 1px solid #F3F3F3;
    color: $color_83;
    border-radius: 50%;
    margin-right: 7px;
    font-size: 12px;
    &:hover {
      background: #4c40ed;
      color: $color_6;
    }
  }
}
.btn_send {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 0;
  font-size: 12px;
}
.search_btn {
  background-color: $background-color_19;
  border: 0;
  line-height: 0;
}
.user_img {
  height: 46px;
  width: 46px;
}
.img_cont {
  position: relative;
  height: 45px;
  width: 45px;
}
.offline {
  background-color: $background-color_46;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    font-size: 18px;
    font-weight: 600;
    color: $color_2;
  }
  .user-name {
    color: $color_84;
  }
  p {
    font-size: 14px;
    line-height: normal;
  }
  p.active {
    color: $color_85;
  }
  p.inactive {
    color: $color_86;
  }
}
.chat-window {
  .avatar-online {
    &::before {
      background-color: $background-color_47;
    }
  }
}
.msg_head {
  position: relative;
  background-color: $background-color_19;
  border-bottom: 0 !important;
}
.chat-cont-right {
  .chat-footer {
    background-color: $background-color_1;
    border-top: 1px solid #f0f0f0;
    padding: 10px 15px;
    position: relative;
    .input-group {
      width: 100%;
      .form-control {
        background-color: $background-color_49;
        border: none;
        border-radius: 50px;
        &:focus {
          background-color: $background-color_49;
          border: none;
          box-shadow: none;
        }
      }
      .input-group-prepend {
        .btn {
          background-color: $background-color_19;
          border: none;
          color: $color_89;
        }
      }
      .input-group-append {
        .btn {
          background-color: $background-color_19;
          border: none;
          color: $color_89;
        }
        .btn.msg-send-btn {
          background-color: $background-color_50;
          border-color: $border-color_14;
          border-radius: 50%;
          color: $color_6;
          margin-left: 10px;
          min-width: 46px;
          font-size: 20px;
        }
      }
    }
  }
  .card {
    border-color: $border-color_15;
    border-radius: 5px;
  }
  .card-header {
    padding: 15px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .img_cont {
    width: auto;
    height: auto;
  }
  .msg_head {
    .back-user-list {
      display: none;
      padding-right: 10px;
      margin-left: -10px;
      padding-left: 10px;
    }
  }
}
.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
  span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: $background-color_1;
    display: block;
    border-radius: 50%;
    opacity: .4;
    &:nth-of-type(1) {
      animation: 1s blink infinite .33333s;
    }
    &:nth-of-type(2) {
      animation: 1s blink infinite .66666s;
    }
    &:nth-of-type(3) {
      animation: 1s blink infinite .99999s;
    }
  }
}
.chat-msg-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  clear: both;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  font-size: 10px;
  padding: 0;
  margin: 0 0 5px;
  bottom: -20px;
  left: 0;
  width: 100%;
  margin-top: 5px;
  li {
    font-size: 13px;
    padding-right: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &:last-child {
      padding-right: 0;
    }
    .chat-time {
      margin-right: 10px;
    }
    .drop-item {
      color: $color_87;
    }
    a {
      color: $color_91;
      &:hover {
        color: $color_92;
      }
    }
  }
}
.media.sent {
  .chat-msg-info {
    li {
      .chat-time {
        margin: 0 0 0 10px;
      }
    }
  }
}
.chat-seen {
  i {
    color: $color_93;
    font-size: 16px;
  }
}
.chat-msg-attachments {
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 -1px;
  gap: 10px;
  > div {
    margin: 0 1px;
  }
}
.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
  border-radius: .25rem;
  &:hover {
    &:before {
      opacity: 0.6;
    }
    .chat-attach-caption {
      opacity: 0;
    }
    .chat-attach-download {
      opacity: 1;
    }
  }
}
.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: $color_6;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all .4s;
}
.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .4s;
  color: $color_6;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  &:hover {
    color: $color_94;
    background: #fff;
  }
}
.chat-attachment-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -5px;
  li {
    width: 33.33%;
    padding: 5px;
  }
}
.chat-attachment-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .4s;
  z-index: 2;
  > a {
    padding: 0 10px;
    color: $color_94;
    font-size: 24px;
    &:hover {
      color: $color_92;
    }
  }
}
.card-body.chat-users-list {
  padding: 15px;
}
.chat-page {
  .footer {
    display: none;
  }
}
.chat-search {
  padding: 0px 15px 24px;
  width: 100%;
  .input-group {
    width: 100%;
    .form-control {
      border: 1px solid #F7F7FF;
      border-radius: 7px !important;
      background-color: $background-color_1;
      padding-left: 36px;
      min-height: 35px;
      font-size: 13px;
      &:focus {
        border-color: $border-color_16;
        box-shadow: none;
      }
    }
    .input-group-prepend {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      bottom: 0;
      color: $color_96;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      left: 10px;
      pointer-events: none;
      position: absolute;
      top: 0;
      z-index: 4;
    }
    .form-control.chat-search {
      border-radius: 5px;
      border: 1px solid #E1E1E1;
      padding: 10px;
    }
  }
}
.chat-options {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    display: -webkit-flex;
    li {
      a {
        width: 30px;
        height: 30px;
        background-color: $background-color_1;
        border-radius: 30px;
        display: inline-block;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        i {
          color: $color_1;
        }
        &:hover {
          background-color: $background-color_44;
          background: #4c40ed;
          i {
            color: $color_6;
          }
        }
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
      a.with-bg {
        background-color: $background-color_44;
      }
    }
  }
}
.msg-highlight {
  a {
    font-size: 13px;
    color: $color_5;
    text-decoration: underline;
  }
}
.chat-cont-profile {
  .card {
    border: 2px solid rgba(220, 220, 220, 0.3);
    border-radius: 5px;
  }
  .card-header {
    padding: 14px 14px 0;
    background: #fff;
    border: 0;
  }
  .card-body {
    padding: 14px;
    max-height: calc(100vh - 265px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      background: #fff;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #D4D4D4;
      border-radius: 10px;
      &:hover {
        background: #D4D4D4;
      }
    }
  }
}
.chat-profile {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.chat-profile-img {
  margin-right: 16px;
  img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
}
.chat-profile-info {
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 3px;
  }
  p {
    font-size: 11px;
    color: $color_97;
    margin-bottom: 0;
  }
}
.profile-wrap {
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 14px;
  ul {
    li {
      display: inline-block;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      a {
        width: 30px;
        height: 30px;
        background-color: $background-color_1;
        border-radius: 30px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        background-color: $background-color_44;
        color: $color_98;
        &:hover {
          background: #4c40ed;
          color: $color_6;
        }
      }
    }
  }
}
.chat-pro-list {
  ul {
    padding: 18px 0;
    border-bottom: 1px solid #F2F2F2;
    li {
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 13px;
      .role-info {
        font-weight: 400;
        color: $color_99;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.role-title {
  width: 100px;
  display: inline-block;
  i {
    margin-right: 8px;
  }
}
.media-list {
  text-align: center;
  ul {
    margin-bottom: 10px;
    justify-content: center;
    justify-content: start;
  }
  li {
    display: inline-block;
    margin-bottom: 10px;
    a {
      padding: 5px 12px;
      font-weight: 500;
      font-size: 12px;
      color: $color_80;
      border-radius: 50px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    a.active {
      background-color: $background-color_44;
    }
  }
}
.photo-list {
  ul {
    li {
      display: inline-block;
      margin: 0 12px 15px 0;
      img {
        width: 80px;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
.input-group {
  > .form-control {
    &:focus {
      z-index: 3;
      z-index: 0;
    }
  }
  > .form-floating {
    &:focus-within {
      z-index: 3;
    }
  }
  > .form-select {
    &:focus {
      z-index: 3;
    }
  }
}
.chat-notification {
  ul {
    li {
      border-bottom: 1px solid #F2F2F2;
      padding: 14px 0;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      p {
        font-weight: 500;
        font-size: 14px;
        color: $color_2;
        margin-bottom: 0;
        i {
          color: $color_80;
          margin-right: 5px;
          width: 15px;
        }
      }
      .status-toggle {
        .checktoggle {
          width: 49px;
        }
      }
    }
  }
}
.chat-media-title {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin: 18px 0 10px 0;
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
  span {
    font-size: 12px;
    color: $color_5;
    display: inline-block;
    margin-bottom: 10px;
  }
}
.photo-link {
  li {
    a {
      font-size: 12px;
      &:hover {
        color: $color_5;
      }
    }
  }
}
.photo-file {
  font-size: 12px;
  li {
    margin-bottom: 10px;
  }
}
.clear-list {
  ul {
    padding-top: 18px;
    li {
      font-weight: 500;
      font-size: 14px;
      color: $color_100;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $color_100;
        i {
          margin-right: 10px;
        }
        &:hover {
          color: $color_5;
        }
      }
    }
  }
}
.blog-list {
  background: #fff;
  border: 1px solid #F3F3F8;
  margin-bottom: 30px;
  .blog-image {
    border-radius: 10px 10px 0px 0px;
    margin: 0;
    img {
      border-radius: 10px 10px 0px 0px;
    }
  }
  .blog-content {
    padding-top: 24px;
    .blog-title {
      font-size: 32px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 24px;
    }
  }
  .blog-info {
    ul {
      li {
        i {
          color: $color_5;
        }
        margin: 0 20px 0 0;
        padding-right: 0;
      }
    }
  }
}
.grid-blog {
  .blog-image {
    margin: 0;
    border-radius: 0;
    img {
      border-radius: 0;
    }
  }
  .blog-category {
    ul {
      li {
        &:last-child {
          padding: 0;
          margin: 0;
        }
        padding-right: 10px;
        margin: 0 10px 12px 0;
      }
    }
  }
  background: #fff;
  border: 1px solid #F3F3F8;
  border-radius: 0px;
  margin-bottom: 24px;
  .blog-content {
    padding-top: 24px;
    .blog-title {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 15px;
      -webkit-line-clamp: 1;
    }
    p {
      margin-bottom: 20px;
    }
    .read-more {
      font-weight: 500;
      font-size: 16px;
      color: $color_5;
      background: #F7F7FF;
      border-radius: 4px;
      padding: 10px 15px;
      display: inline-flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      i {
        margin-left: 5px;
      }
      img {
        filter: brightness(0) saturate(100%) invert(24%) sepia(85%) saturate(5324%) hue-rotate(243deg) brightness(96%) contrast(93%);
      }
      &:hover {
        background: #4c40ed;
        color: $color_6;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
.blog-content-img {
  display: flex;
  display: -webkit-flex;
}
.blog-pagination {
  ul {
    li {
      margin-right: 10px;
      &:first-child {
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
  .page-prev {
    width: auto;
    height: auto;
    background: #fff;
    border: 0;
    font-size: 13px;
    padding: 0 3px;
    line-height: 40px;
    &:hover {
      background-color: $background-color_1 !important;
      color: $color_5;
    }
  }
  .page-next {
    width: auto;
    height: auto;
    background: #fff;
    border: 0;
    font-size: 13px;
    padding: 0 3px;
    line-height: 40px;
    &:hover {
      background-color: $background-color_1 !important;
      color: $color_5;
    }
  }
  .pagination {
    margin-top: 26px;
  }
}
.blog-info {
  ul {
    li {
      display: inline-block;
      color: $color_101;
      font-size: 15px;
      margin: 0 35px 5px 0;
      padding-right: 35px;
      position: relative;
      &:last-child {
        padding: 0;
        margin: 0;
      }
      a {
        color: $color_1;
      }
    }
  }
  .post-author {
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
    span {
      font-size: 15px;
      color: $color_1;
    }
  }
}
.blog-category {
  .post-author {
    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-right: 10px;
    }
    span {
      font-size: 16px;
      color: $color_1;
    }
  }
  ul {
    margin-bottom: 3px;
    li {
      display: inline-block;
      font-size: 14px;
      color: $color_1;
      margin: 0 10px 10px 0;
    }
  }
}
.cat-blog {
  background: #F7F7FF;
  border-radius: 5px;
  color: $color_5;
  padding: 5px 10px;
  display: inline-block;
}
.blog-sidebar {
  .card {
    background: #F8F8FF;
    border: 0;
    border-radius: 5px;
  }
  .card-body {
    padding: 24px;
  }
}
.sin-post {
  margin-bottom: 17px;
}
.about-widget {
  img {
    border-radius: 15px;
    margin-bottom: 20px;
    width: 100%;
  }
  p {
    margin-bottom: 20px;
  }
  .btn {
    font-weight: 500;
  }
}
.search-widget {
  .form-control {
    background: #fff;
    border: 0;
    border-radius: 4px;
    height: 46px;
  }
  .input-group {
    background: #fff;
    border: 0;
    border-radius: 4px;
    .btn {
      width: 50px;
      margin: 0;
      height: 46px;
      border-radius: 4px !important;
      border: 0;
      background: transparent;
      color: $color_1 !important;
      &:hover {
        background: transparent;
        box-shadow: none;
        color: $color_4 !important;
      }
    }
  }
}
.latest-posts {
  margin: 0;
  padding: 0;
  li {
    display: table;
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
}
.post-thumb {
  border-radius: 4px;
  width: 75px;
  float: left;
  overflow: hidden;
  a {
    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 5px;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      transform: translateZ(0);
      -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
      -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
      -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
      -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
      transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    }
    &:hover {
      img {
        -webkit-transform: scale(1.15);
        -moz-transform: scale(1.15);
        transform: scale(1.15);
      }
    }
  }
}
.post-info {
  margin-left: 95px;
  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    a {
      color: $color_2;
      &:hover {
        color: $color_5;
      }
    }
  }
  p {
    color: $color_101;
    font-size: 13px;
    margin-bottom: 7px;
  }
}
.category-widget {
  .categories {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 15px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      a {
        color: $color_1;
        span {
          float: right;
        }
        &:hover {
          color: $color_5;
        }
      }
    }
  }
}
.side-title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
}
.widget-archive {
  ul {
    li {
      a {
        color: $color_1;
        &:hover {
          color: $color_5;
        }
      }
    }
  }
}
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  li {
    float: left;
  }
}
.tag {
  background: #fff;
  border-radius: 4px;
  color: $color_1;
  padding: 6px 12px;
  position: relative;
  margin: 0 10px 15px 0;
  font-size: 16px;
  display: inline-block;
  &:hover {
    background-color: $background-color_2;
    color: $color_6;
  }
}
.tags-widget {
  .card-body {
    padding: 28px 28px 13px;
  }
}
.author-widget {
  margin-bottom: 60px;
  .card-body {
    padding: 30px;
  }
}
.about-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about-author-img {
  flex-shrink: 0;
  margin-right: 30px;
  img {
    width: 84px;
    height: 84px;
    border: 2px solid #fff;
    outline: 7px solid #F4F4F4;
  }
}
.author-details {
  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    color: $color_26;
    margin-bottom: 15px;
  }
}
.new-comment {
  h4 {
    font-size: 24px;
    margin-bottom: 1.5rem;
    font-family: $font-family_4;
  }
  .col-form-label {
    font-size: 16px;
  }
  .submit-section {
    text-align: left;
  }
  .submit-btn {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 30px;
  }
}
.blog-review {
  h4 {
    font-size: 24px;
    margin-bottom: 1.5rem;
    font-family: $font-family_4;
  }
  .review-box {
    padding: 10px 0;
    border: 0;
  }
}
.blog-details {
  .blog-content {
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.test-info {
  font-weight: 500;
  font-size: 16px;
  border-left: 2px solid #4c40ed;
  padding-left: 15px;
}
.blog-head {
  .blog-category {
    ul {
      li {
        font-size: 16px;
        color: $color_1;
        i {
          color: $color_5;
        }
      }
    }
  }
  h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }
}
.social-widget {
  margin-bottom: 33px;
}
.ad-widget {
  margin-bottom: 10px;
  ul {
    li {
      display: inline-block;
      margin: 0 20px 10px 0;
      &:last-child {
        margin-right: 0;
      }
      a {
        background: #F8F8FF;
        border-radius: 5px;
        color: $color_1;
        padding: 6px 12px;
        position: relative;
        display: inline-block;
        &:hover {
          background-color: $background-color_2;
          color: $color_6;
        }
      }
    }
  }
}
.comments-reply {
  li {
    margin-left: 70px;
    .review-box {
      border: 0;
      border-left: 1px solid #eee;
      border-radius: 0;
      padding-left: 20px;
    }
  }
}
.reply-box {
  font-weight: 500;
  font-size: 14px;
  color: $color_2;
}
.reply-com {
  display: none;
}
.work-wrap {
  margin-bottom: 170px;
  position: relative;
  span {
    width: 50px;
    height: 50px;
    background: #4c40ed;
    border-radius: 50%;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    color: $color_6;
    font-family: $font-family_4;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 38px;
    font-family: $font-family_4;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 25px;
    }
  }
}
.work-wrap-acc {
  &:after {
    position: absolute;
    content: "";
    bottom: -94%;
    right: 40px;
    background: url('../img/bg/wrk-bg-01.png');
    width: 314px;
    height: 264px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  padding-right: 150px;
}
.work-wrap-earning {
  padding-right: 150px;
}
.work-wrap-post {
  padding-left: 150px;
  &:after {
    position: absolute;
    content: "";
    bottom: -100%;
    left: 0;
    background: url('../img/bg/wrk-bg-02.png');
    width: 314px;
    height: 264px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.work-img {
  margin-bottom: 170px;
}
.invoice-wrap {
  font-size: 15px;
}
.invoice-head {
  float: right;
  text-align: right;
  margin-bottom: 20px;
  h5 {
    font-family: $font-family_4;
    margin-bottom: 7px;
  }
  ul {
    li {
      i {
        color: $color_32;
      }
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.invoice-logo {
  margin-bottom: 35px;
  img {
    max-width: 220px;
  }
}
.invoice-address {
  margin-bottom: 30px;
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
  }
  ul {
    li {
      margin-bottom: 8px;
    }
  }
}
.invoice-to {
  float: right;
}
.invoice-table {
  margin-bottom: 30px;
  table.table {
    td {
      h2 {
        a {
          font-weight: 400;
          color: $color_26;
        }
      }
    }
  }
  .avatar {
    width: 30px;
    height: 30px;
  }
  .custom-table {
    thead {
      th {
        &:first-child {
          border-radius: 0;
        }
        &:last-child {
          border-radius: 0;
        }
      }
    }
  }
}
.invoice-payment {
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
  }
  ul {
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  margin-bottom: 35px;
}
.invoice-total {
  ul {
    li {
      &:last-child {
        margin-bottom: 0;
      }
      font-weight: 500;
      color: $color_2;
      margin-bottom: 10px;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      span {
        font-weight: 400;
        color: $color_1;
        min-width: 240px;
        display: inline-block;
        text-align: right;
      }
    }
    li.tot-amt {
      span {
        font-weight: 500;
        color: $color_2;
      }
    }
  }
  margin-bottom: 35px;
  float: right;
}
.pay-amt {
  font-weight: 500;
  color: $color_2;
  span {
    font-weight: 400;
    display: inline-block;
    color: $color_1;
    min-width: 124px;
  }
}
.invoice-terms {
  font-size: 15px;
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  padding: 20px;
  h5 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }
  ul {
    li {
      margin-bottom: 15px;
      padding-left: 20px;
      position: relative;
      &:before {
        position: absolute;
        content: '\f111';
        font-family: $font-family_5;
        left: 0;
        top: 7px;
        color: $color_5;
        font-size: 6px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.notification-item {
  display: -ms-flexbox;
  display: flex;
}
.notification-media {
  .avatar {
    margin-right: 10px;
    width: 46px;
    height: 46px;
    > img {
      width: 46px;
      height: 46px;
    }
  }
}
.notification-info {
  h6 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    color: $color_1;
    span {
      font-weight: 500;
      color: $color_2;
    }
  }
  p {
    font-size: 13px;
    color: $color_1;
    margin-bottom: 0;
  }
}
.notification-details {
  .login-back {
    margin-bottom: 28px;
  }
  h4 {
    font-size: 24px;
    font-family: $font-family_4;
    margin-bottom: 1.5rem;
  }
}
.detail-list {
  li {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.notify-head {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  > a {
    font-weight: 500;
    font-size: 16px;
    color: $color_2;
    margin-bottom: 15px;
  }
}
.avatar-icon {
  width: 46px;
  height: 46px;
  background: #fff;
  border: 2px solid #F5F5F5;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}
.notification-dropdown {
  > a {
    padding: 0;
  }
  .dropdown-menu {
    font-size: 14px;
    padding: 0;
  }
}
.notify-btns {
  margin-top: 15px;
  .btn {
    font-weight: 500;
    font-size: 13px;
    padding: 5px 15px;
    border-radius: 4px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.notify-list {
  margin-bottom: 17px;
  li {
    margin: 0 20px 15px 0;
    a {
      padding: 5px 20px 10px;
      position: relative;
      font-weight: 500;
      color: $color_1;
    }
    a.active {
      color: $color_5;
      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: #4c40ed;
        border-radius: 10px;
      }
    }
  }
}
.provider-info {
  padding-left: 60px;
  margin-bottom: 60px;
  background-color: $background-color_24;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 0;
  h2 {
    margin-bottom: 5px;
  }
  h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  p {
    font-size: 14px;
    margin-bottom: 24px;
    color: $color_159;
    font-size: 16px;
    font-weight: 400;
  }
  .provide-info {
    p {
      margin-bottom: 0;
    }
  }
  i {
    margin-right: 5px;
  }
}
.provider-img {
  position: relative;
  img {
    position: relative;
  }
  span {
    position: absolute;
    left: 0;
    bottom: 45px;
    background: #4c40ed;
    font-weight: 700;
    font-size: 22px;
    color: $color_6;
    padding: 20px;
  }
  &:after {
    position: absolute;
    content: "";
    top: -30px;
    left: -30px;
    background: url(../img/bg/pro-bg-02.png) no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &:before {
    position: absolute;
    content: none;
    bottom: -30px;
    right: -50px;
    background: url(../img/bg/pro-bg-01.png) no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.provider-details {
  margin-bottom: 60px;
  margin-left: 100px;
  &:last-child {
    margin-bottom: 0;
  }
  h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    li {
      margin-bottom: 16px;
      padding-left: 24px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        position: absolute;
        content: '\f058';
        font-family: $font-family_5;
        left: 0;
        top: 0;
        color: $color_5;
      }
    }
  }
  p.last-seen {
    margin-bottom: 0px;
    i {
      color: $color_149;
    }
  }
}
.construct-box {
  background: #fff;
  box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
  padding: 25px;
  margin-bottom: 20px;
  img {
    margin-bottom: 19px;
  }
  h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.service-list {
  border: 2px solid #F5F5F5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.service-cont-img {
  position: relative;
  margin-right: 15px;
  flex: 0 0 210px;
  max-width: 210px;
  img {
    border-radius: 8px;
    height: 143px;
    object-fit: cover;
    width: 210px;
  }
  .fav-item {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
.service-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto;
  text-align: left;
  flex: 0 0 calc(100% - 315px);
  overflow: hidden;
}
.service-cont-info {
  overflow: hidden;
  .item-cat {
    display: inline-block;
    margin-bottom: 12px;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    margin-bottom: 10px;
    i {
      color: $color_32;
      margin-right: 7px;
    }
  }
}
.service-pro-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 8px;
  }
  span {
    i {
      color: $color_33;
      margin-right: 5px;
    }
  }
}
.blog-pagination.rev-page {
  .pagination {
    margin-top: 6px;
  }
}
.top-bar {
  background: #3E4265;
  padding: 10px 0;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-wrap: wrap;
  width: 100%;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: $color_6;
    margin: 0 24px 0 0;
  }
  ul {
    li {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 6px;
      font-weight: 600;
      font-size: 12px;
      color: $color_28;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.banner {
  &:before {
    position: absolute;
    content: "";
    top: -6px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2030661f;
  }
}
.banner-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  width: 100%;
  h1 {
    font-weight: 700;
    font-size: 50px;
    color: $color_6;
    text-align: center;
    margin-bottom: 12px;
  }
  p {
    font-weight: 700;
    font-size: 20px;
    color: $color_6;
    text-align: center;
    margin-bottom: 50px;
  }
}
.search-box-two {
  width: 100%;
  background: #fff;
  border-radius: 50px;
  padding: 14px;
  display: table;
  .search-btn {
    width: 15%;
    .btn {
      background: #ff008a;
      border-radius: 52px;
      color: $color_6;
      font-weight: 700;
      font-size: 15px;
      padding: 14px 10px;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #e2017b;
        border: 1px solid #e2017b;
      }
    }
  }
}
.search-input-new {
  width: 32%;
  float: left;
  position: relative;
  input {
    height: 52px;
    padding: 0px 40px 0px 50px;
    border: 0;
    color: $color_108;
    &::placeholder {
      color: $color_108;
    }
  }
  i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: $color_108;
    font-size: 18px;
  }
}
.search-input-new.line {
  width: 53%;
  input {
    height: 52px;
    padding: 0px 40px 0px 50px;
    border: 0;
    color: $color_108;
    &::placeholder {
      color: $color_108;
    }
  }
  &::before {
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 60px;
    background: #E2E6F1;
    content: "";
    z-index: 1;
    margin-top: -30px;
  }
}
.section-heading-two {
  position: relative;
  margin-bottom: 50px;
  &:before {
    position: absolute;
    content: "";
    background: url("../img/bg/title-bg-01.png") no-repeat;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 15px;
    color: $color_3;
  }
}
.section-heading-two.white-text {
  &:after {
    background: -moz-linear-gradient(top, #ff008a 50%, #ff008a 50%);
    background: -webkit-linear-gradient(top, #ff008a 50%, #ff008a 50%);
    background: linear-gradient(to bottom, #ff008a 50%, #ff008a 50%);
  }
  p {
    color: $color_6;
  }
  h2 {
    color: $color_6;
  }
}
.sec-header {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    background: url("../img/bg/title-bg-01.png") no-repeat;
    width: 114px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}
.feature-section-two {
  background: #fff;
  padding: 90px 0 66px;
}
.feature-img {
  overflow: hidden;
  border-radius: 8px;
  img {
    border-radius: 8px;
    width: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  }
  &:hover {
    img {
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      transform: scale(1.15);
    }
  }
}
.feature-title {
  background: #203066;
  border-radius: 0 8px 8px 0;
  padding: 12px 14px;
  width: calc(100% - 50px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  h5 {
    font-weight: 700;
    font-size: 20px;
    color: $color_6;
    margin-bottom: 0;
  }
}
.btn-viewall {
  background: #FCEFF8;
  border-radius: 50px;
  color: $color_109;
  font-weight: 500;
  padding: 10px 20px;
  &:hover {
    background: #ff008a;
    border: 1px solid #ff008a;
    color: $color_6;
  }
  i {
    margin-left: 8px;
  }
}
.service-section-two {
  background: #fff4fa;
  padding: 90px 0 66px;
  .owl-nav {
    .owl-next {
      &:hover {
        background: #ff008a;
      }
    }
    .owl-prev {
      &:hover {
        background: #ff008a;
      }
    }
  }
}
.service-widget.service-two {
  border: 1px solid #E2E6F1;
  padding: 20px;
  .service-img {
    border-radius: 10px;
    .serv-img {
      border-radius: 8px;
    }
  }
  .service-content {
    padding: 20px 0 0;
    p {
      color: $color_1;
      .rate {
        font-size: 15px;
        font-weight: 400;
        color: $color_1;
      }
    }
  }
  .item-cat {
    background: #fff4fa;
    border-radius: 50px;
    color: $color_28;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
  }
  .item-img {
    float: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: $color_6;
    font-weight: 700;
    font-size: 14px;
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 0;
      margin-right: 5px;
    }
  }
  .fav-item {
    padding: 20px 20px 0;
  }
  .item-info {
    padding: 0 20px 20px;
    a {
      display: block;
    }
  }
}
.work-section-two {
  background: #fff;
  padding: 90px 0 66px;
}
.pricing-plans.price-new {
  background: #F3F6FF;
  border: 1px solid #E2E6F1;
  border-radius: 25px;
  padding: 20px;
  margin-top: 24px;
  &:hover {
    .btn {
      background: #ff008a;
    }
  }
  .btn {
    background: #3E4265;
    border-radius: 40px;
    color: $color_6;
    display: block;
    text-align: center;
  }
}
.pricing-plans.price-new.active {
  background: #fff;
  border: 1px solid #E2E5F1;
  box-shadow: 3px 42px 34px rgba(255, 0, 138, 0.08);
  margin-top: 0;
  .btn {
    background: #ff008a;
    color: $color_6;
  }
}
.price-block {
  text-align: right;
}
.app-section-two {
  padding: 0 0 90px;
  background: url(../img/bg/off-bg.png) no-repeat;
  background-position: center right;
}
.blog.blog-new {
  border: 1px solid #E2E6F1;
  .blog-image {
    position: relative;
    .date {
      background: #fff;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 13px;
      text-align: center;
      position: absolute;
      top: 20px;
      left: 20px;
      min-width: 55px;
      min-height: 55px;
      color: $color_28;
      box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
      span {
        display: block;
      }
    }
  }
  .blog-content {
    .blog-item {
      background: #3E4265;
      border-radius: 5px;
      padding: 4px 7px;
      margin-bottom: 10px;
      li {
        margin: 0 10px 0 0;
        font-size: 14px;
        color: $color_6;
        i {
          color: $color_6;
        }
        &:last-child {
          margin-right: 0;
        }
        + {
          li {
            border-left: 1px solid rgba(255, 255, 255, 0.4);
            padding-left: 10px;
          }
        }
        a {
          color: $color_6;
        }
      }
    }
    .blog-title {
      -webkit-line-clamp: 2;
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.client-name {
  h5 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
  }
  h6 {
    font-weight: 400;
    font-size: 14px;
    color: $color_1;
    margin-bottom: 0;
  }
}
.client-section-two {
  background: #fff4fa;
  padding: 90px 0;
}
.owl-dots {
  text-align: center;
}
.offer-sec.offer-paths {
  background: #ff008a;
  border-radius: 30px;
  padding: 0 80px;
  margin: 0;
  align-items: flex-end;
  &::after {
    position: absolute;
    content: "";
    background: url(../img/bg/offer-bg.png) no-repeat 0 0 / 100%;
    left: 0;
    top: 0;
    background-size: contain;
    background-position: top right;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 30px;
  }
  .offer-pathimg {
    margin: 0;
  }
}
.offer-sec {
  .section-heading-two {
    margin-bottom: 20px;
    &:after {
      background: -moz-linear-gradient(top, #fff 50%, #203066 50%);
      background: -webkit-linear-gradient(top, #fff 50%, #203066 50%);
      background: linear-gradient(to bottom, #fff 50%, #203066 50%);
    }
    p {
      color: $color_6;
    }
  }
  .btn-views {
    border-radius: 40px;
    color: $color_28;
    &:hover {
      background-color: $background-color_25;
      box-shadow: inset 0 70px 0 0 #ff008a;
      color: $color_6;
    }
  }
  .offer-path-content {
    padding: 93px 0;
  }
  .offer-path-content.service-path-content {
    padding-left: 50px;
  }
}
.download-sec {
  padding-left: 60px;
  p {
    color: $color_108;
  }
  h6 {
    color: $color_102;
    margin-bottom: 20px;
  }
}
.providers-section-two {
  position: relative;
  padding: 90px 0 66px;
  &:before {
    content: "";
    background: #3E4265;
    width: 100%;
    min-height: 396px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
.provider-box {
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 8px;
  border: 0;
  margin-top: 85px;
  .providerset-img {
    text-align: center;
    margin: -105px 0 10px;
    img {
      width: 180px;
      height: 180px;
      border: 10px solid #fff;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .providerset-name {
    h4 {
      font-weight: 700;
    }
  }
  .provider-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rate {
    background: #fff4fa;
    border-radius: 50px;
    font-size: 15px;
    color: $color_108;
    padding: 5px 12px;
    display: inline-block;
    i {
      color: $color_33;
      margin-right: 6px;
    }
  }
  .btn {
    font-weight: 500;
    font-size: 15px;
    color: $color_6;
    background: #ff008a;
    border: 1px solid #ff008a;
    border-radius: 30px;
    padding: 5px 15px;
    &:hover {
      background: #e2017b;
      border: 1px solid #e2017b;
    }
  }
}
.section-offer {
  position: relative;
  padding-bottom: 60px;
  &:before {
    content: "";
    background: #fff4fa;
    width: 100%;
    min-height: 300px;
    position: absolute;
    top: 50%;
    z-index: -1;
  }
  .offer-sec {
    .btn-views {
      font-weight: 700;
    }
  }
}
.section-section-three {
  h4 {
    color: $color_113;
    font-size: 18px;
  }
  h1 {
    color: $color_6;
  }
  p {
    color: $color_114;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.services-section {
  position: relative;
  z-index: 1;
  padding: 130px 0 85px;
  background-color: $background-color_53;
  .search-box {
    background: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 20px;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 20px;
    margin-top: 20px;
  }
  .owl-nav {
    .owl-prev {
      background: none !important;
      box-shadow: none;
      &:hover {
        background: none !important;
        color: $color_117;
      }
    }
    .owl-next {
      margin-bottom: 0;
    }
    .owl-prev {
      margin-bottom: 0;
    }
    .owl-next {
      &:hover {
        background: none !important;
        color: $color_117;
      }
      background: none !important;
      box-shadow: none;
    }
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
  }
}
.services-all {
  position: relative;
}
.services-main-img {
  position: relative;
  &::before {
    background: linear-gradient(0deg, rgba(13, 21, 49, 0.6), rgba(13, 21, 49, 0.6));
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    z-index: 0;
  }
  &:hover {
    &::before {
      background: #ff008099;
      border-radius: 12px;
      transform: scale(0);
      transition: all 0.5s;
      transform: scale(1);
    }
  }
}
.section-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  p {
    max-width: 510px;
    text-align: center;
  }
  h2 {
    font-size: 40px;
    color: $color_103;
  }
}
.our-img-all {
  position: relative;
  margin-bottom: 10px;
  &::after {
    width: 33px;
    height: 1px;
    position: absolute;
    content: '';
    background-color: $background-color_54;
    top: 13px;
    left: 30px;
  }
  &::before {
    width: 33px;
    height: 1px;
    position: absolute;
    content: '';
    background-color: $background-color_54;
    top: 13px;
    right: 30px;
  }
}
.search-box.search-box-three {
  padding: 18px;
}
.search-box-three {
  justify-content: space-between;
  margin-bottom: -50px;
}
.blog-section-three {
  .blog {
    padding: 0;
  }
  .blog-content {
    padding: 20px;
  }
  .blog-view {
    a {
      color: $color_117;
    }
  }
  .cat-blog {
    background: #fff;
    color: $color_118;
    padding: 2px 10px;
    border: 1px solid #898989;
  }
  .blog.blog-new {
    .blog-image {
      .date {
        background-color: $background-color_52;
        color: $color_6;
        font-weight: bold;
        font-size: 16px;
        span {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }
}
.service-img-top {
  a {
    position: relative;
    &::before {
      background: #0D153199;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}
.service-show-img {
  position: relative;
  &::before {
    content: "";
    background-color: $background-color_55;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: 1;
  }
}
.saloon-right {
  span {
    font-size: 24px;
    color: $color_117;
    font-weight: 600;
  }
}
.saloon-bottom {
  display: flex;
  a {
    padding: 10px;
    background-color: $background-color_52;
    border: 1px solid #ff0080;
    border-radius: 5px;
    color: $color_6;
    width: 100%;
    text-align: center;
    font-weight: 500;
    &:hover {
      background-color: $background-color_19;
      border: 1px solid #2229c1;
      box-shadow: inset 0 70px 0 0 #2229c1;
      color: $color_6;
      transition: all 0.7s;
    }
  }
}
.saloon-section-circle {
  position: absolute;
  left: -127px;
  bottom: -146px;
  z-index: 0;
}
.works-section {
  padding: 90px 0;
}
.works-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.works-top-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
  &::before {
    content: "";
    background-color: $background-color_57;
    position: absolute;
    border-radius: 75% 25% 50% 50% / 52% 47% 53% 48%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    transition: all 0.7s;
  }
  &:hover {
    &::before {
      background-color: $background-color_58;
      transition: all 0.7s;
    }
  }
  img {
    border-radius: 75% 25% 50% 50% / 52% 47% 53% 48%;
    width: 230px;
    height: 218px;
  }
  span {
    position: absolute;
    color: $color_6;
    font-size: 28px;
    font-weight: 700;
    border: 5px solid #fff;
    border-radius: 50%;
    width: 71px;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
}
.works-bottom {
  text-align: center;
  p {
    max-width: 300px;
    margin-bottom: 0;
  }
  h2 {
    font-size: 28px;
  }
}
.works-inner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 9;
  background: #ff008099;
  transform: scale(0);
  transition: all 0.5s;
  border-radius: 75% 25% 50% 50% / 52% 47% 53% 48%;
}
.section-client {
  h2 {
    color: $color_6;
    z-index: 1;
  }
  p {
    color: $color_114;
    z-index: 1;
  }
  .our-img-all {
    &::before {
      background: #fff;
    }
    &::after {
      background: #fff;
    }
  }
}
.register-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 69px 0px;
  h2 {
    font-weight: 600;
    font-size: 40px;
    color: $color_6;
    z-index: 1;
    max-width: 767px;
    margin-bottom: 0;
  }
  ul {
    z-index: 1;
  }
  .header-navbar-rht {
    li {
      > a.header-login {
        background: #ff008a;
        border: 1px solid #ff008a;
        box-shadow: inset 0 70px 0 0 #ff008a;
      }
    }
  }
}
.bar-icon.bar-icon-three {
  span {
    background-color: $background-color_52;
  }
}
.copyright-menu {
  float: right;
  .footer-menu {
    li {
      a {
        &:hover {
          color: $color_117;
        }
      }
    }
  }
}
.catering-banner-section {
  background-color: $background-color_60;
}
.search-box.search-box-four {
  .search-btn {
    .btn {
      padding: 13px 50px;
      border-radius: 105px;
      font-size: 18px;
    }
  }
  border-radius: 90px;
  margin: 0 0 30px 0;
  box-shadow: none;
  padding: 10px 15px;
  padding-left: 30px;
  i {
    color: $color_115;
  }
  .btn {
    i {
      color: $color_6;
    }
  }
}
.section-search.section-search-four {
  max-width: 633px;
  span {
    color: $color_106;
    border: none;
  }
  p {
    margin-bottom: 30px;
  }
}
.service-content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  h6 {
    color: $color_106;
    margin: 0;
    font-weight: 600;
  }
  span {
    display: flex;
    display: -webkit-flex;
    padding: 10px;
    border-radius: 50%;
    background-color: $background-color_61;
    color: $color_6;
  }
}
.catering-main-bottom {
  .title {
    margin-bottom: 0;
    a {
      color: $color_107;
    }
  }
  .rating {
    font-size: 14px;
    margin-bottom: 7px;
  }
  span {
    font-size: 14px;
  }
}
.categories-section {
  padding: 80px 0;
  position: relative;
  background-color: $background-color_60;
}
.categories-main-all {
  padding: 30px 31px;
  border-radius: 10px;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
  background: #fff;
  margin-bottom: 27px;
  h6 {
    text-align: center;
    color: $color_108;
    max-width: 115px;
  }
  .category-bottom {
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    width: 24px;
    height: 24px;
    background-color: $background-color_60;
    color: $color_6;
    border-radius: 50px;
  }
  &:hover {
    .categories-img {
      span {
        background-color: $background-color_61;
        border: #4C40ED;
        transition: 0.7s;
        img {
          filter: invert(1) brightness(2);
          transform: rotateY(360deg);
          -webkit-transform: rotateY(360deg);
          -ms-transform: rotateY(360deg);
          -webkit-transition: 0.4s;
          -ms-transition: 0.4s;
          transition: 0.4s;
        }
      }
    }
    .category-bottom {
      background-color: $background-color_61;
      color: $color_6;
      transition: 0.7s;
    }
  }
}
.categories-img {
  span {
    width: 100px;
    height: 100px;
    background-color: $background-color_60;
    border: #F3F3F3;
    border-radius: 10px;
    margin-bottom: 25px;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-transition: 0.4s;
    -ms-transition: 0.4s;
    transition: 0.4s;
    img {
      -webkit-transition: 0.4s;
      -ms-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
.section-heading.section-heading-four {
  .btn-view {
    border-radius: 95px;
    padding: 15px 27px;
  }
}
.category-feature-bottom {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  background-color: $background-color_60;
  padding: 10px;
  border-radius: 5px;
  p {
    margin-bottom: 0;
    color: $color_104;
  }
  a {
    font-size: 14px;
    font-weight: 700;
    color: $color_106;
    &:hover {
      color: $color_121;
    }
  }
}
.trust-us-section {
  position: relative;
  padding: 80px 0;
  background-color: $background-color_53;
  z-index: 1;
  &::before {
    background: url(../img/trust-bg.png) no-repeat;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-position: left;
  }
}
.trust-us-main {
  padding: 40px;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
  background-color: $background-color_1;
  box-shadow: 0px 4px 24px rgba(205, 205, 205, 0.25);
  h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
    margin-bottom: 0;
  }
  &:hover {
    .trust-us-img {
      background-color: $background-color_61;
      transition: 0.7s;
      color: $color_6;
      padding: 12px 18px;
      border-radius: 10px;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -ms-transition: 0.4s;
      i {
        transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -webkit-transition: 0.4s;
        -ms-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
  .trust-us-img {
    i {
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -ms-transition: 0.4s;
    }
  }
}
.trust-us-img {
  display: -webkit-flex;
  display: flex;
  font-size: 56px;
  color: $color_106;
  margin-bottom: 25px;
  padding: 12px 18px;
}
.review-client-section {
  position: relative;
  padding: 80px 0;
  background-color: $background-color_53;
}
.client-review-img {
  margin-bottom: 55px;
  float: left;
}
.header-button {
  color: $color_6;
}
.pagination-main-class {
  display: flex;
  justify-content: space-between;
}
.table_footer {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1.5rem;
}
.dataTables_length {
  margin-top: 15px !important;
  float: left;
  font-size: 12px;
}
.table_footer .dataTables_length label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
}
.dataTables_length label:before {
  margin-right: 5px;
}
.table_design {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dataTables_info {
  float: right;
  padding-right: 15px;
  padding-top: 18px !important;
  font-size: 12px;
  color: #5E5873;
  font-weight: 600;
}
.table_design .pagination_section {
  display: flex;
  justify-content: flex-end;
}
.pagination {
  margin-top: 15px;
}
.table_footer .dataTables_length select {
  width: auto;
  display: inline-block;
  margin: 0px 3px;
  -webkit-appearance: none;
  appearance: auto;
  border-radius: 0.375rem;
}
.search-group-icon-5 {
  color: $color_28;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 0, 138, 0.05);
}
.search-box.search-box-five {
  padding: 12px;
  margin-top: 0;
  .search-input {
    width: 50%;
    float: none;
    .form-group {
      width: calc(100% - 40px);
    }
  }
  .select2-container--default {
    .select2-selection--single {
      border: none;
      .select2-selection__rendered {
        color: $color_122;
        font-weight: 400;
        padding-left: 0;
      }
    }
  }
  .search-box-form {
    display: flex;
  }
}
.floating-img {
  position: absolute;
  bottom: -75px;
  z-index: 1;
}
.car-five-arrow-img {
  position: relative;
  img {
    position: absolute;
    content: '';
    top: -205px;
    left: 115px;
  }
}
.car-wash-feature {
  margin-top: 35px;
}
.featured-categories-section {
  padding: 140px 0 90px 0;
  position: relative;
  background-color: $background-color_66;
}
.featured-category-bg-img {
  img {
    position: absolute;
    left: 0;
    top: 170px;
    z-index: 0;
  }
}
.feature-services-five {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  h6 {
    margin-bottom: 0;
    font-size: 24px;
    color: $color_102;
    margin-right: 8px;
  }
  span {
    text-decoration: line-through;
  }
}
.feature-category-section {
  padding: 90px 0;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    background: url(../img/bubbles-bottom-section.png) no-repeat 0 0 / 100%;
    left: 0;
    top: 163px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
.feature-category-section-five {
  position: relative;
  padding: 90px 0;
  background-color: $background-color_66;
  &::before {
    position: absolute;
    content: "";
    background: url(../img/car-blog-ng.jpg) no-repeat 0 0 / 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
  }
}
.top-providers-five {
  .providerset {
    .providerset-img {
      a {
        img {
          border-radius: 10px;
        }
      }
    }
    padding: 0;
    margin-bottom: 50px;
    .providerset-content {
      padding: 0 18px 18px 18px;
      text-align: center;
      h4 {
        margin-bottom: 10px;
        a {
          font-size: 20px;
          margin-right: 4px;
        }
        i {
          font-size: 18px;
          color: $color_9;
        }
      }
      h5 {
        color: $color_122;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 400;
      }
      .providerset-prices {
        margin-bottom: 20px;
      }
    }
  }
  .owl-nav {
    text-align: center;
    .owl-prev {
      background: #ff008a !important;
      color: $color_6;
      margin-bottom: 0;
      &:hover {
        background: #fff !important;
        color: $color_28;
      }
    }
    .owl-next {
      background: #ff008a !important;
      color: $color_6;
      margin-bottom: 0;
      &:hover {
        background: #fff !important;
        color: $color_28;
      }
    }
  }
}
.providers-section-five {
  position: relative;
  padding: 90px 0;
  background: #F3F6FC;
  .providerset {
    border: 1px solid #fff;
  }
}
.providers-five-bg-car {
  img {
    position: absolute;
    content: "";
    right: 0;
    bottom: 55px;
    z-index: 0;
  }
}
.works-five-section {
  position: relative;
  padding: 90px 0;
  &::before {
    position: absolute;
    content: "";
    background: url(../img/bubbles-bottom-section.png) no-repeat 0 0 / 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.car-wash-img-five {
  display: flex;
  justify-content: center;
  align-items: center;
}
.works-it-five-button {
  text-align: center;
  h4 {
    font-size: 40px;
    color: $color_124;
    margin-bottom: 15px;
  }
}
.works-it-dots {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: $background-color_65;
    border-radius: 50%;
    margin-bottom: 55px;
    &::before {
      width: 38px;
      height: 38px;
      background-color: $background-color_67;
      border-radius: 50%;
      position: absolute;
      content: '';
      top: -11px;
      left: -11px;
      z-index: -1;
    }
  }
}
.works-it-lines {
  span {
    position: relative;
    &::before {
      width: 370px;
      height: 1px;
      background-color: $background-color_65;
      position: absolute;
      content: '';
      top: -62px;
      left: 241px;
      z-index: -1;
    }
  }
}
.works-five-main {
  border: 1px solid #F3F6FC;
  border-radius: 8px;
  margin-bottom: 160px;
  &:hover {
    box-shadow: 0px 10px 20px -5px rgb(76 64 237 / 8%);
  }
}
.works-five-img {
  img {
    width: 100%;
  }
}
.offering-five-all {
  position: relative;
  background-color: $background-color_65;
  border-radius: 20px;
  z-index: 1;
}
.offering-five-all-img {
  img {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    z-index: -1;
  }
}
.offering-five-main {
  h2 {
    font-size: 40px;
    color: $color_6;
    margin-bottom: 25px;
  }
  p {
    color: $color_6;
  }
  padding: 50px 29px 50px 50px;
}
.offering-five-img {
  margin: -86px 0 0 -60px;
}
.works-five-bottom {
  text-align: center;
  padding: 20px;
  i {
    display: inline-flex;
    width: 54px;
    height: 54px;
    background: #ff008a;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: $color_6;
    font-size: 18px;
  }
}
.car-testimonials-main-five {
  background-color: $background-color_25;
  border-radius: 50px;
}
.car-wash-bg-five {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    background: #ff008a;
    width: 100%;
    height: 483px;
    border-radius: 50px;
    bottom: -60px;
  }
}
.blog-section.blog-five-section {
  padding: 90px 0 80px 0;
}
.car-blogs-section {
  position: relative;
  padding: 90px 0 0 0;
  background-color: $background-color_66;
  &::before {
    position: absolute;
    content: "";
    background: url(../img/car-blog-ng.jpg) no-repeat 0 0 / 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
  }
}
.pricing-service-five {
  position: relative;
  padding: 90px 0;
}
.item-cat.item-car {
  background-color: $background-color_68;
  color: $color_6;
  &:hover {
    background-color: $background-color_1;
    color: $color_125;
  }
}
.section-heading.car-wash-heading {
  position: relative;
  h2 {
    position: relative;
  }
  .car-wash-header-one {
    margin-right: 8px;
  }
  .car-wash-header-two {
    margin-left: 8px;
  }
}
.app-five-section {
  background-color: $background-color_5;
  padding: 117px 0 160px 0;
}
.footer.footer-five {
  .footer-widget.footer-menu {
    ul {
      li {
        a {
          &:hover {
            color: $color_28;
          }
          color: $color_6;
        }
      }
    }
    .footer-title {
      color: $color_6;
    }
  }
  .footer-bottom {
    .copyright {
      border-top: 1px solid rgba(255, 255, 255, 0.07);
      .payment-image {
        ul {
          justify-content: start;
        }
      }
      .copyright-text {
        p {
          text-align: center;
          color: $color_6;
        }
      }
      .copyright-menu {
        .policy-menu {
          li {
            a {
              color: $color_6;
            }
          }
        }
      }
    }
  }
  background-color: $background-color_65;
  .footer-top {
    .footer-widget {
      .footer-content {
        p {
          color: $color_6;
        }
      }
    }
  }
  .footer-widget.footer-contact {
    .footer-title {
      color: $color_6;
    }
    .footer-contact-info {
      p {
        color: $color_6;
      }
      span {
        color: $color_28;
      }
    }
  }
  .footer-widget {
    .footer-title {
      color: $color_6;
    }
  }
  .social-icon {
    ul {
      li {
        a {
          &:hover {
            background-color: $background-color_25;
          }
        }
      }
    }
  }
}
.home-banner-main {
  position: relative;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home-banner-six-bg {
  img {
    position: absolute;
    content: '';
    z-index: -1;
    top: -125px;
    left: -40px;
  }
}
.home-banner-six-detergent {
  justify-content: start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  h5 {
    margin-bottom: 0;
    color: $color_102;
  }
}
.search-box-two.search-box-six {
  padding: 10px;
  width: 93%;
  .search-input-new.line-six {
    width: 45%;
  }
  .search-input-new.line-two-six {
    width: 35%;
  }
  .search-btn {
    width: 20%;
    .btn {
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        color: $color_1;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
.search-input-new.line-two-six {
  input {
    padding: 0px 40px 0px 10px;
    height: 40px;
    min-height: 40px;
  }
  i {
    color: $color_28;
    right: 12px;
  }
  .form-group {
    input {
      &::placeholder {
        color: $color_1;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
.search-input-new.line-six {
  .select2-container--default {
    .select2-selection--single {
      border: 0;
      height: 40px;
      .select2-selection__arrow {
        b {
          border-color: $border-color_21;
        }
      }
    }
  }
}
.home-banner-six-bottom {
  padding-top: 85px;
  h1 {
    color: $color_102;
    max-width: 470px;
    margin-bottom: 23px;
    span {
      color: $color_28;
    }
  }
  p {
    color: $color_1;
    max-width: 375px;
    margin-bottom: 50px;
  }
}
.banner-six-side-img {
  position: relative;
  img {
    position: absolute;
    bottom: -33px;
    right: 0;
  }
}
.section-heading.section-heading-six {
  h2 {
    color: $color_102;
    margin-bottom: 0;
    span {
      color: $color_28;
    }
  }
}
.our-company-ryt {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
}
.our-company-bg {
  img {
    position: absolute;
    top: -160px;
    z-index: -1;
    left: -10px;
  }
}
.our-company-img {
  margin-top: -160px;
}
.about-our-company {
  position: relative;
  background: url(../img/oru-company-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
}
.our-company-six {
  p {
    color: $color_1;
    font-weight: 400;
    max-width: 559px;
    line-height: 30px;
    margin-bottom: 40px;
  }
}
.video-home {
  video {
    width: 100%;
  }
}
.satisfied-service-section {
  position: relative;
  padding: 80px 0 45px 0;
}
.blogs-service-section {
  position: relative;
  padding: 80px 0 56px 0;
}
.service-widget.service-six {
  padding: 20px;
  background: #F8F8F8;
}
.customer-reviews-six-section {
  position: relative;
  padding: 80px 0;
}
.customer-review-main-six {
  background: #F8F8F8;
  border-radius: 10px;
  position: relative;
  margin-bottom: 24px;
}
.customer-review-top {
  img {
    width: 97px;
    height: 97px;
    border-radius: 50%;
    margin-bottom: 30px;
  }
  position: relative;
  padding: 30px 56px;
  text-align: center;
  border-bottom: 2px solid #E7E7E7;
  &::before {
    position: absolute;
    content: '';
    bottom: -2px;
    left: 50%;
    width: 54px;
    height: 2px;
    background: #ff008a;
    transform: translate(-50%, -50%);
  }
  p {
    color: $color_1;
    margin-bottom: 0;
  }
}
.customer-review-bottom {
  padding: 28px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .rating {
    margin-bottom: 0;
  }
}
.hidden-charge-section {
  position: relative;
  background: rgba(255, 0, 138, 0.02);
  padding: 80px 0;
}
.hidden-charge-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: #fff;
  border: 1px solid #E7E7E7;
  border-radius: 10px;
  margin-bottom: 24px;
  h4 {
    color: $color_102;
    margin-bottom: 30px;
  }
  ul {
    text-align: center;
    height: 213px;
    width: 100%;
    margin-bottom: 30px;
    li {
      line-height: 30px;
      color: $color_1;
    }
  }
}
.hidden-charge-main.hidden-charge-main-two {
  border: 1px solid #ff008a;
  box-shadow: 10px 24px 17px rgba(255, 0, 138, 0.04);
}
.hidden-charge-img {
  margin-bottom: 30px;
}
.app-six-section {
  position: relative;
  padding: 80px 0;
}
.app-sec.app-sec-six {
  background: #203066;
  border-radius: 10px;
  padding: 0;
}
.app-sec-main {
  position: relative;
  background: url(../img/new-app-bg.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50% 100%;
  border-radius: 10px;
}
.app-img-sec {
  img {
    position: absolute;
    top: 80px;
    left: 60px;
    z-index: -1;
  }
}
.new-app-six-bottom {
  display: flex;
}
.new-app-six-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 27px;
}
.new-app-six-middle {
  padding-left: 50px;
  h2 {
    font-size: 50px;
    max-width: 340px;
    span {
      font-size: 40px;
      font-weight: 600;
    }
  }
  p {
    max-width: 450px;
  }
}
.new-six-img {
  margin-bottom: 15px;
}
.hero-section-seven {
  background: #FFF6FB;
}
.hero-banner-ryt {
  position: relative;
}
.feature-icon.feature-icon-seven {
  span {
    width: 113px;
    height: 113px;
    background: #F9F5FF;
  }
}
.service-section-seven {
  padding: 80px 0;
}
.service-widget.service-two.service-seven {
  .item-cat {
    background: rgba(255, 255, 255, 0.8);
  }
  .fav-item {
    .fav-icon {
      &:hover {
        background: #4c40ed;
      }
    }
    padding: 10px 10px 0;
  }
  .item-info {
    padding: 0 10px 10px;
  }
  .item-img {
    img {
      width: 34px;
      height: 34px;
    }
  }
  .service-content {
    p {
      .rate {
        color: $color_132;
      }
      color: $color_132;
    }
  }
  .serv-info {
    h6 {
      color: $color_102;
    }
  }
}
.work-section-seven {
  padding: 80px 0;
}
.work-icon-seven {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding: 45px 43px;
    border: 2px solid #DEDEDE;
    border-radius: 10px;
    background: #fff;
    margin-bottom: 25px;
  }
  h1 {
    position: absolute;
    top: 22%;
    left: -30%;
    font-size: 80px;
    color: $color_133;
  }
}
.providers-section-seven {
  padding: 80px 0;
}
.app-sec.app-sec-seven {
  background: #F8FCFD;
  border-radius: 50px;
  padding: 0;
}
.app-seven-section {
  padding: 80px 0;
}
.service-widget.service-seven-blog {
  padding: 15px;
  margin-bottom: 40px;
}
.service-bottom-seven {
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
  h6 {
    font-size: 12px;
    padding: 6px 14px;
    background: #fff4fa;
    border-radius: 50px;
    color: $color_108;
    margin-bottom: 0;
  }
}
.service-bottom-icons {
  i {
    color: $color_102;
    font-size: 14px;
  }
  span {
    color: $color_136;
    font-size: 14px;
  }
}
.testimonials-seven-img {
  img {
    width: 60px !important;
    height: 60px;
    border-radius: 50%;
  }
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonials-main-ryt {
  background: #fff;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
}
.footer.footer-seven {
  background: #252525;
  .footer-title {
    color: $color_6;
  }
  .footer-menu {
    ul {
      li {
        a {
          color: $color_137;
        }
      }
    }
  }
  .social-icon {
    ul {
      li {
        a {
          background: none;
          color: $color_28;
        }
      }
      margin-bottom: 0;
    }
  }
  .footer-widget {
    .footer-six-main {
    }
  }
  .footer-bottom.footer-bottom-seven {
    .copyright {
      border-top: 1px solid #454545;
    }
  }
}
.footer-bottom.footer-bottom-seven {
  .copyright {
    .copyright-text {
      p {
        color: $color_6;
        margin-bottom: 0;
      }
    }
  }
}
.home-banner-eight {
  min-height: 625px;
  .section-search {
    max-width: none;
    text-align: center;
    padding-top: 67px;
    h1 {
      position: relative;
      span {
        color: $color_28;
      }
    }
    p {
      margin-bottom: 30px;
    }
  }
}
.home-eight-bg {
  img {
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 0;
  }
}
.home-eight-dog {
  position: relative;
}
.home-eight-bg-two {
  img {
    position: absolute;
    bottom: 127px;
    left: 100px;
    z-index: 0;
  }
}
.home-banner-eight-icon {
  margin-bottom: 32px;
  ul {
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    li {
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-right: 30px;
      i {
        font-size: 20px;
        margin-right: 7px;
        color: $color_28;
      }
    }
  }
}
.hero-section-eight {
  background: #F9F9F9;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
}
.category-eight-main {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
  span {
    padding: 5px 10px;
    background: rgba(42, 57, 109, 0.05);
    border-radius: 10px;
    font-size: 14px;
  }
  &:hover {
    h6 {
      color: $color_117;
    }
    .category-eight-img {
      &::before {
        opacity: 1;
        transform: scale(1);
        transition: 0.5s all;
      }
    }
    span {
      background: rgba(255, 0, 128, 0.05);
      color: $color_117;
    }
  }
}
.category-eight-img {
  img {
    border-radius: 10px;
  }
  margin-bottom: 15px;
  position: relative;
  transition: 0.5s all;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 0, 128, 0.7), rgba(255, 0, 128, 0.7));
    opacity: 0;
    transform: scale(0);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s all;
    border-radius: 10px;
  }
}
.shop-content-logo {
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 17px;
  }
}
.service-feature-eight {
  margin-top: -40px;
  position: relative;
  z-index: 1;
}
.service-content.service-feature-eight {
  p {
    i {
      color: $color_104;
    }
  }
}
.feature-services-eight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rate {
    display: flex;
    align-items: center;
    h6 {
      margin-bottom: 0;
      font-size: 14px;
      color: $color_141;
      font-weight: 400;
    }
  }
}
.rate-icon {
  background: #FFD43B;
  border-radius: 5px;
  padding: 2px 10px;
  color: $color_6;
  font-size: 14px;
  margin-right: 3px;
  i {
    margin-right: 2px;
  }
}
.works-eight-section {
  padding: 80px 0;
}
.about-us-eight-section {
  padding: 80px 0;
  background: #F9F9F9;
}
.about-eight-main {
  position: relative;
}
.professional-eight-img {
  position: relative;
}
.professional-eight-bg {
  img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.owl-carousel {
  .professional-eight-img-ryt {
    img {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      margin-bottom: 20px;
    }
  }
}
.professional-eight-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  h6 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .rating {
    font-size: 14px;
  }
}
.professional-eight-img-ryt {
  position: relative;
  &:hover {
    transform: scale(1);
  }
}
.blog-eight-section {
  padding: 80px 0;
  background: #F9F9F9;
}
.service-content.service-eight-blogs {
  p {
    white-space: normal;
  }
}
.healthy-eight-section {
  padding: 80px 0;
}
.healthy-pets-img {
  position: relative;
}
.healthy-eight-bg {
  img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.healthy-eight-bg-two {
  img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
.footer.footer-eight {
  background: #F9F9F9;
  .footer-top {
    padding: 80px 0 70px 0;
  }
  .footer-widget {
    .footer-logo {
      margin-bottom: 20px;
    }
  }
  .social-icon {
    ul {
      margin: 0 0 0px;
    }
  }
  .footer-contact-info {
    span {
      color: $color_117;
    }
  }
  .footer-bottom {
    .copyright {
      border-top: 1px solid rgba(156, 156, 156, 0.5);
    }
  }
}
.footer-menu-eight {
  ul {
    li {
      img {
        margin-right: 10px;
      }
    }
  }
}
.app-eight-bottom {
  display: flex;
}
.footer-bottom-eight {
  .copyright {
    .copyright-text {
      p {
        margin-bottom: 0;
      }
    }
  }
}
.hero-section-nine {
  background: rgba(32, 48, 102, 0.05);
  min-height: 700px;
}
.home-banner.home-banner-nine {
  min-height: 620px;
}
.section-search-nine {
  h6 {
    color: $color_28;
  }
  p {
    margin-bottom: 20px;
  }
}
.arrow-ryt-all {
  position: relative;
}
.arrow-ryt {
  img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.safety-section {
  padding: 80px 0;
}
.service-nine-section {
  padding: 80px 0;
  background: #FFF9F9;
}
.service-feature-nine {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  margin-top: -58px;
  z-index: 1;
  position: relative;
  .shop-content-logo {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: 60px;
    height: 60px;
    background: #203066;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
    border-radius: 41px;
    margin-bottom: 20px;
    img {
      margin-bottom: 0;
      border-radius: 0;
      width: auto;
      height: auto;
    }
  }
  span {
    color: $color_28;
    text-transform: capitalize;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 10px;
  }
  p {
    white-space: normal;
    text-align: center;
    color: $color_2;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 18px;
  }
}
.feature-service-nine {
  padding: 80px 0;
}
.service-bottom-nine {
  .btn-primary {
    padding: 8px 16px;
    background-color: $background-color_1;
    border: 1px solid #203066;
    border-radius: 0;
    color: $color_102;
    width: 100%;
    text-align: center;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
    &:hover {
      background: #ff008a;
      border: 1px solid #ff008a;
      box-shadow: inset 0 70px 0 0 #ff008a;
      color: $color_6;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      -o-transition: all 0.7s;
      transition: all 0.7s;
    }
  }
}
.journey-nine-section {
  position: relative;
  padding: 60px 0;
  background: url(../img/journey-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  background-size: cover;
  &::before {
    content: "";
    background: rgba(32, 48, 102, 0.83);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -6;
  }
}
.journey-client-main {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  text-align: center;
  h2 {
    color: $color_6;
    font-size: 48px;
    margin-bottom: 0;
  }
  span {
    color: $color_6;
    font-size: 30px;
    font-weight: 600;
  }
  h5 {
    font-weight: 400;
    color: $color_6;
    margin-bottom: 0;
  }
}
.choose-nine-section {
  padding: 80px 0 56px 0;
  background: #FFF9F9;
}
.delivery-all {
  background: #1C1C1C;
}
.customer-review-section {
  padding: 80px 0;
  background: #FFF9F9;
}
.customer-reviews-all {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 724px;
  margin: auto;
  text-align: center;
  span {
    margin-bottom: 20px;
  }
}
.customer-reviews-main {
  position: relative;
  margin-bottom: 20px;
  img {
    border-radius: 50px 50px 50px 0;
  }
}
.customer-side-main-all {
  position: relative;
}
.customer-side-right-img {
  img {
    position: absolute;
    content: '';
    right: 8%;
    top: 150px;
  }
}
.customer-review-slider {
  .owl-dots {
  }
}
.blogs-nine-section {
  padding: 80px 0;
  background: #FFF9F9;
  .blog {
    border-radius: 0;
    padding: 0;
  }
  .blog.blog-new {
    .blog-image {
      border-radius: 0;
      img {
        border-radius: 0;
      }
      .date {
        background-color: $background-color_52;
        color: $color_6;
      }
    }
    .blog-content {
      p {
        margin-bottom: 1rem;
      }
    }
  }
  .blog-content {
    padding: 20px;
  }
}
.service-blog-nine {
  h5 {
    color: $color_102;
    font-size: 18px;
  }
  p {
    white-space: normal;
  }
}
.free-service-all {
  background: #203066;
  z-index: 1;
  position: relative;
  margin-top: 80px;
}
.free-service-img-one {
  img {
    border-radius: 50px 50px 0 50px;
    margin-right: 10px;
  }
}
.free-service-img-two {
  img {
    margin-top: -60px;
    border-radius: 50px 50px 50px 0;
  }
}
.free-service-bottom-content {
  padding: 76px 58px 76px 0;
  p {
    color: $color_138;
  }
  .btn-primary {
    padding: 8px 16px;
    background: #ff008a;
    border: 1px solid #ff008a;
    font-weight: 600;
    &:hover {
      border: 1px solid #2229C1;
      transition: 0.7s;
    }
  }
}
.footer-nine {
  .footer-top {
    background-color: $background-color_69;
  }
  p {
    a {
      &:hover {
        color: $color_28;
      }
    }
  }
  .footer-contact-info {
    .footer-address {
      p {
        padding-left: 16px;
        font-weight: 700;
        font-size: 16px;
      }
      span {
        position: relative;
        font-size: 24px;
      }
      &:last-child {
        p {
          margin-bottom: 0;
        }
      }
    }
    h6 {
      padding-left: 16px;
      font-size: 12px;
      color: $color_132;
    }
  }
  .footer-tags {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-menu {
    .footer-tags {
      li {
        a {
          font-weight: 400;
          padding: 6px 10px;
          margin-bottom: 0;
          border: 1px solid #e7e7e7;
          margin-right: 0;
          font-size: 14px;
          transition: 0.5s;
          &:hover {
            color: $color_6 !important;
            background: #ff008a;
            transition: 0.5s;
          }
        }
      }
    }
  }
  .footer-widget {
    .read-more {
      color: $color_28;
    }
  }
  .footer-address {
    i {
      color: $color_28;
    }
  }
  .social-media-icon-img {
    i {
      color: $color_28;
      font-size: 18px;
    }
  }
}
.footer.footer-nine {
  .footer-widget.footer-menu {
    ul {
      li {
        a {
          &:hover {
            color: $color_28;
          }
        }
      }
    }
  }
}
.footer {
  .footer-menu {
    .footer-tags {
      li {
        &:last-child {
          margin-bottom: 9px;
        }
      }
    }
    ul {
      li {
        margin-bottom: 10px;
        position: relative;
        display: flex;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $color_1;
        }
      }
    }
  }
  background-color: $background-color_1;
  border-top: 1px solid rgba(205, 205, 205, 0.2);
  text-align: left;
  font-size: 16px;
  margin: 0;
  color: $color_1;
  .footer-top {
    padding: 50px 0;
  }
  .footer-widget.footer-menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      outline: none;
      li {
        a {
          &:hover {
            color: $color_5;
          }
        }
      }
    }
  }
  .footer-widget {
    .footer-logo {
      margin-bottom: 30px;
    }
  }
  .footer-bottom {
    .copyright {
      border-top: 1px solid #f5f5f5;
      padding: 30px 0;
      .copyright-four {
        border: 1px solid #EBEBEB;
      }
    }
    .copyright-text {
      p {
        a {
          color: $color_5;
          -webkit-transition: all 0.4s ease 0s;
          -o-transition: all 0.4s ease 0s;
          transition: all 0.4s ease 0s;
          &:hover {
            color: $color_5;
          }
        }
        color: $color_1;
      }
      p.title {
        font-weight: 400;
        margin: 10px 0 0;
      }
    }
  }
  .social-icon {
    ul {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      list-style: none;
      padding: 0;
      margin: 0 0 35px;
      li {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $color_1;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          font-size: 18px;
          transition: all 0.4s ease 0s;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          background-color: $background-color_38;
          &:hover {
            background-color: $background-color_2;
            color: $color_6;
          }
        }
      }
    }
  }
}
.social-media-icon-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding-top: 40px;
  img {
    width: 20px;
    height: 20px;
  }
}
.service-section-nine {
  .offer-paths {
    background-color: $background-color_65;
    border-radius: 0;
  }
}
.offer-paths.service-path {
  &::after {
    background: none;
    content: none;
  }
}
.offer-paths.service-path.offer-sec {
  align-items: center;
  .service-img-nine {
    border-radius: 50px 50px 0 50px;
    margin-right: 20px;
    margin-top: 50px;
  }
}
.partner-section-nine {
  margin-top: -250px;
  padding: 230px 0 56px 0;
  background: #F4EFF2;
}
.provider-price {
  .price-card {
    box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
    border: 2px solid #F5F5F5;
    .btn-choose {
      background: #AAADF9;
      color: $color_6;
      width: 100%;
      justify-content: center;
    }
  }
  .price-card.active {
    background: #F7F7FF;
    border-radius: 8px;
    &:hover {
      background: #4c40ed;
      .btn-choose {
        background: #AAADF9;
      }
    }
    .btn-choose {
      background: #4c40ed;
    }
  }
}
.subs-list {
  border-radius: 8px;
  margin-bottom: 24px;
  li {
    display: inline-block;
    margin-right: -4px;
    a.active {
      background: #fff;
    }
    a {
      display: inline-block;
      background: #F3F4F6;
      border: 4px solid #F3F4F6;
      font-weight: 500;
      font-size: 14px;
      color: $color_143;
      padding: 10px 20px;
    }
  }
}
.choose-title {
  margin-bottom: 1.5rem;
  h6 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 30px 10px 0;
    flex: 0 0 100%;
  }
  .status-toggle {
    margin-bottom: 10px;
    font-weight: 500;
  }
  .status-tog {
    .checktoggle {
      margin: 0 16px;
    }
  }
}
.addon-box {
  background: #fff;
  border: 2px solid #F5F5F5;
  border-radius: 5px;
  padding: 24px 24px 14px;
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  &:last-child {
    margin: 0;
  }
}
.addon-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .custom_check {
    margin-bottom: 25px;
  }
}
.addon-info {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    li {
      // margin: 0 24px 10px 0;
      margin: 0 1rem 10px 0;
      &:last-child {
        margin-right: 0;
      }
      h6 {
        font-size: 20px;
        margin: 0;
      }
    }
  }
  .btn {
    font-weight: 500;
    padding: 5px 15px;
    white-space: nowrap;
  }
  .select2-container--default {
    .select2-selection--single {
      min-width: 161px;
    }
  }
}
.footer-content {
  max-width: 304px;
  p {
    color: $color_1;
    margin-bottom: 15px;
  }
}
.footer-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.footer-logo {
  img {
    max-height: 35px;
  }
}
.footer-contact-info {
  .footer-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  p {
    color: $color_1;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 17px;
    color: $color_5;
  }
  h6 {
    color: $color_108;
    font-weight: 500;
    position: relative;
    padding-left: 39px;
    margin-bottom: 2px;
  }
}
.subscribe-form {
  position: relative;
  .form-control {
    min-height: 42px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #D9D9D9;
    padding: 6px 50px 6px 15px;
    &::placeholder {
      color: $color_1;
    }
  }
  .footer-btn {
    font-size: 20px;
    color: $color_26;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    padding: 0;
    display: inline-flex;
    i {
      &:hover {
        color: $color_5;
      }
    }
  }
}
.policy-menu {
  margin: 0;
  padding: 0;
  text-align: right;
  li {
    display: inline-block;
    color: $color_1;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
      &::after {
        content: "";
      }
    }
    a {
      color: $color_1;
      &:hover {
        color: $color_5;
      }
      &:focus {
        color: $color_5;
      }
    }
    &::after {
      color: $color_1;
      content: "|";
      font-weight: 300;
      position: relative;
      left: 10px;
    }
  }
}
.footer-select {
  position: relative;
  width: 108px;
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        font-size: 14px;
        color: $color_144;
        color: $color_145;
        line-height: 40px;
      }
      border: 1px solid #D9D9D9;
      height: 40px;
      .select2-selection__arrow {
        height: 47px;
        right: 6px;
        display: none;
      }
    }
  }
  &:last-child {
    margin-left: 20px;
  }
  > img {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: 10px;
  }
  > img.footer-dropdown {
    top: 18px;
  }
}
.select2-results__option {
  font-size: 14px;
}
.payment-image {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      &:not(:last-child) {
        margin-right: 11px;
      }
    }
  }
}
.footer-two {
  background: #fff4fa;
  .footer-contact-info {
    .footer-address {
      position: relative;
      &:last-child {
        p {
          margin: 0;
        }
      }
    }
    p {
      color: $color_102;
      font-size: 16px;
      font-weight: 700;
      padding-left: 37px;
    }
    span {
      font-size: 25px;
      color: $color_146;
    }
  }
  .social-icon {
    text-align: center;
    ul {
      margin: 0;
      li {
        a {
          background: rgba(212, 216, 229, 0.26);
          color: $color_146;
          &:hover {
            background: #ff008a;
          }
        }
      }
    }
  }
  .footer-bottom {
    .copyright-text {
      p {
        color: $color_108;
        text-align: right;
      }
    }
  }
  .payment-image {
    ul {
      justify-content: start;
    }
  }
  .footer-widget.footer-menu {
    ul {
      li {
        a {
          &:hover {
            color: $color_28;
          }
        }
      }
    }
  }
}
.footer-menu {
  li {
    a {
      color: $color_103;
    }
    display: inline-block;
    color: $color_103;
    margin-right: 15px;
  }
}
.footer-selects {
}
.footer.footer-six {
  background: url(../img/oru-company-bg.png);
  background-repeat: no-repeat;
  padding-top: 80px;
  border: none;
  position: relative;
  .footer-top {
    padding: 0;
  }
  .footer-menu {
    ul {
      li {
        margin-bottom: 15px;
        color: $color_1;
      }
    }
  }
}
.footer-top-content {
  img {
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
.footer-six-ryt {
  span {
    font-size: 14px;
    color: $color_1;
  }
  h6 {
    color: $color_102;
    margin-bottom: 0;
  }
}
.footer-btns {
  color: $color_28 !important;
  text-decoration: underline;
  &:hover {
    color: $color_102 !important;
    text-decoration: underline;
  }
}
.footer-bottom.footer-bottom-six {
  .copyright-menu {
    ul {
      display: flex;
      li {
        padding-right: 10px;
        a {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: rgba(32, 48, 102, 0.6);
          color: $color_148;
          font-size: 12px;
          &:hover {
            background: #ff008a;
            color: $color_6;
          }
        }
      }
    }
  }
}
.footer-widget.footer-menu.footer-menu-six {
  margin-top: 50px;
}
.add-service {
  padding: 24px;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  border: 0;
  .sub-title {
    h6 {
      padding-bottom: 24px;
      border-bottom: 1px solid #E1E1E1;
      font-size: 20px;
    }
  }
  .col-form-label {
    padding: 0 0 10px;
  }
  .add-service-toggle {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E1E1E1;
    .sub-title {
      h6 {
        margin: 0;
        padding: 0;
        border: 0;
      }
    }
  }
  .file-preview {
    ul {
      display: flex;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        .img-preview {
          margin-bottom: 15px;
          a {
            width: 24px;
            height: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $color_6;
            background: #FF0101;
            border-radius: 5px;
          }
        }
        .custom_check {
          .checkmark {
            position: static;
            display: inline-flex;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            &::after {
              position: static;
              display: inline-flex;
              transform: none;
              font-size: 15px;
              content: "";
              width: 12px;
              height: 12px;
              border-radius: 50%;
              position: absolute;
              background: #4c40ed;
              opacity: 0;
              top: 4px;
              left: 4px;
              display: none;
            }
          }
          padding: 0;
          input {
            &:checked {
              ~ {
                .checkmark {
                  &::after {
                    opacity: 1;
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    ul.gallery-selected-img {
      li {
        .custom_check {
          .checkmark {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $background-color_1;
          }
        }
      }
    }
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        line-height: 50px;
      }
      height: 50px;
    }
  }
}
.field-bottom-btns {
  display: flex;
  justify-content: center;
  button.btn {
    padding: 9px 15px;
    display: inline-flex;
    align-items: center;
    min-width: 78px;
    justify-content: center;
  }
  .prev_btn {
    background: #333;
    border-color: $border-color_23;
    margin-right: 10px;
    &:hover {
      border-color: $border-color_24;
    }
    i {
      margin-right: 5px;
    }
  }
  .next_btn {
    i {
      margin-left: 5px;
    }
  }
  .next_btn.submit-btn {
    min-width: 98px;
  }
}
.add-service-modal {
  .modal-content {
    padding: 40px;
    text-align: center;
    .modal-body {
      padding: 0;
      span {
        margin-bottom: 10px;
        display: block;
        i {
          font-size: 40px;
          color: $color_9;
        }
      }
      h3 {
        font-size: 28px;
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }
      .popup-btn {
        a {
          padding: 9px 15px;
          font-size: 14px;
          display: inline-flex;
          align-items: center;
          i {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.add-service-modal.show {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: 0.5s all;
  }
}
.booking-two {
  margin: 60px 0;
}
.call-help {
  h6 {
    font-size: 14px;
    color: $color_116;
    margin-bottom: 0;
    span {
      font-size: 14px;
      font-weight: 400;
      display: block;
      margin-top: 10px;
    }
  }
}
.get-new-password {
  p {
    font-size: 14px;
    color: $color_25;
    margin-bottom: 20px;
    a {
      color: $color_116;
    }
  }
}
.main-booking-form {
  flex-direction: column;
}
.product-info {
  h6 {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.name-card {
  background: #FFF;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 15px;
  margin-bottom: 15px;
  h6 {
    font-weight: 500;
    margin-bottom: 10px;
  }
  span {
    img {
      border-radius: 5px;
      width: 100%;
    }
  }
  .profile-detail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
.profile-detail {
  .email-name {
    span {
      color: $color_116;
      font-size: 14px;
    }
    p {
      font-size: 14px;
      color: $color_25;
      margin-bottom: 0;
      line-break: anywhere;
    }
    a {
      color: $color_5;
      font-size: 14px;
      display: block;
      line-height: normal;
      line-break: anywhere;
    }
  }
}
#appointment-confirmation {
}
.booking-date-time {
  background: #FAFAFA;
  padding: 10px;
  text-align: center;
  line-height: normal;
  color: $color_116;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 15px;
  i {
    color: $color_5;
    margin-right: 5px;
  }
}
.search-icon-right {
  position: absolute;
  right: 10px;
  top: 50%;
  line-height: normal;
  transform: translateY(-50%);
  padding-bottom: 0;
}
.chat-search.chat-search-head {
  padding-bottom: 0;
  .input-group {
    input.form-control {
      padding-right: 30px;
    }
  }
}
.received {
  .drop-item.message-more-option {
    position: absolute;
    right: -40px;
    top: 0px;
  }
}
.chat-time {
  i.fa-check-double {
    color: $color_25;
    margin-left: 5px;
  }
  i.fa-check-double.read {
    color: $color_149;
  }
  i.fa-check {
    color: $color_25;
    margin-left: 5px;
  }
}
.sent {
  .drop-item.message-more-option {
    position: absolute;
    left: -40px;
    top: 0px;
  }
}
.drop-item {
  opacity: 0;
  transform: scale(0);
  transition: 0.5s all;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  border: 1px solid #E1E1E1;
  border-radius: 50%;
  z-index: 1;
  a.more-vertical-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.msg-box {
  &:hover {
    .drop-item {
      opacity: 1;
      transform: scale(1);
      transition: 0.5s all;
    }
  }
}
.drop-item.message-more-option {
  .dropdown-menu {
    min-width: 130px !important;
    padding: 0;
    a {
      display: flex;
      align-items: center;
      font-size: 0.9375rem;
      padding: 5px 15px;
    }
    .dropdown-item {
      &:active {
        background-color: $background-color_2;
      }
    }
    .dropdown-item.active {
      background-color: $background-color_2;
    }
  }
}
.timeline {
  width: 100%;
  height: 2px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #E1E1E1;
}
span.audio-length {
  font-size: 14px;
}
.wizard {
  ul {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    li {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      h5 {
        font-size: 16px;
        margin: 10px 0;
        font-weight: 400;
      }
      span.dot-active {
        width: 18px;
        height: 18px;
        border: 1px solid #DDD;
        background-color: $background-color_1;
        display: flex;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 0;
        z-index: 1;
      }
      &::after {
        content: "";
        width: 50%;
        height: 1px;
        background: #DDD;
        position: absolute;
        bottom: 9px;
        left: 0;
      }
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background: #DDD;
        position: absolute;
        bottom: 9px;
        right: 0;
      }
      &:first-child {
        &:before {
          width: 50%;
        }
        &::after {
          display: none;
        }
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
    li.progress-activated {
      &::after {
        background: #4c40ed;
      }
      &::before {
        background: #4c40ed;
      }
      span.dot-active {
        border-color: $border-color_18;
        background-color: $background-color_3;
      }
    }
    li.progress-active {
      &::after {
        background: #4c40ed;
      }
      span.dot-active {
        border-color: $border-color_18;
      }
    }
  }
}
.profile-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-form-btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.card.chat-window {
  width: 100%;
  margin-right: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.chat-cont-profile.chat-profile-right {
  width: 0;
  display: none;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.chat-page-body {
  .sidebar-overlay.opened {
    display: none;
  }
}
.form-control.type_msg.empty_check {
  padding-left: 50px;
  padding-right: 130px;
}
.setting-img-profile {
  text-align: start;
  h6 {
    font-size: 20px;
    font-weight: 500;
    color: $color_116;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: $color_50;
    margin-bottom: 0px;
  }
}
&:root {
  --tooltip-color: red;
}
input[type="password"] {
  padding-right: 45px;
}
.modal-dialog {
  .modal-content {
  }
}
#otp {
  .doctor-profile {
    .modal-body {
      .wallet-add {
        .form-group {
          margin-bottom: 20px;
        }
      }
    }
  }
}
.success-modal {
  .modal-body {
    .success-modal-body {
      color: $color_25;
      font-size: 16px;
      font-weight: 400;
      max-width: 270px;
      margin: auto;
    }
  }
  .modal-content {
    .success-body {
      margin-bottom: 40px;
    }
    .modal-body {
      span {
        display: block;
        i {
          font-size: 40px;
          color: $color_9;
        }
      }
      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }
}
#change-mail {
  .modal-dialog {
    .doctor-profile {
      .modal-body {
        .wallet-add {
          .form-group {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
#change-document {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .wallet-add {
          .form-group {
            margin-bottom: 20px;
            label {
              font-size: 16px;
              font-weight: 400;
              color: $color_116;
              margin-bottom: 10px;
            }
          }
        }
        .document-upload-file {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          background: #FAFAFA;
          p {
            color: $color_25;
            font-size: 16px;
            font-weight: 400;
            margin-left: 5px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
#success-document {
  .modal-dialog {
    .modal-content {
      .success-body {
        .success-modal-body {
          color: $color_25;
          font-size: 16px;
          font-weight: 400;
          margin: auto;
          max-width: inherit;
        }
      }
    }
  }
}
.modal-content {
  padding: 20px;
}
.notification-delete-wrap {
  .notification-delete {
    a.btn-acc {
      background: rgba(255, 1, 1, 0.10);
      padding: 10px;
      color: $color_150;
      border: none;
      display: inline-block;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  transition: 0.5s all;
  &:hover {
    .notification-delete {
      .notification-dropdown {
        opacity: 1;
        display: flex;
        transition: 0.5s all;
      }
    }
  }
}
.notification-delete {
  .notification-dropdown {
    margin-right: 20px;
    width: 34px;
    height: 34px;
    background: #FAFAFA;
    border: 1px solid #E1E1E1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    display: none;
    transition: 0.5s all;
    .dropdown-menu {
      border-radius: 5px;
      background: #FFF;
      box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
      padding: 10px;
    }
  }
}
.notification-wrap-date {
  .search-calendar-line {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #E1E1E1;
    width: 135px;
    height: 42px;
    i.feather.icon-calendar {
      margin-right: 5px;
    }
  }
}
.search-calendar-line {
  input {
    padding-right: 0;
    line-height: normal;
  }
}
.sidebar-notification {
  display: none;
}
.section-booking {
  padding: 80px 0;
  background: #fff;
  h4.card-title {
    color: $color_116;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
.service-view {
  .service-header {
  }
  .service-images {
    img {
      border-radius: 0;
      margin-bottom: 24px;
    }
  }
}
.service-header {
  margin-bottom: 30px;
  .rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
  }
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
  font-size: 18px;
  color: $color_155;
}
.service-details {
  ul {
    li {
      + {
        li {
          margin-left: 10px;
        }
      }
      a {
        &:hover {
          background: #203066;
          color: $color_6;
        }
      }
    }
    margin-bottom: 24px;
  }
  .tab-content {
    padding-top: 0;
  }
}
.service-carousel {
  .service-widget {
    .service-img {
      &::before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
        background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
        background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        z-index: 1;
      }
    }
  }
}
.widget {
  margin-bottom: 30px;
}
.sidebar-widget {
  background: #f9f9f9;
  padding: 14px;
  border-radius: 5px;
  text-align: center;
  .service-amount {
    color: $color_84;
    font-size: 36px;
    font-weight: 700;
  }
}
.about-provider-img {
  background-color: $background-color_1;
  height: 80px;
  overflow: hidden;
  position: absolute;
  width: 80px;
}
.computer-service {
  padding: 15px;
  background: #FFF;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
}
.additional-title {
  margin: 15px 0;
  h5 {
    margin-bottom: 0;
  }
}
.service-wrap-content {
  height: 94%;
}
.service-add-server {
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  box-shadow: 0px 4px 114px 0px rgba(214, 214, 214, 0.25);
  padding: 15px;
  margin-bottom: 15px;
  .custom_check {
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.body-one {
  .fav-item {
    .fav-icon {
      &:hover {
        background: #4c40ed;
      }
    }
  }
  .fav-icon.selected {
    background: #4c40ed;
  }
}
.search-form {
  .btn-primary {
    &:active {
      &:not(:disabled) {
        &:not(.disabled) {
          border: 0;
          box-shadow: none;
          background-color: $background-color_19;
        }
      }
    }
    &:focus {
      border: 0;
      box-shadow: none;
      background-color: $background-color_19;
    }
  }
}
.noti-wrapper {
  .notify-link {
    position: relative;
    &:hover {
      .noti-message {
        background: #fff;
        color: $color_5;
      }
    }
  }
  .noti-message {
    width: 17px;
    color: $color_6;
    height: 17px;
    border-radius: 50%;
    background: #4c40ed;
    position: absolute;
    display: block !important;
    right: 7px;
    top: 0;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-size: 11px;
  }
}
form {
}
.chat-profile-right {
  .back-user-list {
    display: none;
  }
}
header.header {
  nav {
    ul {
      li {
        + {
          li {
            margin-left: 0;
          }
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.show-card-details {
}
.book-main {
  .middle-items {
    margin: 0 24px;
  }
  .hide-show-main {
    width: 73%;
  }
}
.hide-show#show-first {
  display: block;
}
.hide-show {
  display: none;
}
.page-link {
  &:focus {
    box-shadow: none;
  }
}
.window-full-width {
  .received {
    .drop-item.message-more-option {
      right: 0;
    }
  }
}
@media only screen and (min-width: 992px) {
  .main-nav {
    > li {
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
      > a {
        line-height: 85px;
        padding: 0 !important;
        > i {
          font-size: 12px;
          margin-left: 3px;
        }
      }
      .submenu {
        li {
          &:first-child {
            a {
              border-top: 0;
            }
          }
        }
        > li {
          .submenu {
            left: 100%;
            top: 0;
            margin-top: 10px;
          }
        }
      }
    }
    li {
      display: block;
      position: relative;
      > ul {
        background-color: $background-color_1;
        border-radius: 5px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        display: block;
        font-size: 14px;
        left: 0;
        margin: 0;
        min-width: 200px;
        opacity: 0;
        padding: 0;
        position: absolute;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        top: 100%;
        visibility: hidden;
        z-index: 1000;
      }
      .submenu {
        a {
          &:hover {
            color: $color_5;
          }
        }
      }
    }
    li.has-submenu {
      &:hover {
        > .submenu {
          opacity: 1;
          visibility: visible;
          transform: scaleY(1);
          -webkit-transform: scaleY(1);
          -moz-transform: scaleY(1);
          -ms-transform: scaleY(1);
          -o-transform: scaleY(1);
        }
      }
    }
    .has-submenu.active {
      > a {
        color: $color_5;
      }
      .submenu {
        li.active {
          > a {
            color: $color_5;
          }
        }
      }
    }
    > li.has-submenu {
      &:hover {
        > .submenu {
          > li.has-submenu {
            &:hover {
              > .submenu {
                visibility: visible;
                opacity: 1;
                margin-top: -1px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    > .has-submenu {
      > .submenu {
        > .has-submenu {
          > .submenu {
            &::before {
              top: 20px;
              margin-left: -35px;
              box-shadow: 1px 1px 0 0 rgba(0, 0, 0, .15);
              border-color: $border-color_28;
            }
          }
        }
      }
    }
  }
  .header-navbar-rht {
    .dropdown-toggle.show {
      + {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          margin-top: 0;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }
    li {
      .dropdown-menu {
        border-radius: 5px;
        padding: 0;
        margin: 0;
        min-width: 200px;
        visibility: hidden;
        opacity: 0;
        top: 115%;
        left: auto;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        display: block;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
      }
      .dropdown-menu.notifications {
        left: -310px;
      }
    }
  }
  .mini-sidebar {
    .header-left {
      .logo {
        img {
          height: auto;
          max-height: 40px;
          width: auto;
        }
      }
      .logo {
        display: block;
      }
    }
    .header {
      .header-left {
        .logo {
          display: none;
        }
        padding: 0 5px;
        width: 80px;
        #toggle_btn {
          i {
            transform: rotate(180deg);
            color: $color_27;
            padding: 0 7px;
          }
        }
      }
    }
    .sidebar {
      width: 80px;
      .sidebar-menu {
        ul {
          > li {
            > a {
              span {
                display: none;
                transition: all 0.2s ease-in-out;
                opacity: 0;
              }
            }
          }
        }
      }
    }
    .menu-title {
      visibility: hidden;
      white-space: nowrap;
      a {
        visibility: hidden;
      }
    }
    .page-wrapper {
      margin-left: 78px;
    }
  }
  .mini-sidebar.expand-menu {
    .sidebar {
      width: 270px;
      .sidebar-menu {
        ul {
          > li {
            > a {
              span {
                display: inline;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .menu-title {
      visibility: visible;
      a {
        visibility: visible;
      }
    }
  }
  .modal-open.mini-sidebar {
    .sidebar {
      z-index: 1051;
    }
  }
}
@media only screen and (max-width: 1399.98px) {
  .home-banner {
    background-size: 550px;
  }
  .catering-banner-img {
    img {
      max-width: 660px;
    }
  }
  .home-banner.slider-service {
    min-height: 600px;
  }
  .service-content {
    p {
      font-size: 15px;
    }
  }
  .banner-imgs {
    .banner-1 {
      max-width: 160px;
      left: -7rem;
      &::before {
        left: -8rem;
        top: 4rem;
      }
    }
    .banner-2 {
      max-width: 250px;
      right: -0.5rem;
      top: 4rem;
    }
    .banner-3 {
      max-width: 240px;
      bottom: 2rem;
      left: -6rem;
    }
    .banner-4 {
      bottom: 5rem;
      right: 1.2rem;
      img {
        max-width: 165px;
        max-height: 165px;
      }
    }
  }
  .testimonial-slider {
    .owl-nav {
      .owl-next {
        right: -40px;
      }
      .owl-prev {
        left: -40px;
      }
    }
  }
  .footer {
  }
  .booking-details {
    li {
      p {
        padding-left: 10px;
        &:last-child {
          margin-left: 110px;
          margin-top: 10px;
          display: block;
        }
      }
    }
  }
  .slot-action {
    .btn {
      padding: 5px 11px;
    }
  }
  .main-nav {
    > li {
      margin-right: 18px;
    }
    li {
      a {
        font-size: 14px;
      }
    }
  }
  .section-search {
    h1 {
      font-size: 44px;
    }
  }
  .chat-cont-left {
    .chat-users-list {
      a.media {
        .media-body {
          > div {
            &:first-child {
              .user-name {
                max-width: 70px;
              }
              .user-last-chat {
                max-width: 70px;
              }
            }
          }
        }
      }
    }
    .chat-scroll {
      max-height: calc(100vh - 325px);
    }
  }
  .grid-blog {
    .blog-category {
      ul {
        li {
          margin: 0 5px 12px 0;
        }
      }
    }
  }
  .search-btn {
    .btn {
      padding: 11px 10px;
    }
  }
  .search-box {
    width: 86%;
  }
  .search-input {
    &::before {
      display: none;
    }
  }
  .search-input.line {
    width: 100%;
    margin-bottom: 1rem;
  }
  .dash-wallet {
    .dash-icon {
      width: 42px;
      height: 42px;
      margin: 0 7px 0 0;
    }
  }
  .card-remove {
    flex-shrink: 0;
  }
  .balance-crad {
    h3 {
      font-size: 28px;
    }
    .view-transaction {
      padding: 5px 6px;
    }
    .btn-withdraw {
      padding: 5px 6px;
    }
  }
  .servicecontent.service-widget {
    .serv-info {
      h6 {
        font-size: 20px;
      }
    }
  }
  .provider-img {
    span {
      bottom: 39px;
    }
  }
  .price {
    font-size: 18px;
    .old-price {
      font-size: 14px;
    }
  }
  .flag-dropdown {
    .nav-link {
      font-size: 14px;
      font-size: 14px;
    }
  }
  .support-title {
    font-size: 16px;
  }
  .offer-sec {
    .offer-path-content {
      padding: 80px 0;
    }
  }
  .servicecontent {
    .btn-book {
      padding: 10px 14px;
    }
  }
  .categories-main-all {
    padding: 30px 25px;
  }
  .trust-us-main {
    padding: 30px;
  }
  .works-it-lines {
    span {
      &::before {
        width: 315px;
        left: 207px;
      }
    }
  }
  .offering-five-img {
    margin: -60px 0 0 -60px;
  }
  .search-box-two.search-box-six {
    .search-btn {
      .btn {
        padding: 8px 10px;
      }
    }
    width: 100%;
  }
  .home-banner-six-bg {
    img {
      left: -95px;
    }
  }
  .our-company-bg {
    img {
      left: -100px;
    }
  }
  .professional-eight-img-ryt {
    img {
      height: auto;
    }
  }
  .book-submit {
    .btn {
      + {
        .btn {
          margin-left: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1199.98px) {
  .logo {
    img {
      max-width: 170px;
    }
  }
  .dropdown.noti-dropdown {
    margin-top: 0px !important;
  }
  .header-navbar-rht {
    > li.desc-list {
      a {
        > span {
          display: none;
        }
      }
    }
    > li {
      padding-right: 5px;
    }
    li {
      > a.header-reg {
        font-size: 13px;
      }
    }
  }
  .provider-box {
    .btn {
      font-size: 14px;
      padding: 5px 12px;
    }
    .rate {
      font-size: 14px;
    }
  }
  .register-content {
    h2 {
      font-size: 35px;
    }
  }
  .header {
    .has-arrow {
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }
  }
  .dash-widget-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    flex: 0 0 50px;
  }
  .dash-widget {
    padding: 15px;
  }
  .dash-widget-info {
    > span {
      font-size: 16px;
    }
  }
  .main-nav {
    > li {
      margin-right: 10px;
      margin-right: 20px;
      margin-right: 9px;
    }
    li {
      a {
        font-size: 14px;
      }
    }
  }
  .section-heading {
    h2 {
      font-size: 30px;
    }
    span {
      font-size: 18px;
    }
  }
  .section-heading-two {
    h2 {
      font-size: 30px;
    }
  }
  .category-name {
    h6 {
      font-size: 18px;
    }
  }
  .pricing-plans.price-new {
  }
  .language-list {
    a {
      padding: 11px 13px;
      font-size: 14px;
      i {
        margin-left: 10px;
      }
    }
  }
  .app-section {
    padding: 20px 0 90px;
  }
  .app-sec {
    h2 {
      font-size: 40px;
    }
    padding: 45px;
  }
  .appimg-set {
    margin-top: -70px;
    img {
      max-width: 450px;
    }
  }
  .testimonial-slider {
    .owl-nav {
      .owl-prev {
        left: -25px;
      }
      .owl-next {
        right: -25px;
      }
    }
  }
  .home-banner {
    background-size: 470px;
  }
  .section-search {
    h1 {
      font-size: 34px;
      max-width: 500px;
    }
    p {
      width: 24rem;
    }
  }
  .serv-profile {
    h2 {
      font-size: 36px;
    }
    ul {
      li {
        font-size: 14px;
        margin: 0 15px 15px 0;
      }
    }
  }
  .service-book {
    .serv-profile {
      h2 {
        font-size: 20px;
      }
    }
  }
  .contact-info {
    span {
      font-size: 20px;
      width: 60px;
      height: 60px;
    }
  }
  .provide-info {
    p {
      font-size: 14px;
    }
  }
  .service-book-img {
    margin: 0 25px 30px 0;
  }
  .progress-wrap.active-progress {
    display: none;
  }
  .available-widget {
    ul {
      li {
        font-size: 14px;
      }
    }
  }
  .available-info {
    h5 {
      font-size: 18px;
    }
    ul {
      li {
        font-size: 14px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .package-widget {
    h5 {
      font-size: 18px;
    }
  }
  .service-wrap {
    h5 {
      font-size: 18px;
    }
  }
  .card-provide {
    .btn {
      padding: 12px;
    }
  }
  .dash-info {
    h6 {
      font-size: 13px;
    }
  }
  .dash-wallet {
    .dash-info {
      h5 {
        font-size: 20px;
      }
      h6 {
        font-size: 13px;
      }
    }
  }
  .widget-title {
    h4 {
      font-size: 20px;
    }
  }
  .maintenance-wrap {
    h2 {
      font-size: 26px;
    }
  }
  .balance-crad {
    h3 {
      font-size: 26px;
    }
  }
  .booking-list {
    .booking-widget {
      flex: 0 0 100%;
    }
  }
  .booking-action {
    display: flex;
    flex: 0 0 100%;
    margin-top: 15px;
  }
  .main-content {
    .linked-wrap {
      display: block;
    }
  }
  .linked-item {
    a.btn-gconnect {
      margin-right: 15px;
    }
    &:last-child {
      .linked-acc {
        margin-bottom: 25px;
      }
    }
  }
  .plan-btns {
    .btn {
      margin: 5px 5px 0 0;
    }
  }
  .slot-action {
    .btn {
      padding: 7px 15px;
    }
  }
  .chat-cont-profile.opened {
    margin-right: 0;
  }
  .chat-cont-profile {
    .card {
      border-radius: 10px 0 0 10px;
    }
    .card-body {
      max-height: calc(100vh - 136px);
    }
  }
  .window-full-width {
    .sidebar-overlay.opened {
      display: none;
    }
  }
  .chat-cont-left {
    .chat-scroll {
      max-height: calc(100vh - 292px);
    }
  }
  .customer-chat {
    .chat-cont-profile {
      .card-body {
        max-height: calc(100vh - 330px);
      }
    }
  }
  .search-btn {
    .btn {
      font-size: 14px;
      padding: 10px;
    }
  }
  .search-input {
    label {
      font-size: 14px;
    }
  }
  .work-wrap {
    h1 {
      font-size: 30px;
    }
  }
  .work-wrap-acc {
    &:after {
      bottom: -53%;
      width: 60%;
      height: 60%;
    }
  }
  .work-wrap-post {
    &:after {
      bottom: -53%;
      width: 60%;
      height: 60%;
    }
  }
  .visits {
    width: 16.6%;
  }
  .book-submit {
    margin-top: 25px;
    .btn {
      + {
        .btn {
          margin-left: 0;
        }
      }
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .offer-paths {
    padding: 0 50px;
  }
  .offer-path-content {
    padding-left: 30px;
    h3 {
      font-size: 28px;
    }
  }
  .providerset-name {
    h4 {
      font-size: 16px;
    }
  }
  .providerset-prices {
    h6 {
      font-size: 16px;
    }
  }
  .feature-title {
    h5 {
      font-size: 16px;
    }
  }
  .page-headers {
    .search-bar {
      width: 190px;
    }
  }
  .provider-img {
    span {
      padding: 10px;
      font-size: 14px;
      bottom: 32px;
    }
  }
  .service-action {
    .btn {
      margin-left: 10px;
      padding: 10px 12px;
    }
    h6 {
      font-size: 18px;
    }
  }
  .service-widget.service-two {
    .service-content {
      .title {
        font-size: 18px;
      }
    }
  }
  .support-title {
    font-size: 15px;
  }
  .about-content {
    h2 {
      font-size: 30px;
    }
  }
  .choose-content {
    h2 {
      font-size: 30px;
    }
  }
  .choose-info {
    h5 {
      font-size: 28px;
    }
    p {
      font-size: 14px;
    }
  }
  .about-img {
    .about-exp {
      span {
        font-size: 20px;
        margin: 190px -85px;
      }
    }
  }
  .works-it-lines {
    span {
      &::before {
        width: 265px;
        left: 175px;
      }
    }
  }
  .offering-five-img {
    margin: -13px 0 0 -100px;
  }
  .search-box-two.search-box-six {
    .search-input-new.line-six {
      width: 40%;
    }
    .search-input-new.line-two-six {
      width: 35%;
    }
    .search-btn {
      width: 25%;
    }
  }
  .customer-review-top {
    padding: 30px 30px;
  }
  .home-banner-eight {
    .section-search {
      h1 {
        max-width: none;
      }
    }
  }
  .about-eight-main {
    img {
      max-width: 100%;
    }
  }
  .booking-info-tab {
  }
  .settings-widget {
    .settings-header {
      display: block;
    }
  }
  .setting-img-profile {
    text-align: center;
  }
  .service-content {
    padding: 16px;
    .user-info {
      h6 {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 991.98px) {
  .service-section {
    .btn-sec {
      .btn {
        margin-top: 0;
      }
    }
  }
  //.service-wizard {
  //  display: none;
  //}
  .table {
    border: 0;
    .table-actions {
      width: 25%;
      height: 25%;
    }
    h2.table-avatar {
      white-space: normal !important;
    }
  }
  .align-description {
    display: none;
  }
  .card {
    .card-body {
      padding: 0.8rem 0.5rem;
    }
  }
  .table-responsive {
    padding: 0;
  }
  .providers-sidebar {
    .sidebar {
      top: 68px;
    }
  }
  .sidebar-overlay.opened {
    display: block;
  }
  .booking-details {
    li {
      p {
        &:last-child {
          margin-left: 0;
          margin-top: 0;
          display: unset;
        }
        &:first-child {
          margin-left: 110px;
          margin-top: 10px;
        }
      }
    }
  }
  .linked-item {
  }
  .settings-widget {
    margin-bottom: 30px;
  }
  .works-five-main {
    margin-bottom: 30px;
  }
  .provider-box {
    .btn {
      font-size: 15px;
      padding: 5px 15px;
    }
    .rate {
      font-size: 15px;
    }
  }
  .header-navbar-rht {
    > li {
      padding-right: 15px;
    }
    > li.desc-list {
      display: none;
    }
    li {
      .dropdown-menu.notifications {
        left: -310px;
      }
    }
  }
  .login-wrap {
    .col-form-label {
      font-size: 14px;
    }
    .login-btn {
      font-size: 14px;
      padding: 10px;
    }
  }
  .offer-paths {
    background-color: $background-color_2;
    border-radius: 50px;
    margin-top: 60px;
    padding: 45px;
    top: 0;
    &::after {
      background: transparent;
      z-index: -1;
    }
  }
  .offer-path-content {
    h3 {
      font-size: 28px;
    }
    padding-left: 0;
  }
  .offer-pathimg {
    display: none;
  }
  .grid-listview {
    a {
      width: 32px;
      height: 32px;
      font-size: 14px;
    }
  }
  .select2-container--default {
    .select2-selection--single {
      outline: 0;
    }
  }
  .review-sort {
    .select2-container--default {
      .select2-selection--single {
        height: 32px;
        .select2-selection__arrow {
          height: 32px;
        }
        .select2-selection__rendered {
          line-height: 31px;
        }
      }
    }
  }
  .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    ul {
      display: none;
      list-style: none;
      margin: 0;
      padding-left: 0;
    }
    > li {
      margin-left: 0;
      border-bottom: 1px solid #E1E2E5;
      margin-right: 0;
      > a {
        line-height: 1.5;
        padding: 15px 20px !important;
        font-size: 14px;
        font-weight: 500;
        > i {
          float: right;
          margin-top: 5px;
        }
        &:hover {
          &::after {
            top: 0;
          }
        }
      }
      .submenu {
        li {
          a {
            border-top: 0;
            color: $color_2;
            padding: 10px 15px 10px 35px;
          }
        }
        ul {
          li {
            a {
              padding: 10px 15px 10px 45px;
            }
          }
        }
        > li.has-submenu {
          > a {
            &::after {
              content: "\f078";
            }
          }
        }
      }
    }
    li {
      + {
        li {
          margin-left: 0;
        }
      }
    }
    .has-submenu.active {
      > a {
        color: $color_5;
      }
      .submenu {
        li.active {
          > a {
            color: $color_5;
          }
        }
      }
    }
    > li.active {
      > a {
        &::after {
          top: 0;
        }
      }
    }
  }
  .main-menu-wrapper {
    order: 3;
    width: 240px;
    position: fixed;
    top: 69px;
    bottom: 56px;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 11111;
    transform: translateX(-260px);
    transition: all 0.4s;
    background-color: $background-color_1;
    margin: 0;
    .sidebar {
      margin-left: 0;
      top: 10px;
    }
  }
  .reset-width {
    bottom: 1px;
    top: 67px;
  }
  .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    .logo {
      display: none !important;
    }
  }
  .customer-desktop-only {
    display: none;
  }
  #mobile_btn {
    line-height: 0;
    display: inline-block;
  }
  .mobile-toolbar {
    bottom: env(safe-area-inset-bottom);
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    left: 0;
    right: 0;
    background-color: white;
    padding: 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 15px 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 10;
    border-top: 2px solid #F0F0F0;
  }
  .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      justify-items: anchor-center;
    }
    a.active {
      color: #4c40ed;
    }
  }
  .bottom-menu {
    padding-bottom: 3rem;
  }
  .sidebar {
    margin-left: -260px;
    width: 250px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1038;
  }
  .sidebar-pro{
    order: 2;
    background: #F0F0F0;
  }
  .customer-body {
    .page-wrapper {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
    .header {
      left: 0;
      z-index: 1039;
    }
    .user-menu.nav {
      > li {
        > a.viewsite {
          display: none;
        }
        > a.win-maximize {
          display: none;
        }
      }
      > li.flag-nav {
        display: none;
      }
    }
    .logo-small {
      display: block;
      margin: 0 auto;
    }
  }
  .dropdown-menu-left {
    min-width: 130px !important;
  }
  .provider-body {
    .page-wrapper {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
    .header {
      left: 0;
      z-index: 1039;
      height: 69px;
    }
    .user-menu.nav {
      > li {
        > a.viewsite {
          display: none;
        }
        > a.win-maximize {
          display: none;
        }
      }
      > li.flag-nav {
        display: none;
      }
    }
    .logo-small {
      display: block;
      margin: 0 auto;
    }
  }
  .review-pagination {
    ul {
      li {
        a {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .page-item {
    .page-link {
      width: 25px;
      height: 25px;
    }
  }
  .blog-pagination {
    .page-prev {
      line-height: 25px;
    }
    .page-next {
      line-height: 25px;
    }
  }
  .mobile-only {
    display:flex;
  }
  .mobile_btns {
    color: $color_5;
    cursor: pointer;
    display: flex;
    font-size: 26px;
    height: 76px;
    left: 0;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10;
    justify-content: center;
    align-items: center;
  }
  .bg-img {
    display: none;
  }
  .page-headers {
    display: none;
  }
  .siderbar-toggle {
    display: none;
  }
  .header {
    .header-left {
      .logo {
        display: none;
      }
      position: absolute;
      width: 100%;
      display: block;
      height: auto;
      top: 0;
      left: 0;
      .sidebar-logo {
        padding: 26px 0;
      }
    }
  }
  .header-split {
    justify-content: flex-end;
  }
  .content {
    padding: 30px 0;
    padding: 30px 0;
  }
  .container {
    max-width: 100%;
  }
  .footer-widget {
    margin-bottom: 30px;
  }
  .footer {
    .footer-top {
      padding-bottom: 10px;
    }
    .footer-bottom {
      .copyright-text {
        p {
          font-size: 13px;
        }
      }
    }
  }
  .section-search {
    margin: 60px 0;
    max-width: 100%;
    p {
      font-size: 20px;
      width: 100%;
    }
    h1 {
      font-size: 34px;
      margin-bottom: 15px;
      max-width: 100%;
    }
  }
  .hero-section {
    min-height: 400px;
  }
  .home-banner {
    min-height: 400px;
    background: none;
  }
  .search-box {
    form {
      .search-btn {
        width: auto;
        float: right;
        margin: 0;
        float: left;
        width: 100%;
        padding: 0;
      }
      > div {
        width: 100%;
      }
    }
    border-radius: 30px;
    margin-top: 35px;
    width: 100%;
  }
  .search-box-two {
    border-radius: 30px;
    form {
      .search-btn {
        float: left;
        width: 100%;
        padding: 0;
      }
    }
    .search-btn {
      .btn {
        font-size: 14px;
        padding: 6px 10px;
      }
    }
  }
  .search-input.line {
    width: 100%;
    margin-right: 0;
    &::before {
      display: none;
    }
  }
  .search-input-new.line {
    width: 100%;
    &::before {
      display: none;
    }
    input {
      height: 35px;
    }
  }
  .search-input {
    // width: 100%;
    // margin: 0 0 10px 0;
    //> i {
    //  right: 20px;
    //}
  }
  .search-input-new {
    width: 100%;
    input {
      height: 35px;
    }
  }
  .viewall {
    span {
      font-size: 1.875rem;
    }
  }
  .heading {
    h2 {
      font-size: 1.625rem;
    }
  }
  .chat-cont-left {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: all .4s;
    width: 100%;
    max-height: 100%;
    z-index: 9;
    .chat-scroll {
      max-height: calc(100vh - 292px);
    }
  }
  .chat-cont-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: all .4s;
    width: 100%;
    max-height: 100%;
    position: absolute;
    right: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    .chat-header {
      justify-content: start;
      -webkit-justify-content: start;
      -ms-flex-pack: start;
    }
    .msg_head {
      .back-user-list {
        display: flex;
        align-items: center;
      }
      .chat-options {
        margin-left: auto;
      }
    }
    .chat-scroll {
      max-height: calc(100vh - 257px);
      max-height: calc(100vh - 254px);
    }
  }
  .chat-window.chat-slide {
    .chat-cont-right {
      right: 0;
      opacity: 1;
      visibility: visible;
    }
  }
  .chat-cont-profile {
    z-index: 999;
  }
  .msg_card_body {
    .media.sent {
      .media-body {
        .msg-box {
          margin-left: 0px;
        }
      }
    }
  }
  .chat-options {
    ul {
      li {
        a {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .user_img {
    height: 30px;
    width: 30px;
  }
  .section-search-box {
    min-height: 330px;
  }
  .download-app {
  }
  .section-heading {
    span {
      font-size: 16px;
    }
    h2 {
      font-size: 25px;
    }
  }
  .true-search-box {
    position: relative;
    top: 0;
    padding-top: 25px;
  }
  .feature-category {
    padding-top: 60px;
  }
  .section-heading-two {
    h2 {
      font-size: 25px;
    }
    margin-bottom: 24px;
  }
  .blog-title {
    a {
      font-weight: 500;
    }
  }
  .blog-date {
    p {
      font-size: 15px;
    }
  }
  .blog-inner-img {
    img {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
  .blog-item.left-box {
    margin-right: 30px;
  }
  .blog-item {
    p {
      padding: 30px 35px;
    }
  }
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
  .feature-section {
    padding: 60px 0 36px;
  }
  .work-section {
    padding: 60px 0 36px;
    &.offer-container {
      margin: 0;
    }
    .no-offer-path {
      padding-top: 3rem;
    }
  }
  .client-section {
    padding: 60px 0 36px;
  }
  .feature-section-two {
    padding: 60px 0 36px;
  }
  .service-section-two {
    padding: 60px 0 36px;
  }
  .work-section-two {
    padding: 60px 0 36px;
  }
  .providers-section-two {
    padding: 60px 0 36px;
  }
  .app-section-two {
    padding-bottom: 60px;
  }
  .service-section {
    padding: 60px 0;
    padding: 40px 0;
  }
  .client-section-two {
    padding: 60px 0;
  }
  .about-sec {
    padding: 60px 0;
  }
  .chooseus-sec {
    padding: 60px 0 40px;
  }
  .blog-section {
    padding: 60px 0 40px;
  }
  .app-section {
    padding: 0 0 60px;
  }
  .about-banner {
    margin-bottom: 60px;
  }
  .about-content {
    margin: 0;
    p {
      margin-bottom: 15px;
    }
    h2 {
      font-size: 24px;
    }
  }
  .btn-view {
    font-weight: 500;
    font-size: 14px;
    float: right;
  }
  .fav-item {
    .serv-rating {
      padding: 6px 8px;
      font-size: 14px;
    }
  }
  .choose-content {
    h2 {
      font-size: 24px;
    }
    margin-right: 0;
  }
  .choose-info {
    h5 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
  .service-content {
    .title {
      font-size: 18px;
    }
  }
  .work-box {
    h5 {
      font-size: 18px;
    }
  }
  .book-title {
    h5 {
      font-size: 18px;
    }
  }
  .contact-data {
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 15px;
    }
  }
  .category-name {
    h6 {
      font-size: 18px;
      font-size: 18px;
    }
  }
  .service-widget {
    .serv-info {
      h6 {
        font-size: 20px;
      }
    }
  }
  .widget-title {
    h4 {
      font-size: 20px;
    }
  }
  .grid-blog {
    .blog-content {
      p {
        font-size: 14px;
      }
      .blog-title {
        font-size: 18px;
      }
      .read-more {
        font-size: 14px;
      }
    }
  }
  .blog-category {
    ul {
      li {
        font-size: 12px;
      }
    }
    .post-author {
      span {
        font-size: 12px;
      }
    }
  }
  .breadcrumb-bar {
    .page-breadcrumb {
      ol {
        li {
          a {
            font-size: 15px;
          }
          font-size: 15px;
        }
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    &::before {
      padding: 7px 15px 0 15px;
    }
  }
  .about-offer {
    padding: 30px;
    .offer-path-content {
      padding: 0;
    }
  }
  .service-offer {
    padding-bottom: 60px;
  }
  .btn-book {
    padding: 10px 16px;
  }
  .blog-content {
    .blog-title {
      font-size: 16px;
    }
  }
  .testimonial-slider {
    .owl-nav {
      .owl-prev {
        left: -15px;
      }
      .owl-next {
        right: -15px;
      }
    }
  }
  .appimg-set {
    margin-top: 20px;
    position: relative;
    text-align: center;
    right: 0;
    img {
      max-width: 100%;
    }
  }
  .app-sec {
    &:before {
      content: none;
    }
  }
  .serv-profile {
    h2 {
      font-size: 30px;
    }
  }
  .contact-queries {
    h2 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    .btn {
      margin-top: 2px;
    }
  }
  .serv-action {
    ul {
      li {
        margin-right: 10px;
        a {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .step-register {
    li {
      .multi-step-icon {
        margin-right: 10px;
      }
      .multi-step-info {
        p {
          font-size: 14px;
        }
        h6 {
          font-size: 16px;
        }
      }
    }
  }
  .service-wrap {
    margin-bottom: 20px;
  }
  .service-amount {
    h5 {
      font-size: 20px;
      span {
        font-size: 18px;
      }
    }
  }
  .booking-done {
    h6 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .package-widget {
    ul {
      li {
        &:before {
          top: 2px;
        }
      }
    }
  }
  .book-submit {
    .btn {
      font-size: 14px;
    }
  }
  .blog-list {
    .blog-content {
      .blog-title {
        font-size: 16px;
      }
    }
  }
  .page-title {
    h2 {
      font-size: 24px;
    }
  }
  .login-header {
    h3 {
      font-size: 24px;
    }
  }
  .dash-info {
    h5 {
      font-size: 24px;
    }
  }
  .category-info {
    p {
      font-size: 15px;
    }
  }
  .login-back {
    margin-bottom: 25px;
  }
  .recent-booking {
    margin-bottom: 25px;
  }
  .login-back.manage-log {
    margin-bottom: 25px;
  }
  .banner-imgs {
    display: none;
  }
  .settings-header {
    padding: 20px;
  }
  .settings-menu {
    padding: 20px;
  }
  .price-head {
    h1 {
      font-size: 32px;
    }
  }
  .error-wrap {
    h2 {
      font-size: 32px;
    }
  }
  .maintenance-sec {
    padding: 60px 0;
  }
  .maintenance-wrap {
    margin-bottom: 30px;
    h2 {
      font-size: 22px;
    }
    p {
      margin-bottom: 24px;
    }
  }
  .balance-crad {
    h3 {
      font-size: 22px;
    }
  }
  .main-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .balance-head {
    h6 {
      font-size: 13px;
    }
    p {
      font-size: 12px;
    }
  }
  .user-menu {
    li.dropdown.dropdown-heads {
      margin-right: 0;
    }
  }
  .filter-div {
    .filter-content {
      &:last-child {
        margin-bottom: 25px;
      }
    }
    .btn {
      margin-bottom: 25px;
    }
  }
  .new-comment {
    margin-bottom: 30px;
  }
  .chat-page-body {
    overflow: hidden;
  }
  .customer-chat {
    .chat-cont-left {
      .chat-scroll {
        max-height: calc(100vh - 235px);
      }
    }
    .chat-cont-profile {
      .card-body {
        max-height: calc(100vh - 260px);
      }
    }
  }
  .work-wrap {
    h1 {
      font-size: 26px;
    }
    margin-bottom: 100px;
    span {
      width: 45px;
      height: 45px;
      font-size: 15px;
      margin-bottom: 15px;
    }
  }
  .work-wrap-acc {
    padding-right: 0;
    &:after {
      bottom: -40%;
      width: 50%;
      height: 50%;
    }
  }
  .work-wrap-earning {
    padding-right: 0;
  }
  .work-wrap-post {
    padding-left: 0;
    &:after {
      bottom: -40%;
      width: 50%;
      height: 50%;
    }
  }
  .work-img {
    margin-bottom: 100px;
  }
  .breadcrumb-title {
    font-size: 30px;
  }
  .countdown-container {
    .countdown-el {
      p {
        font-size: 26px;
      }
    }
  }
  #datetimepickershow {
    margin-bottom: 24px;
  }
  .align-items-center .image-responsive {
    width: 100%;
    .provider-img {
      margin: 20px 0;
      img {
        min-width: 100%;
      }
    }
  }
  .align-items-center .details-responsive {
    width: 100%;
    .provider-info {
      h2 {
        font-size: 28px;
      }
    }
    .responsive {
      width: 50%;
    }
  }
  .provider-details {
    margin-bottom: 40px;
  }
  .banner-search {
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  .btn-viewall {
    margin-bottom: 24px;
  }
  .feature-title {
    h5 {
      font-size: 15px;
    }
  }
  .service-widget.service-two {
    .service-content {
      .title {
        font-size: 16px;
      }
    }
  }
  .offer-sec.offer-paths {
    padding: 0 30px;
  }
  .offer-sec {
    .offer-path-content {
      padding: 30px 0;
    }
  }
  .footer-two {
    .payment-image {
      ul {
        margin-bottom: 15px;
      }
    }
    .social-icon {
      text-align: right;
      margin-bottom: 15px;
    }
    .footer-bottom {
      .copyright-text {
        p {
          text-align: left;
        }
      }
    }
  }
  .header-nav {
    height: 67px;
  }
  .download-sec {
    padding-left: 0;
  }
  .contact-details {
    margin-bottom: 30px;
  }
  .policy-menu {
    li {
      font-size: 13px;
    }
  }
  .blog-info {
    ul {
      li {
        font-size: 14px;
      }
    }
    .post-author {
      span {
        font-size: 14px;
      }
    }
  }
  .available-info {
    ul {
      li {
        font-size: 14px;
      }
    }
  }
  .side-title {
    font-size: 20px;
  }
  .review-box {
    p {
      font-size: 14px;
    }
  }
  .about-img {
    margin-bottom: 24px;
  }
  .search-box-three {
    .search-form-profile {
      flex-direction: column;
    }
  }
  .section-section-three {
    text-align: center;
    h4 {
      font-size: 16px;
    }
  }
  .services-header {
    .section-content {
      h2 {
        font-size: 30px;
      }
    }
  }
  .saloon-bottom {
    a {
      padding: 10px;
      font-size: 14px;
    }
  }
  .works-bottom {
    h2 {
      font-size: 26px;
    }
  }
  .register-content {
    h2 {
      font-size: 34px;
      margin-bottom: 20px;
    }
    flex-direction: column;
  }
  .works-section {
    padding: 55px 0 33px;
  }
  .services-section {
    padding: 75px 0 15px;
    .owl-nav {
      margin-top: 30px;
    }
  }
  .saloon-section-circle {
    display: none;
  }
  .section-search.section-search-four {
    max-width: none;
    text-align: center;
    h1 {
      font-size: 40px;
    }
  }
  .catering-banner-img {
    display: none;
  }
  .trust-us-main {
    margin-bottom: 20px;
  }
  .search-box.search-box-four {
    .search-btn {
      .btn {
        justify-content: center;
        width: auto;
      }
      text-align: end;
    }
  }
  .section-heading.section-heading-four {
    .btn-view {
      padding: 15px 20px;
    }
  }
  .floating-img {
    display: none;
  }
  .section-search.section-section-five {
    text-align: center;
  }
  .section-section-five {
    p {
      max-width: none;
    }
  }
  .works-it-lines {
    span {
      &::before {
        width: 250px;
        left: 171px;
      }
    }
  }
  .offering-five-img {
    display: none;
  }
  .app-sec.app-sec-five {
    .appimg-set {
      img {
        display: none;
      }
    }
    padding: 45px;
    text-align: center;
    border-radius: 0;
    .downlaod-btn {
      justify-content: center;
    }
  }
  .works-it-five-button-main {
    display: none;
  }
  .works-five-bottom {
    i {
      padding: 10px;
    }
  }
  .providers-five-bg-car {
    img {
      display: none;
    }
  }
  .featured-category-bg-img {
    img {
      display: none;
    }
  }
  .works-five-section {
    &::before {
      background: none;
    }
    padding: 45px 0;
  }
  .offering-five-all {
    &::before {
      background: none;
    }
  }
  .feature-category-section-five {
    &::before {
      background: none;
    }
    padding: 45px 0;
  }
  .pricing-service-five {
    &::before {
      background: none;
    }
    padding: 45px 0;
  }
  .car-blogs-section {
    &::before {
      background: none;
    }
    padding: 45px 0 0 0;
  }
  .featured-categories-section {
    padding: 45px 0;
  }
  .feature-category-section {
    padding: 45px 0;
    &::before {
      background: none;
    }
  }
  .car-five-arrow-img {
    display: none;
  }
  .categories-section {
    padding: 45px 0;
  }
  .trust-us-section {
    padding: 45px 0;
  }
  .review-client-section {
    padding: 45px 0;
  }
  .home-banner-main {
    display: none;
  }
  .banner-six-side-img {
    display: none;
  }
  .home-banner-six-bottom {
    padding-top: 30px;
    text-align: -webkit-center;
    text-align: -moz-center;
    h1 {
      max-width: 550px;
    }
  }
  .home-banner-six-detergent {
    justify-content: center;
  }
  .hero-section-six {
    background: #F8F8F8;
  }
  .our-company-ryt {
    display: none;
  }
  .our-company-six {
    text-align: -webkit-center;
  }
  .about-our-company {
    background: #F8F8F8;
    padding: 50px 0;
  }
  .satisfied-service-section {
    padding: 50px 0;
  }
  .blogs-service-section {
    padding: 50px 0;
  }
  .hidden-charge-section {
    padding: 50px 0;
  }
  .app-sec-main {
    background: none;
  }
  .hero-banner-ryt {
    display: none;
  }
  .footer.footer-seven {
    .footer-top {
      padding: 45px 0 0px 0;
    }
  }
  .service-section-seven {
    padding: 45px 0;
  }
  .work-section-seven {
    padding: 45px 0;
  }
  .providers-section-seven {
    padding: 45px 0;
  }
  .app-seven-section {
    padding: 45px 0;
  }
  .home-banner-eight {
    .section-search {
      padding-top: 0;
    }
  }
  .home-eight-bg {
    display: none;
  }
  .about-eight-main {
    display: none;
  }
  .professional-eight-img {
    display: none;
  }
  .healthy-pets-img {
    display: none;
  }
  .about-us-eight-section {
    padding: 50px 0;
  }
  .blog-eight-section {
    padding: 50px 0;
  }
  .healthy-eight-section {
    padding: 50px 0;
  }
  .footer.footer-eight {
    .footer-top {
      padding: 50px 0;
    }
  }
  .works-eight-section {
    padding: 50px 0 26px 0;
  }
  .header.header-eight {
    .bar-icon {
      span {
        background-color: $background-color_25;
      }
    }
  }
  .home-banner.home-banner-nine {
    min-height: auto;
    text-align: center;
  }
  .hero-section-nine {
    min-height: auto;
  }
  .arrow-ryt {
    display: none;
  }
  .safety-section {
    padding: 50px 0;
  }
  .service-nine-section {
    padding: 50px 0;
  }
  .feature-service-nine {
    padding: 50px 0;
  }
  .choose-nine-section {
    padding: 50px 0;
    padding: 50px 0 26px 0;
  }
  .customer-review-section {
    padding: 50px 0;
  }
  .blogs-nine-section {
    padding: 50px 0;
  }
  .free-service-bottom-content {
    padding: 50px;
    text-align: center;
  }
  .partner-section-nine {
    margin-top: auto;
    padding: 45px 0;
  }
  .free-service-all {
    margin-top: 45px;
  }
  .footer.footer-nine {
    .footer-top {
      padding: 45px 0 10px 0;
    }
  }
  .header.header-nine {
    .navbar-header {
      display: inline-flex;
    }
    .header-nav {
      height: 65px;
    }
  }
  .footer.footer-five {
    .footer-bottom {
      .copyright {
        .copyright-text {
          p {
            font-size: 14px;
          }
        }
      }
    }
    .copyright-menu {
      .policy-menu {
        font-size: 14px;
      }
    }
  }
  .professional-eight-img-ryt {
    img {
      height: 306px;
    }
  }
  .header.header-nine.fixed {
    .header-nav {
      height: 65px;
    }
  }
  .customer-side-main-all {
    display: none;
  }
  #progressbar {
    flex-wrap: wrap;
    justify-content: space-between !important;
    li {
      flex-basis: 33%;
      margin-right: 0;
    }
    .li-trick {
      display: block!important;
    }
  }
  .field-bottom-btns {
    margin-bottom: 20px;
  }
  .booking-info-tab {
    margin-bottom: 0;
  }
  .wizard {
    ul {
      li {
        h5 {
          font-size: 15px;
        }
      }
    }
  }
  .section-booking {
    padding: 60px 0;
  }
  .service-wrap-content {
    height: 88%;
  }
  .feature-box {
    .feature-overlay {
      img {
        width: 100%;
      }
    }
  }
  .service-img {
    .fixed-size {
      max-height: 26rem;
    }
  }
  .journey-nine-section {
    .section-heading {
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .journey-client-main {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.875rem;
  }
  h1 {
    font-size: 2rem;
  }
  .h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  .h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.375rem;
  }
  .h3 {
    font-size: 1.375rem;
  }
  h4 {
    font-size: 1rem;
  }
  .h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 15px;
  }
  .h5 {
    font-size: 15px;
  }
  h6 {
    font-size: 15px;
  }
  .h6 {
    font-size: 15px;
  }
  .main-nav {
    > li {
      > a {
        &:hover {
          &::after {
            top: 0;
            bottom: inherit;
          }
        }
      }
    }
    > li.active {
      > a {
        &::after {
          top: 0;
          bottom: inherit;
        }
      }
    }
  }
  .content {
    padding: 25px 0;
  }
  .booking-details {
    li {
      p {
        display: block;
        margin-left: 100px;
        margin-top: 5px;
        &::before {
          display: none;
        }
        &:last-child {
          margin-left: 100px;
          margin-top: 5px;
        }
      }
    }
  }
  .footer-title {
    font-size: 1rem;
  }
  .policy-menu {
    margin-top: 10px;
    text-align: left;
  }
  .form-title {
    font-size: 0.875rem;
  }
  .section-search {
    > h3 {
      font-size: 30px;
    }
    h1 {
      font-size: 28px;
    }
    p {
      font-size: 16px;
    }
  }
  .search-box {
    padding: 10px;
    border-radius: 10px;
  }
  .table {
    td, th, tr {
      padding: 0.5rem 0.25rem !important;
      font-size: 13px !important;
      h2 {
        a {
          font-size: 13px;
        }
      }
    }
  }
  .table-actions {
    white-space: normal;
  }
  .add-service {
    padding: 17px;
  }
  .app-set {
    background: #F5F5F5;
    padding: 25px 0;
  }
  .viewall {
    text-align: left;
    padding: 0 0 30px;
    span {
      display: none;
    }
  }
  .search-cat {
    i {
      display: none;
    }
    span {
      display: block;
      margin-right: 0;
    }
    a {
      margin: 10px 5px;
    }
  }
  .breadcrumb-title {
    h2 {
      font-size: 1.375rem;
    }
    font-size: 22px;
  }
  .container-fluid {
    padding: 18px calc(var(--bs-gutter-x)* .5) !important;
  }
  .section-header {
    h2 {
      font-size: 1.875rem;
    }
  }
  .dropdown-menu-left {
    font-size: 0.875rem;
    min-width: 112px !important;
    left: -3.5rem !important;
  }
  .section-search-box {
    min-height: 290px;
  }
  .true-search-box {
    position: relative;
    top: 0;
    padding-top: 25px;
  }
  .section-heading {
    h2 {
      font-size: 24px;
    }
    span {
      font-size: 16px;
    }
    margin-bottom: 30px;
    p {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .section-heading-two {
    h2 {
      font-size: 24px;
    }
  }
  .price-head {
    h1 {
      font-size: 28px;
    }
  }
  .service-widget {
    .serv-info {
      h6 {
        font-size: 16px;
      }
    }
  }
  .new-comment {
    .submit-btn {
      font-size: 16px;
    }
    h4 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .owl-nav {
    .owl-next {
      width: 35px;
      height: 35px;
    }
    .owl-prev {
      width: 35px;
      height: 35px;
    }
  }
  .footer-content {
    max-width: 100%;
  }
  .app-sec {
    padding: 30px;
  }
  .serv-profile {
    h2 {
      font-size: 20px;
    }
    span.badge {
      font-size: 14px;
    }
    margin: 0px 0 10px;
    ul {
      li {
        margin: 0 10px 15px 0;
        font-size: 15px;
      }
    }
  }
  .notification-details {
    h4 {
      font-size: 20px;
    }
  }
  .btn-back {
    font-size: 14px;
  }
  .book-submit {
    .btn {
      font-size: 14px;
      margin: 5px;
      margin-left: 0;
      + {
        .btn {
          margin-left: 0;
        }
      }
    }
  }
  .step-register {
    li {
      .multi-step-info {
        p {
          font-size: 14px;
        }
        h6 {
          font-size: 16px;
        }
      }
      padding-bottom: 20px;
      justify-content: start;
      -webkit-justify-content: start;
      -ms-flex-pack: start;
    }
    margin-bottom: 15px;
  }
  .serv-action {
    text-align: left;
    margin-top: 0px;
  }
  .big-gallery {
    a.btn-show {
      font-size: 14px;
      padding: 8px 8px;
    }
  }
  .btn-review {
    font-size: 14px;
    padding: 10px 20px;
  }
  .service-gal {
    margin-bottom: 30px;
  }
  .provide-widget {
    margin-bottom: 10px;
  }
  .available-info {
    h5 {
      font-size: 18px;
    }
  }
  .package-widget {
    h5 {
      font-size: 18px;
      margin-bottom: 20px;
    }
    padding: 15px;
  }
  .service-images {
    img {
      width: 100%;
    }
  }
  .page-breadcrumb {
    ol {
      font-size: 15px;
    }
  }
  .serv-proimg {
    img {
      width: 60px;
      height: 60px;
    }
    span {
      font-size: 18px;
    }
  }
  .card-provide {
    .btn {
      font-size: 16px;
      padding: 11px;
    }
  }
  .booking-done {
    h6 {
      font-size: 18px;
    }
    text-align: center;
  }
  .widget-title {
    h4 {
      font-size: 18px;
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    &::before {
      padding: 8px 7px;
    }
  }
  .dash-wallet {
    .dash-info {
      h5 {
        font-size: 16px;
      }
    }
  }
  .book-option {
    text-align: left;
  }
  .service-book {
    display: block;
    text-align: center;
  }
  .booking-info {
    .service-book-img {
      margin: 0 0 15px 0;
    }
    padding: 20px;
  }
  .booking-date {
    padding: 20px;
    li {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  .booking-total {
    padding: 20px;
    ul {
      li {
        span {
          font-size: 16px;
        }
      }
    }
  }
  #calendar-doctor {
    margin-bottom: 20px;
  }
  .service-book-img {
    margin: 0 0 20px 0;
  }
  .payment-title {
    h6 {
      font-size: 16px;
    }
  }
  .maintenance-wrap {
    h6 {
      font-size: 16px;
    }
    h2 {
      font-size: 20px;
    }
    .social-icon {
      ul {
        margin-bottom: 20px;
      }
    }
  }
  .summary-box {
    margin-bottom: 25px;
  }
  .provide-table {
    margin-bottom: 25px;
  }
  .page-header {
    .review-sort {
      margin-bottom: 20px;
    }
    .grid-listview {
      margin-bottom: 20px;
    }
    .add-set {
      margin-bottom: 20px;
    }
  }
  .serv-info {
    .serv-edit {
      margin-right: 10px;
      font-size: 12px;
    }
    span {
      font-size: 12px;
    }
  }
  .pay-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .price-card {
    margin-bottom: 15px;
  }
  .error-wrap {
    h2 {
      font-size: 22px;
    }
    p {
      margin-bottom: 24px;
    }
    .error-logo {
      margin-bottom: 35px;
    }
    .btn {
      margin-bottom: 24px;
    }
  }
  .contact-queries {
    h2 {
      font-size: 22px;
    }
    .form-control {
      min-height: 50px;
      margin-bottom: 30px;
    }
    .btn {
      font-size: 14px;
    }
  }
  .contact-details {
    margin-bottom: 10px;
  }
  .login-header {
    h3 {
      font-size: 20px;
    }
  }
  .faq-sec {
    h4 {
      font-size: 20px;
    }
  }
  .countdown-container {
    .countdown-el {
      p {
        font-size: 20px;
      }
      &:before {
        font-size: 15px;
      }
    }
  }
  .choose-signup {
    margin-bottom: 20px;
  }
  .settings-widget {
    margin-bottom: 20px;
  }
  .settings-menu {
    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .search-btn {
    .btn {
      font-size: 15px;
    }
  }
  .price-toggle {
    font-size: 15px;
  }
  .header-navbar-rht {
    .flag-nav {
      display: none;
    }
  }
  .account-item {
    .user-info {
      display: none;
    }
  }
  .review-list {
    flex-direction: column;
    text-align: center;
  }
  .review-count {
    margin: 0 auto;
  }
  .social-widget {
    margin-bottom: 10px;
  }
  .ad-widget {
    ul {
      li {
        margin: 0 10px 10px 0;
      }
    }
  }
  .faq-section {
    margin-bottom: 40px;
  }
  .category-name {
    h6 {
      font-size: 15px;
    }
  }
  .maintenance-sec {
    padding: 40px 0;
  }
  .coming-back {
    p {
      font-size: 14px;
    }
  }
  .booking-action {
    flex: 0 0 100%;
    justify-content: center;
    margin-top: 20px;
  }
  .booking-list {
    .booking-widget {
      flex: 0 0 100%;
    }
  }
  #tablelength {
    text-align: center;
  }
  .table-ingopage {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .balance-crad {
    h3 {
      font-size: 22px;
    }
  }
  .work-wrap {
    h1 {
      font-size: 22px;
    }
    margin-bottom: 35px;
    text-align: center;
  }
  .balance-amt {
    margin-bottom: 0;
  }
  .book-id {
    margin-bottom: 0;
  }
  .main-content {
    width: 100%;
  }
  .coupon-submit {
    .btn {
      min-width: 150px;
    }
  }
  .page-content {
    display: block;
  }
  .content-side {
    min-width: 100%;
  }
  .content-sidebar {
    padding-bottom: 0;
  }
  #progressbar {
    margin-bottom: 15px;
    li {
      //flex-basis: 30%;
    }
  }
  .book-history {
    margin-bottom: 15px;
  }
  .book-id-action {
    text-align: left;
    margin: 0;
  }
  .slot-action {
    float: left;
  }
  .slot-box {
    margin-bottom: 24px;
  }
  .page-topbar {
    .page-breadcrumb {
      ol {
        margin-bottom: 24px;
      }
    }
    .breadcrumb {
      margin: 0;
    }
  }
  .maintenance-icon {
    margin: 0;
  }
  .payment-summary {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
  .fc {
  }
  .book-email {
    h6 {
      font-size: 14px;
    }
  }
  .leave-action {
    .btn {
      padding: 8px 15px;
      margin: 0 5px 20px 0;
    }
  }
  .file-upload {
    padding: 15px 20px;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
    }
  }
  .bk-history {
    h4 {
      font-size: 16px;
    }
  }
  .blog-head {
    h3 {
      font-size: 22px;
    }
  }
  .blog-review {
    h4 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .work-wrap-acc {
    &:after {
      content: none;
    }
  }
  .work-wrap-post {
    &:after {
      content: none;
    }
  }
  .work-img {
    margin-bottom: 35px;
    text-align: center;
  }
  .works-main {
    margin-bottom: 24px;
  }
  .section-heading.section-heading-six {
    h2 {
      text-align: center;
    }
    p {
      margin-bottom: 0;
    }
  }
  .breadcrumb-bar {
    padding: 20px 0;
  }
  .linked-wrap {
    display: block;
  }
  .linked-item {
    a {
      margin-right: 5px;
    }
  }
  .link-close {
    margin-right: 10px !important;
  }
  .link-check {
    margin-right: 10px !important;
  }
  .edit-service {
    h5 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .provider-subtitle {
    h6 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  .booking-det-info {
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .provider-info {
    h2 {
      font-size: 22px;
    }
    margin-bottom: 25px;
  }
  .align-items-center .details-responsive {
    .responsive {
      width: 100%;
    }
  }
  .provider-details {
    margin-bottom: 25px;
    ul {
      margin-bottom: 16px;
    }
  }
  .service-list {
    flex-direction: column;
  }
  .service-action {
    flex: 0 0 100%;
    width: 100%;
  }
  .service-cont {
    flex: 0 0 100%;
    width: 100%;
  }
  .search-box-two {
    display: none;
  }
  .offer-path-content {
    h3 {
      font-size: 22px;
    }
  }
  .pricing-plans.price-new {
    margin-top: 0;
  }
  .app-section-two {
  }
  .footer-two {
    .footer-contact-info {
      p {
        font-size: 14px;
      }
    }
  }
  .comments-reply {
    li {
      margin-left: 20px;
    }
  }
  .addon-box {
    display: block;
  }
  .addon-info {
    ul {
      justify-content: start;
      flex-wrap: wrap;
    }
  }
  .about-img {
    .about-exp {
      span {
        font-size: 18px;
        margin: 276px -70px;
      }
      width: 230px;
    }
  }
  .choose-icon {
    border: 0;
  }
  .contact-img {
    text-align: center;
    margin: 0 0 24px 0;
    &:before {
      left: 60px;
    }
    &:after {
      right: 40px;
    }
  }
  .payment-image {
    ul {
      justify-content: start;
    }
  }
  .copyright-text {
    margin-bottom: 10px;
  }
  .home-banner {
    display: block;
    > div {
      width: auto !important;
    }
  }
  .invoice-breadcrumb {
    .breadcrumb-item + .breadcrumb-item {
      &::before {
        padding: 9px 7px;
      }
    }
  }
  .invoice-head {
    float: left;
  }
  .invoice-to {
    float: left;
  }
  .invoice-total {
    float: left;
    margin-bottom: 24px;
  }
  .invoice-payment {
    margin-bottom: 24px;
  }
  .hero-section-two {
    .banner-search {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .client-name {
    h5 {
      font-size: 16px;
    }
  }
  .search-box.search-box-three {
    margin-top: -70px;
  }
  .works-bottom {
    h2 {
      font-size: 24px;
    }
  }
  .register-content {
    h2 {
      font-size: 30px;
    }
  }
  .copyright-menu {
    float: none;
  }
  .search-box.search-box-four {
    border-radius: 0;
    .search-btn {
      .btn {
        width: 100%;
        padding: 12px 0;
        justify-content: center;
        border-radius: 0;
      }
    }
  }
  .section-search.section-search-four {
    h1 {
      font-size: 32px;
    }
  }
  .works-five-main {
    margin-bottom: 20px;
  }
  .offering-five-main {
    h1 {
      font-size: 30px;
    }
  }
  .services-header {
    .section-content {
      h2 {
        font-size: 28px;
      }
    }
  }
  .search-box-two.search-box-six {
    display: inline-block;
    border-radius: 0;
    .search-input-new.line-six {
      width: 100%;
      margin-bottom: 5px;
    }
    .search-input-new.line-two-six {
      width: 100%;
      margin-bottom: 5px;
    }
    .search-btn {
      width: 100%;
      .btn {
        border-radius: 0;
      }
    }
    .select2-container--default {
      .select2-selection--single {
        .select2-selection__rendered {
          padding-left: 10px;
        }
      }
    }
  }
  .new-app-six-middle {
    p {
      text-align: center;
    }
    h2 {
      text-align: center;
      span {
        font-size: 22px;
      }
    }
    padding: 40px 10px 25px 10px;
  }
  .service-section-seven {
    padding: 150px 0 45px 0;
  }
  .home-banner-eight {
    min-height: 540px;
    min-height: 350px;
  }
  .home-eight-dog {
    display: none;
  }
  .footer.footer-five {
    .footer-bottom {
      .copyright {
        .payment-image {
          ul {
            justify-content: center;
            margin-bottom: 10px;
          }
        }
      }
    }
    .copyright-menu {
      .policy-menu {
        text-align: center;
        margin-top: 0;
      }
    }
  }
  .safety-first-all {
    text-align: center;
  }
  .chat-cont-right {
    .chat-cont-profile.chat-profile-right {
      position: absolute;
      right: 100%;
      opacity: 0;
      z-index: 105;
      width: 100%;
    }
  }
  .card.chat-window {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .section-booking {
    padding: 40px 0;
  }
  .settings-header {
    img {
      margin-right: 0;
    }
  }
  .modal-dialog {
    .modal-content {
    }
  }
  .footer.footer-six {
    .footer-widget.footer-menu {
      .footer-title {
        border-bottom: 0;
      }
    }
  }
  .customer-chat {
    .chat-cont-profile {
      .card-body {
        max-height: calc(100vh - 228px);
      }
    }
  }
  .chat-profile-right {
    .back-user-list {
      display: block;
    }
    .chat-profile-img {
      margin-left: 10px;
    }
  }
}
@media only screen and (max-width: 575.98px) {
  body {
    font-size: 0.8125rem;
  }
  h1 {
    font-size: 1.75rem;
  }
  .h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  .h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  .h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1rem;
  }
  .h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 15px;
  }
  .h5 {
    font-size: 15px;
  }
  h6 {
    font-size: 15px;
  }
  .h6 {
    font-size: 15px;
  }
  .card {
    margin-bottom: 0.9375rem;
  }
  .card-body {
    padding: 1.25rem;
  }
  .card-header {
    padding: .75rem 1.25rem;
  }
  .card-footer {
    padding: .75rem 1.25rem;
  }
  .header-nav {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-nav {
    li.login-link {
      display: block;
    }
  }
  #mobile_btn {
    left: 0;
    margin-right: 0;
    padding: 0 0px;
    position: absolute;
    z-index: 99;
  }
  .notification-delete-wrap {
    flex-direction: column;
  }
  .notification-delete {
    margin-top: 20px;
  }
  .login-body {
    .header-navbar-rht {
      display: none;
    }
  }
  .footer-title {
    font-size: 1.125rem;
  }
  .contacts_card {
    margin-bottom: 15px !important;
  }
  .header-navbar-rht {
    position: absolute;
    right: 10px;
    li {
      > a.header-login {
        padding: 8px 20px;
        display: contents;
        i.fa-regular {
          position: absolute;
          font-size: 1.8rem;
          margin-right: -1.5rem !important;
        }
        i::before {
          color: #4c40ed;
        }
      }
      > a.header-reg {
        display: none;
      }
      .dropdown-menu.notifications {
        left: -180px;
      }
    }
    .dropdown-menu {
      min-width: 200px;
      padding: 0;
      max-width: 300px;
      right: -1rem;
      left: auto;
    }
    > li {
      &:last-child {
        .dropdown-menu {
          right: 0;
        }
      }
    }
    > li.msg-nav {
      display: none;
    }
    > li.noti-nav {
      display: none;
      display: inherit;
    }
  }
  .section-search {
    > h3 {
      font-size: 1.75rem;
    }
  }
  .heading {
    h2 {
      font-size: 1.375rem;
    }
  }
  .breadcrumb-title {
    text-align: center;
  }
  .breadcrumb-menu {
    display: none;
  }
  .notifications {
    .noti-content {
      width: 240px;
    }
  }
  .noti-details {
    font-size: 14px;
  }
  .owl-carousel {
    .professional-eight-img-ryt {
      img {
        width: 250px;
        height: 250px;
      }
    }
    .owl-nav {
      .owl-prev {
        right: 49px;
      }
      .owl-next {
        left: auto;
      }
    }
  }
  .blog-title {
    a {
      font-size: 16px;
    }
  }
  .blog-date {
    p {
      font-size: 14px;
    }
  }
  .blog-inner-img {
    flex-direction: column;
    padding: 34px 0px 20px 0px;
    img {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
  .social-links-block {
    margin-top: 25px;
  }
  .tags-links-block {
    flex-direction: column;
  }
  .booking-list {
    .booking-widget {
      flex-direction: column;
    }
    .booking-img {
      margin: 0 auto 15px;
    }
  }
  .service-cont {
    flex-direction: column;
  }
  .service-cont-img {
    flex: 0 0 100%;
    margin: 0 auto 15px;
  }
  .service-cont-info {
    text-align: center;
  }
  .service-action {
    justify-content: center;
  }
  .service-pro-img {
    justify-content: center;
  }
  .social-share {
    > li {
      > a {
        font-size: 16px;
        height: 30px;
        width: 30px;
      }
    }
    justify-content: center;
  }
  .about-author {
    flex-direction: column;
  }
  .about-author-img {
    margin-bottom: 15px;
    text-align: center;
    margin-right: 0;
  }
  .author-details {
    text-align: center;
  }
  .page-top {
    flex-direction: column;
  }
  .balance-crad {
    display: block;
    .btn-withdraw {
      margin: 6px 0 0;
    }
    .view-transaction {
      display: block;
    }
  }
  .balance-head {
    margin-bottom: 15px;
  }
  .breadcrumb {
    margin: 15px 0;
  }
  .page-breadcrumb {
    ol {
      background-color: $background-color_19;
      font-size: 14px;
      margin: 0;
    }
  }
  .page-title {
    h2 {
      font-size: 20px;
    }
    margin-bottom: 20px;
  }
  .filter-content {
    h2 {
      font-size: 16px;
    }
  }
  .service-wrap {
    h5 {
      font-size: 16px;
    }
  }
  .provide-info {
    h6 {
      font-size: 16px;
    }
  }
  .available-widget {
  }
  .available-info {
    p {
      span {
        font-size: 14px;
        margin-left: 7px;
      }
    }
  }
  .visits {
    width: 33.33%;
    span.visit-rsn {
      font-size: 14px;
    }
  }
  .visit-btns {
    margin-bottom: 10px;
  }
  .coming-back {
    p {
      margin-bottom: 10px;
    }
    flex-direction: column;
  }
  .book-submit {
    .btn {
      width: 100%;
    }
  }
  .booking-img {
    margin: 0 0 15px;
  }
  .plan-info {
    .plan-term {
      margin: 0 0 15px;
    }
    // flex-direction: column;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
  .passcode-wrap {
    input {
      width: 60px;
      height: 60px;
      font-size: 22px;
      margin-right: 12px;
    }
  }
  .terms-btn {
    .btn {
      margin: 10px 0 0;
    }
  }
  .slot-chat {
    flex-direction: column;
  }
  .blog-content-img {
    flex-direction: column;
  }
  .review-pagination {
    text-align: center;
  }
  .review-entries {
    text-align: center;
    margin-bottom: 20px;
  }
  .plan-btns {
    .btn {
      margin: 10px 0 0 0;
    }
  }
  .plan-price {
    margin-bottom: 0;
    .btn-primary {
      margin-top: 20px;
    }
  }
  #progressbar {
    justify-content: space-between !important;
    li {
      flex-basis: 47%;
      margin-right: 0;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
    }
  }
  .schedule-nav {
    ul {
      flex-direction: column;
      li {
        display: block;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        margin-right: 0;
      }
    }
  }
  .wizard-book {
    ul {
      li {
        padding-right: 21px;
      }
    }
  }
  .user-drop {
    left: 0px;
    width: 220px;
    bottom: 60px;
  }
  .chat-cont-profile {
    width: 270px;
  }
  .provider-body {
    .dropdown-heads {
      display: none;
    }
    .dropdown-heads.noti-wrapper {
      display: inherit;
    }
  }
  .service-widget.service-two {
    .service-content {
      p {
        .rate {
          display: block;
          float: unset;
        }
      }
    }
  }
  .banner {
    &:before {
      top: 0;
    }
  }
  .banner-search {
    p {
      margin: 0;
    }
  }
  .footer-two {
    .social-icon {
      text-align: left;
    }
  }
  .top-bar {
    flex-direction: column;
    h6 {
      margin: 0 0 10px 0;
    }
  }
  .about-img {
    .about-exp {
      span {
        margin: 116px -70px;
      }
    }
  }
  .contact-img {
    &:before {
      left: 0;
    }
  }
  .downlaod-btn {
    display: block;
  }
  .search-box.search-box-three {
    margin-top: -50px;
  }
  .app-sec.app-sec-five {
    .downlaod-btn {
      flex-direction: column;
      align-items: center;
      .scan-img {
        margin: 0 0 10px 0;
      }
      a {
        margin: 0 0 10px 0;
      }
    }
  }
  .header.header-five {
    .header-navbar-rht {
      display: none;
    }
  }
  .footer.footer-five {
    .footer-bottom {
      .copyright {
        .payment-image {
          margin-bottom: 20px;
        }
      }
    }
  }
  .search-box.search-box-five {
    .search-box-form {
      flex-direction: column;
    }
    .search-input {
      width: 100%;
    }
  }
  .services-section {
    padding: 75px 0 33px;
  }
  .header.header-six {
    .header-navbar-rht {
      display: none;
    }
  }
  .home-banner-eight-icon {
    display: none;
  }
  .booking-info-tab {
  }
  .notification-wrap-date {
    flex-direction: column;
    .search-calendar-line {
      margin-bottom: 15px;
    }
  }
  .provider-widget {
    .card-body {
      .about-author {
        flex-direction: row;
      }
    }
  }
  .service-details {
    ul {
      justify-content: center;
    }
  }
  .service-wrap-content {
    height: 90%;
    padding: 10px;
  }
  .modal-dialog {
    .service-profile {
      padding: 20px;
    }
  }
  .wizard {
    ul {
      li {
        span.dot-active {
          display: none;
        }
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        border-bottom: 2px solid #fff;
      }
      flex-direction: column;
      row-gap: 20px;
      li.progress-activated {
        border-color: $border-color_1;
      }
    }
  }
  .add-service-toggle {
    .status-toggle {
      margin-top: 10px;
    }
  }
  .add-service {
    .file-preview {
      ul {
        flex-direction: column;
      }
    }
  }
  .swal2-actions {
    .btn {
      margin: 0;
    }
  }
  .linked-item {
    a.link-close {
      margin-right: 20px !important;
      padding: 0;
    }
    a {
      padding: 9px 15px;
    }
  }
  .linked-info {
    h6 {
      a {
        padding: 0;
      }
    }
  }
}
@media only screen and (max-width: 485.98px) {
  #progressbar {
    justify-content: space-between !important;
    li {
      // flex-basis: 47%;
    }
  }
  .booking-info-tab {
    .card-details {
      flex-direction: column;
    }
    padding: 20px;
  }
  .payment-head {
    flex-direction: column;
    row-gap: 10px;
  }
  .payment-title {
    .custom_radio {
      .checkmark {
        margin-top: -4px;
      }
    }
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .custom-modal {
    .modal-body {
      padding: 20px;
    }
  }
  #datetimepickershow {
    padding: 10px;
  }
  .service-details {
    ul {
      li {
        &:last-child {
          margin-top: 14px;
          margin-left: 0;
        }
      }
    }
  }
  .countdown-container {
    .countdown-el {
      min-width: 80px;
      min-height: 80px;
    }
  }
  .chat-msg-attachments {
    display: block;
    .chat-attachment {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .chat-attachment {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
