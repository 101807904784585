body {
  height: 100%;
  overflow-x: hidden;
  line-height: 1.6;
}

.client-widget .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-bottom: 0;
  right: -60px;
}
.client-widget .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-bottom: 0;
  left: -60px;
}
.client-widget .owl-theme .owl-nav:hover {
  background-color: #4c40ed !important;
  color: #fff;
}

.slick-next .slick-arrow {
  display: none;
}
.hide-icon .mat-mdc-select-arrow svg {
  display: none;
}
.mat-selection-box {
  border: 1px solid #d9d9d9;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  padding: 7px;
}
.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background-color: #4c40ed !important;
}
.mat-mdc-option-active .mdc-list-item__primary-text,
.mat-mdc-option:hover .mdc-list-item__primary-text {
  color: #fff !important;
}
.mat-mdc-option {
  min-height: 35px !important;
  font-size: 14px;
}
.stickybar {
  position: sticky;
  top: 0;
}
.mat-mdc-select {
  width: 100%;
  outline: none;
  display: flex !important;
  font-size: 14px;
}
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}
.NgxEditor {
  background: white;
  color: #000;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
}
.partners-slider .owl-theme .owl-dots {
  display: none;
}
.service-slider .owl-theme .owl-dots {
  display: none;
}
.testimonial .slick-next {
  right: -55px;
}
.testimonial .slick-prev {
  left: -55px;
}
.testimonial .slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #4c40ed !important;
  color: #fff;
  opacity: 1;
}
.banner-slider-testi .slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #ff008a !important;
  color: #fff;
  opacity: 1;
}
.review-sort .mat-mdc-select {
  display: inline-block !important;
  width: auto;
  padding-right: 70px;
}
.cdk-overlay-container {
  z-index: 10000000;
}
.mat-datepicker-toggle {
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.mat-datepicker-content .mat-calendar {
  font-size: 13px;
}
.cus-icon .icon-calendar {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review-sort mat-select {
  border: 2px solid #edecf8;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 25px;
  height: 40px;
  line-height: 39px;
}
.review-sort .mat-mdc-select-arrow {
  left: 60px;
}
.mat-select-box .mat-mdc-select {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  height: 42px;
  padding: 14px;
}
.addon-info mat-select {
  min-width: 161px;
}
.custom-mat-select .mat-mdc-select {
  display: inline-block;
  outline: none;
  width: 85px !important;
  padding: 10px;
}
.custom-mat-select .mat-mdc-select-value-text {
  color: #4c40ed;
}
.custom-mat-select .mat-mdc-select-placeholder {
  color: #4c40ed;
}
.mat-mdc-select-placeholder {
  color: #3c4758;
}
.custom-select mat-select {
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 25px;
  height: 37px;
  line-height: 39px;
}
.custom-select .mat-mdc-select-arrow {
  left: 19px;
}
.table-span {
  border: 1px solid #f8f8f8;
  height: 30px;
  background-color: #f9f9fb;
  border-radius: 8px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 14px;
}
.table-span .mat-mdc-select-arrow {
  width: 23px;
  color: #74788d;
}
.table-span .mat-mdc-select-placeholder {
  color: #74788d;
}
.setting-select {
  border: 1px solid #f8f8f8;
  height: 46px;
  background-color: #f9f9fb;
  border-radius: 8px;
  padding-left: 15px;
  line-height: 46px;
}
.setting-select .mat-mdc-select-value-text {
  color: #74788d;
  padding-left: 20px;
}
.setting-select-box .mat-mdc-select-value-text {
  color: #74788d;
  padding-left: 20px;
}
.setting-select .mat-mdc-select-placeholder {
  color: #74788d;
}
.setting-select .mat-mdc-select-arrow {
  width: 30px;
}
.placeholder-cus .mat-mdc-select-placeholder {
  padding-left: 20px;
}
.edit-select-box {
  background-color: #fff;
  height: 34px;
  border: 1px solid #f8f8f8;
  background-color: #f9f9fb;
  border-radius: 8px;
  line-height: 34px;
  padding-left: 15px;
}
.arrow-customize .mat-mdc-select-arrow {
  right: 10px;
}
.setting-select-box {
  border: 1px solid #f8f8f8;
  height: 36px;
  background-color: #f9f9fb;
  border-radius: 8px;
  padding-left: 15px;
}
.providers-dm .providers-header,
.providers-dm .providers-sidebar {
  display: none;
}
mat-form-field {
  font-size: 13px;
  background-color: #f9f9fb;
}
.mdc-line-ripple {
  display: none;
}
.mat-primary
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
.mdc-list-item__primary-text {
  color: white;
}
.select-content {
  top: 12px;
  position: relative;
  left: 7px;
}
.select-content .mat-mdc-select-placeholder {
  color: #898989;
}
.cus-mat-icon .mat-datepicker-toggle {
  left: 0;
}
.cus-mat-icon .mat-mdc-icon-button:active,
.cus-mat-icon .mat-mdc-icon-button:hover {
  background: #fff;
}
.search-input .cus-mat-icon input {
  padding-left: 35px;
}
.mat-mdc-icon-button .mat-mdc-button-ripple {
  display: none !important;
}
.select-list {
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #c2c9d1;
  border-radius: 5px;
  height: 42px;
}
.demo-inline-calendar-card {
  font-size: 14px;
}
.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 0.75;
  display: -webkit-box !important;
}
.cdk-overlay-container {
  z-index: 10000000 !important;
}
.filter-range .ngx-slider .ngx-slider-pointer {
  width: 18px;
  height: 17px;
  background-color: #4c40ed;
  border: 3px solid #fff;
  top: -6px;
}

.filter-range .ngx-slider .ngx-slider-bar {
  height: 6px !important;
}
.filter-range .ngx-slider .ngx-slider-bubble {
  color: white;
  font-size: 12px;
  line-height: 1.333;
  text-shadow: none;
  padding: 4px 10px;
  background-color: #4c40ed;
  border-radius: 2px;
  top: -38px;
  margin: 0px 0px !important;
}
.filter-range .ngx-slider .ngx-slider-bubble {
  color: white;
  font-size: 12px;
  line-height: 1.333;
  text-shadow: none;
  padding: 4px 10px;
  background-color: #0b2540;
  border-radius: 2px;
  top: -38px;
  margin: 0px -0px !important;
}
.filter-range .ngx-slider .ngx-slider-bubble.ngx-slider-limit {
  display: none !important;
}
.filter-range
.ngx-slider.animate
.ngx-slider-bubble.ngx-slider-limit.ngx-slider-floor {
  display: none !important;
}
.filter-range
.ngx-slider.animate
.ngx-slider-bubble.ngx-slider-limit.ngx-slider-ceil {
  display: none !important;
}
.filter-range .ngx-slider .ngx-slider-pointer::after {
  display: none !important;
}
.filter-range .ngx-slider .ngx-slider-selection {
  background-color: #4c40ed !important;
}

.filter-range .ngx-slider-selection-bar {
  visibility: unset !important;
}

/* progress-bar */
.service-section .owl-nav .owl-prev {
  position: absolute;
  top: -129px;
  right: 49px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.service-section .owl-nav .owl-next {
  position: absolute;
  top: -129px;
  right: 5px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.service-section .owl-prev:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.service-section .owl-next:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.client-section .owl-nav .owl-prev {
  position: absolute;
  left: -41px;
  top: 187px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.client-section .owl-nav .owl-next {
  position: absolute;
  right: -41px;
  top: 187px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.client-section .owl-prev:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.client-section .owl-next:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.popular-service-seven-section .owl-nav .owl-next {
  position: absolute;
  top: -129px;
  right: 12px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.popular-service-seven-section .owl-nav .owl-prev {
  position: absolute;
  top: -129px;
  right: 54px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.popular-service-seven-section .owl-prev:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.popular-service-seven-section .owl-next:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.services-section .owl-nav .owl-prev {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  color: inherit;
}
.services-section .owl-nav .owl-next {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  color: inherit;
}
.service-nine-section .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.mechanics-section .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.customer-review-section .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.providers-section-five .owl-nav .owl-prev {
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
  background: #ff008a !important;
}
.providers-section-five .owl-nav .owl-next {
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
  background: #ff008a !important;
}
.providers-section-five .owl-prev:hover {
  background: #fff !important;
  color: #ff008a !important;
  margin-bottom: 0;
}
.providers-section-five .owl-next:hover {
  background: #fff !important;
  color: #ff008a !important;
  margin-bottom: 0;
}
.feature-category-section-five .owl-nav .owl-prev {
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
  background: #ff008a !important;
}
.feature-category-section-five .owl-nav .owl-next {
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
  background: #ff008a !important;
}
.car-blogs-section .owl-nav .owl-prev {
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
  background: #ff008a !important;
}
.car-blogs-section .owl-nav .owl-next {
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
  background: #ff008a !important;
}
.feature-category-section-five .owl-next:hover {
  background: #ffffff !important;
  color: #ff008a !important;
  margin-bottom: 0;
}
.feature-category-section-five .owl-prev:hover {
  background: #ffffff !important;
  color: #ff008a !important;
  margin-bottom: 0;
}
.car-blogs-section .owl-next:hover {
  background: #ffffff !important;
  color: #ff008a !important;
  margin-bottom: 0;
}
.car-blogs-section .owl-prev:hover {
  background: #ffffff !important;
  color: #ff008a !important;
  margin-bottom: 0;
}
.service-section-seven .owl-nav .owl-prev {
  position: absolute;
  top: -129px;
  right: 99px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.service-section-seven .owl-nav .owl-next {
  position: absolute;
  top: -129px;
  right: 50px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.service-section-seven .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.popular-service-seven-section .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.price-sections-seven .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.List-section-seven .owl-dots .owl-dot.active span {
  background: #ff008a;
}
.List-section-seven .owl-nav .owl-next {
  position: absolute;
  top: -129px;
  right: 5px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.List-section-seven .owl-nav .owl-prev {
  position: absolute;
  top: -129px;
  right: 49px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.List-section-seven .owl-prev:hover {
  background: #ff008a !important;
  color: #ffffff;
  margin-bottom: 0;
}
.List-section-seven .owl-next:hover {
  background: #ff008a !important;
  color: #ffffff;
  margin-bottom: 0;
}
.List-section-seven .owl-prev:hover {
  background: #ff008a !important;
  color: #ffffff;
  margin-bottom: 0;
}
.service-wrap .owl-nav .owl-prev {
  position: absolute;
  top: -55px;
  right: 49px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.service-wrap .owl-nav .owl-next {
  position: absolute;
  top: -55px;
  right: 5px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.service-wrap .owl-next:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.service-wrap .owl-prev:hover {
  background-color: #4c40ed !important;
  color: #fff;
}
.slick-prev slick-arrow {
  display: none;
}
.testimonals-seven-section .owl-nav .owl-next {
  position: absolute;
  top: -129px;
  right: 5px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.testimonals-seven-section .owl-nav .owl-prev {
  position: absolute;
  top: -129px;
  right: 49px;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border: 0;
  border-radius: 50%;
  color: #28283c;
  width: 32px;
  height: 32px;
  background: #fff !important;
}
.testimonals-seven-section .owl-next:hover {
  background: #4c40ed !important;
  color: #ffffff;
  margin-bottom: 0;
}
.testimonals-seven-section .owl-prev:hover {
  background: #4c40ed !important;
  color: #ffffff;
  margin-bottom: 0;
}
.show-sub-menu {
  display: block !important;
  transition: all 0.5s;
}

.hide-sub-menu {
  display: none !important;
  transition: all 0.5s;
}
.position-cls {
  top: 9px;
  position: relative;
  right: 10px;
}
.value-select .mat-mdc-select-value {
  color: #6c6c6c;
  padding-left: 10px;
}
.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.5) !important;
}
input.form-control.text-center.bs-timepicker-field {
  padding-right: 15px;
}
timepicker {
  position: absolute;
  background: #eee;
  padding: 40px;
  border: 1px solid #dcdcdc ;
  border-radius: 4px;
  z-index: 1
}
.bs-chevron-up {
  color: black;
}
.bs-chevron-down {
  color: black;
}
@media only screen and (max-width: 767.98px) {
  .value-select {
    text-align: start;
    padding-left: 0px;
  }
  .dropdown-select {
    text-align: start;
  }
  .position-cls {
    top: 0px;
    position: relative;
    right: -7px;
    text-align: start;
  }
}

@media only screen and (max-width: 575.98px) {
  .service-section .owl-nav .owl-prev {
    position: absolute;
    top: -50px;
    right: 502px;
    box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
    border: 0;
    border-radius: 50%;
    color: #28283c;
    width: 32px;
    height: 32px;
    background: #fff !important;
  }
  .service-section .owl-nav .owl-next {
    position: absolute;
    top: -50px;
    left: 49px;
    box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
    border: 0;
    border-radius: 50%;
    color: #28283c;
    width: 32px;
    height: 32px;
    background: #fff !important;
  }
  .service-section-two .owl-nav .owl-prev {
    position: absolute;
    top: -59px;
    right: 49px;
  }
  .service-section-two .owl-nav .owl-next {
    position: absolute;
    top: -59px;
    right: 5px;
  }
}
